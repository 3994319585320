<template>
    <div>
        <back></back>
        <div class="task-container">
            <div class="task" v-for="(task, index) in tasks" :key="index">
                <div class="task-header">
                    <h3 class="task-title">{{ task.title }}</h3>
                </div>
                <div class="task-body">
                    <p class="task-description">{{ task.description }}</p>

                    <div class="events-list">
                        <div class="event" v-for="(event, index) in task.events" :key="index"
                             :class="getEventClass(event)">
                            <div class="event-header">
                                <h4 class="event-title">{{ event.title }}</h4>
                            </div>
                            <p class="event-description">{{ event.description }}</p>
                            <div class="event-status">
                                <span :class="getStatusClass(event.status)" class="status-dot"></span>
                                <span class="status-text">{{ event.statusText }}</span>
                            </div>
                            <div class="event-footer">
                                <button
                                        :class="getButtonClass(event.status)"
                                        :disabled="event.status === '已完成'"
                                        @click="updateStatus(event)">
                                    {{
                                    event.status === '未完成' ? '开始' : event.status === '进行中' ? '完成' : '已完成'
                                    }}
                                </button>
                            </div>
                        </div>
                    </div>

                    <!-- 新增任务奖励部分 -->
                    <div class="task-rewards">
                        <h4 class="rewards-title">任务奖励</h4>
                        <div class="rewards-list">
                            <div class="reward-item" v-for="(reward, index) in task.rewards" :key="index">
                                <img :src="reward.icon" alt="reward" class="reward-icon"/>
                                <span class="reward-text">{{ reward.text }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Back from "@/components/back.vue";

export default {
    name: "task",
    components: {Back},
    data() {
        return {
            tasks: [
                {
                    title: "任务一",
                    description: "完成第一个任务，涉及多个事件",
                    events: [
                        {title: "事件一", description: "事件描述1", status: "未完成", statusText: "未开始"},
                        {title: "事件二", description: "事件描述2", status: "进行中", statusText: "进行中"},
                        {title: "事件三", description: "事件描述3", status: "已完成", statusText: "已完成"}
                    ],
                    rewards: [
                        {icon: "path/to/reward-icon1.png", text: "100金币"},
                        {icon: "path/to/reward-icon2.png", text: "经验 +50"}
                    ]
                },
                {
                    title: "任务二",
                    description: "完成第二个任务，包含更多事件",
                    events: [
                        {title: "事件一", description: "事件描述1", status: "未完成", statusText: "未开始"},
                        {title: "事件二", description: "事件描述2", status: "未完成", statusText: "未开始"}
                    ],
                    rewards: [
                        {icon: "path/to/reward-icon3.png", text: "200金币"}
                    ]
                }
            ]
        };
    },
    methods: {
        getStatusClass(status) {
            switch (status) {
                case '未完成':
                    return 'status-not-started';
                case '进行中':
                    return 'status-in-progress';
                case '已完成':
                    return 'status-completed';
                default:
                    return '';
            }
        },
        getEventClass(event) {
            switch (event.status) {
                case '未完成':
                    return 'event-not-started';
                case '进行中':
                    return 'event-in-progress';
                case '已完成':
                    return 'event-completed';
                default:
                    return '';
            }
        },
        getButtonClass(status) {
            switch (status) {
                case '未完成':
                    return 'btn-start';
                case '进行中':
                    return 'btn-in-progress';
                case '已完成':
                    return 'btn-completed';
                default:
                    return '';
            }
        },
        updateStatus(event) {
            if (event.status === '未完成') {
                event.status = '进行中';
                event.statusText = '进行中';
            } else if (event.status === '进行中') {
                event.status = '已完成';
                event.statusText = '已完成';
            }
        }
    }
};
</script>

<style scoped>
/* 任务容器 */
.task-container {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
    max-width: 100%;
    /*margin: 0 auto;*/
    font-family: 'Press Start 2P', cursive;
}

/* 任务 */
.task {
    background: #111;
    border: 4px solid #ff6347;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.7), 0 6px 15px rgba(255, 99, 71, 0.5);
    position: relative;
    flex: 1 1 calc(33% - 20px);
    min-width: 320px;
    box-sizing: border-box;
    overflow: hidden;
    transition: all 0.3s ease;
    background-image: radial-gradient(circle, rgba(255, 99, 71, 0.3) 10%, rgba(255, 99, 71, 0.2) 40%, transparent 60%);
    background-size: 100% 100%, 80px 80px;
    background-position: center center, 10px 10px;
    transition: background-color 0.3s ease;
}

.task:hover {
    box-shadow: 0 6px 25px rgba(0, 0, 0, 0.9), 0 8px 20px rgba(255, 99, 71, 0.6);
    background: #333;
}

/* 任务标题 */
.task-title {
    font-size: 1.7em;
    color: #ff6347;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-shadow: 4px 4px 6px rgba(0, 0, 0, 0.8);
}

/* 任务描述 */
.task-description {
    font-size: 1.2em;
    color: #ccc;
    line-height: 1.6;
    margin-bottom: 15px;
}

/* 子任务容器 */
.events-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

/* 子任务 */
.event {
    padding: 18px;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.7);
    transition: all 0.2s ease;
}

.event:hover {
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.7);
}

/* 子任务头 */
.event-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.event-title {
    font-size: 1.4em;
    color: #fff;
}

/* 状态点 */
.event-status {
    display: flex;
    align-items: center;
    gap: 10px;
}

.status-dot {
    width: 14px;
    height: 14px;
    border-radius: 50%;
}

.status-not-started {
    background-color: #f44336;
}

.status-in-progress {
    background-color: #ffeb3b;
}

.status-completed {
    background-color: #4caf50;
}

.status-text {
    font-size: 1.2em;
    color: #fff;
}

/* 按钮 */
.event-button {
    padding: 14px 28px;
    background: #ff6347;
    color: #fff;
    border: 4px solid #ff6347;
    border-radius: 10px;
    cursor: pointer;
    font-size: 1.3em;
    text-transform: uppercase;
    font-family: 'Press Start 2P', cursive;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.5);
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.event-button:disabled {
    background: #ccc;
    border-color: #aaa;
    cursor: not-allowed;
}

.event-button:focus {
    outline: none;
}

/* 按钮状态样式 */
.btn-start {
    background-color: #f44336; /* 红色 */
    border-color: #f44336;
    color: #fff;
    font-family: 'Press Start 2P', cursive; /* 像素风格字体 */
    font-size: 16px;
    padding: 10px 20px;
    border: 3px solid #f44336;
    border-radius: 8px;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
    transition: all 0.3s ease;
    display: inline-block;
    min-width: 120px;
    text-align: center;
}

.btn-in-progress {
    background-color: #ffeb3b; /* 黄色 */
    border-color: #ffeb3b;
    color: #000;
    font-family: 'Press Start 2P', cursive; /* 像素风格字体 */
    font-size: 16px;
    padding: 10px 20px;
    border: 3px solid #ffeb3b;
    border-radius: 8px;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
    transition: all 0.3s ease;
    display: inline-block;
    min-width: 120px;
    text-align: center;
}

.btn-completed {
    background-color: #4caf50; /* 绿色 */
    border-color: #4caf50;
    color: #fff;
    font-family: 'Press Start 2P', cursive; /* 像素风格字体 */
    font-size: 16px;
    padding: 10px 20px;
    border: 3px solid #4caf50;
    border-radius: 8px;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
    transition: all 0.3s ease;
    display: inline-block;
    min-width: 120px;
    text-align: center;
}

/* 按钮悬停效果 */
.btn-start:hover, .btn-in-progress:hover, .btn-completed:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.8);
}

/* 禁用按钮 */
.btn-start:disabled, .btn-in-progress:disabled, .btn-completed:disabled {
    background-color: #bbb;
    border-color: #aaa;
    cursor: not-allowed;
    box-shadow: none;
}

/* 按钮点击效果 */
.btn-start:active, .btn-in-progress:active, .btn-completed:active {
    transform: scale(0.98);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.6);
}


.event-button:hover {
    transform: scale(1.1);
}

/* 任务奖励部分 */
.task-rewards {
    margin-top: 20px;
}

.rewards-title {
    font-size: 1.4em;
    color: #ff6347;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);
}

.rewards-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.reward-item {
    display: flex;
    align-items: center;
    gap: 12px;
}

.reward-icon {
    width: 24px;
    height: 24px;
}

.reward-text {
    font-size: 1.1em;
    color: #fff;
}
</style>
