<template>
    <div class="game">
        <back></back>
        <div class="game-container">
            <h1 class="game-title">人生模拟器</h1>
            <transition name="fade" mode="out-in">
                <div :key="currentScene" class="game-content">
                    <p class="game-text">{{ gameText }}</p>
                    <div v-if="options.length" class="options-container">
                        <button
                            v-for="option in options"
                            :key="option.text"
                            @click="chooseOption(option)"
                            class="option-button"
                        >
                            {{ option.text }}
                        </button>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import { game } from '@/utils/game.js';
import Back from "@/components/back.vue";

export default {
    name: "Game",
    components: {
        Back
    },
    data() {
        return {
            currentScene: game.currentScene,
            gameText: '',
            options: []
        };
    },
    created() {
        this.updateScene();
        document.title = '人生模拟器';
    },
    methods: {
        updateScene() {
            this.gameText = game.scenes[this.currentScene].text;
            this.options = game.scenes[this.currentScene].options;
        },
        chooseOption(option) {
            this.currentScene = option.next;
            this.updateScene();
        }
    }
};
</script>

<style scoped>
.game {
    min-height: 100vh;
    background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
    padding: 20px;
}

.game-container {
    max-width: 800px;
    margin: 40px auto;
    background: rgba(255, 255, 255, 0.95);
    border-radius: 15px;
    padding: 30px;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
}

.game-title {
    color: #2c3e50;
    font-size: 2.5em;
    margin-bottom: 30px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.game-content {
    margin-top: 20px;
}

.game-text {
    font-size: 1.2em;
    line-height: 1.6;
    color: #34495e;
    margin-bottom: 30px;
    padding: 20px;
    background: rgba(0, 0, 0, 0.02);
    border-radius: 8px;
}

.options-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
}

.option-button {
    width: 80%;
    padding: 15px 25px;
    font-size: 1.1em;
    color: #fff;
    background: #3498db;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.option-button:hover {
    background: #2980b9;
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.option-button:active {
    transform: translateY(1px);
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>