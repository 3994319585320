<template>
    <div id="app">
        <div id="map-container"></div>
        <div className="info">
            <h4 id="status"></h4>
            <hr>
            <p id="result"></p>
            <hr>
            <div id="myPageTop">
                <table>
                    <tr>
                        <td>
                            <label>请输入关键字：</label>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <input id="tipinput" type="text"/>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div id="searchResult" className="search-result"></div> <!-- 提示词展示区域 -->
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "MapDisplay",
    mounted() {
        this.initMap();
    },
    methods: {
        initMap() {
            // 动态加载高德地图 API 脚本
            const script = document.createElement("script");
            script.src = "https://webapi.amap.com/maps?v=2.0&key=b6db39bb8af06c94ae3f154a1fead38a";
            script.onload = () => {
                this.loadMap();
            };
            document.head.appendChild(script);
        },
        loadMap() {
            const map = new AMap.Map("map-container", {
                resizeEnable: true,
            });
            // 输入提示
            const autoOptions = {
                input: "tipinput",
                city: "北京" // 可以根据需要设置默认城市
            };

            // 加载插件并进行设置
            AMap.plugin(['AMap.PlaceSearch', 'AMap.AutoComplete'], () => {
                const auto = new AMap.AutoComplete(autoOptions);
                const placeSearch = new AMap.PlaceSearch({
                    map: map,
                });

                auto.on("select", this.select); // 监听选择事件
                this.placeSearch = placeSearch; // 保存 PlaceSearch 实例

                // 监听输入框的输入，展示提示词
                auto.on("suggestion", this.displaySuggestions); // 添加 suggestion 事件监听
            });

            // 加载定位插件
            AMap.plugin('AMap.Geolocation', () => {
                const geolocation = new AMap.Geolocation({
                    enableHighAccuracy: true,
                    timeout: 10000,
                    position: 'RB',
                    offset: [10, 20],
                    zoomToAccuracy: true,
                    showButton: true,
                    showMarker: true,
                    markerOptions: {
                        offset: new AMap.Pixel(-18, -36),
                        content: '<img src="https://a.amap.com/jsapi_demos/static/resource/img/user.png" style="width:36px;height:36px"/>'
                    },
                    showCircle: true,
                    circleOptions: {
                        strokeColor: '#0093FF',
                        noSelect: true,
                        strokeOpacity: 0.5,
                        strokeWeight: 1,
                        fillColor: '#02B0FF',
                        fillOpacity: 0.25
                    }
                });

                map.addControl(geolocation);

                geolocation.getCurrentPosition((status, result) => {
                    if (status === 'complete') {
                        this.onComplete(result);
                    } else {
                        this.onError(result);
                    }
                });
            });
        },
        // 选择输入提示后的结果
        select(e) {
            const placeSearch = this.placeSearch;
            placeSearch.setCity(e.poi.adcode);
            placeSearch.search(e.poi.name);  // 进行关键字查询
        },
        // 解析定位结果
        onComplete(data) {
            document.getElementById('status').innerHTML = '定位成功';
            const str = [];
            str.push('定位结果：' + data.position);
            str.push('定位类别：' + data.location_type);
            if (data.accuracy) {
                str.push('精度：' + data.accuracy + ' 米');
            }
            str.push('是否经过偏移：' + (data.isConverted ? '是' : '否'));
            document.getElementById('result').innerHTML = str.join('<br>');
        },
        // 解析定位错误信息
        onError(data) {
            document.getElementById('status').innerHTML = '定位失败';
            document.getElementById('result').innerHTML = `失败原因排查信息: ${data.message}<br>浏览器返回信息: ${data.originMessage}`;
        },
        // 显示输入框下方的搜索提示词
        displaySuggestions(e) {
            const suggestions = e.suggestions;
            const resultContainer = document.getElementById('searchResult');
            resultContainer.innerHTML = ''; // 清空之前的提示内容

            if (suggestions.length > 0) {
                suggestions.forEach((suggestion) => {
                    const div = document.createElement('div');
                    div.classList.add('suggestion-item');
                    div.textContent = suggestion.name;
                    div.onclick = () => this.select({poi: suggestion}); // 点击选中某项
                    resultContainer.appendChild(div);
                });
            }
        },
    },
};
</script>

<style>
#map-container {
    width: 100%;
    height: 100vh;
}

.info {
    width: 26rem;
}

#searchResult {
    position: absolute;
    top: 60px; /* 确保显示在输入框下方 */
    left: 20px;
    right: 20px;
    border: 1px solid #ccc;
    background-color: white;
    max-height: 150px;
    overflow-y: auto;
    z-index: 10;
}

.suggestion-item {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #f1f1f1;
}

.suggestion-item:hover {
    background-color: #f5f5f5;
}

#myPageTop {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 10;
}

input[type="text"] {
    width: 250px;
    padding: 8px;
    font-size: 14px;
}
</style>
