<template>

    <div class="wechatWallet_container">
        <div class="title">
            <div class="arrow" @click="goto('')">

                <img src="../assets/img/backArrow.png">
            </div>
            <div class="title-text">零钱明细</div>
        </div>
        <div class="info">
            <img src="../assets/img/fundIcon.png">
            <div style="margin-top: 4.1vh;font-weight: 400;font-size: 17px">我的零钱
            </div>
            <div style="display: flex;margin-bottom: 8px">
                <div class="rmb"><img src="../assets/img/rmb.png"></div>
                <!--                <div class="text-style" style="scale: 0.6;font-weight: normal" >¥</div>-->
                <div class="text-style">{{ amount }}</div>

            </div>
            <div style="color: #f8a527;font-weight: 400">转入零钱通，能赚又能花 ></div>
        </div>
        <div class="charge">
            <div class="btn btn--green" @click="openInput()">充值</div>
            <div style="margin-top: 2.5vh"></div>
            <div class="btn btn--white">提现</div>
            <div style="margin-top: 8vh"></div>
            <div class="faq">常见问题</div>
            <div class="service-provider">本服务由财付通提供</div>
            <div v-show="isEditing" style="margin-top: -350px;z-index: 999">
                <input v-model="amount">
            </div>
        </div>
    </div>
</template>

<script>
import {goto} from "@/utils/pathUtil";

export default {
    name: "wechatWallet",
    methods: {
        goto,
        openInput() {
            this.isEditing = !this.isEditing
        }, setSeo() {
            // 设置关键词标签
            let keywordsContent = '微信余额截图可修改';
            let keywordsMeta = document.querySelector('meta[name="keywords"]');
            if (!keywordsMeta) {
                keywordsMeta = document.createElement('meta');
                keywordsMeta.setAttribute('name', 'keywords');
                document.head.appendChild(keywordsMeta);
            }
            keywordsMeta.setAttribute('content', keywordsContent);

            // 设置描述标签
            let descriptionContent = '微信余额截图模版，可修改金额，防借钱可发朋友圈';
            let descriptionMeta = document.querySelector('meta[name="description"]');
            if (!descriptionMeta) {
                descriptionMeta = document.createElement('meta');
                descriptionMeta.setAttribute('name', 'description');
                document.head.appendChild(descriptionMeta);
            }
            descriptionMeta.setAttribute('content', descriptionContent);

            // 设置标题
            document.title = '微信余额';
        },
    },

    mounted() {
        this.setSeo()
    },
    data() {
        return {
            amount: 0.04,
            isEditing: false
        };
    }
}
</script>

<style scoped>
.wechatWallet_container {
    padding: 1px 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    display: flex;
    justify-content: space-between; /* 分散对齐，使箭头在左，标题在右 */
    width: 100%; /* 标题栏宽度设为100% */
    box-sizing: border-box; /* 边框计算在宽度内 */
}


.arrow img {
    width: 27px;
    height: 27px;
}

.title-text {
    padding-top: 0.5px;
    padding-right: 10px;
    text-align: right; /* 文本对齐到右侧 */
    font-size: 17px; /* 根据需要调整字体大小 */
    font-weight: lighter;
}

.info {
    margin-top: 6.5vh;
    display: flex;
    flex-direction: column; /* 使.info内部的元素垂直排列 */
    align-items: center; /* 垂直居中.info内部的元素 */
}

.text-style {
    margin-left: 0.5vh;
    margin-top: 0.8vh;
    color: black;
    font-size: 48px;
    font-weight: 500;
    /* 自定义文字描边效果 */
    text-shadow: -0.5px -0.5px 0.5px rgba(0, 0, 0),
    -0.5px -0.5px 0.5px rgba(0, 0, 0),
    -0.5px -0.5px 1px rgba(0, 0, 0),
    -0.5px -0.5px 0.5px rgba(0, 0, 0);
}

.info img {
    width: 53px;
}

.rmb {
    margin-top: 2.5vh;
}

.rmb img {
    height: 26px;
    width: 25px;
}

.charge {
    margin-top: 68%;
    flex-direction: column;
    align-items: center; /* 如果需要在水平方向上居中按钮，使用这个属性 */
    justify-content: center;
}

.btn {
    width: 180px; /* 按钮宽度，根据需要调整 */
    padding: 10px; /* 按钮内边距 */
    text-align: center; /* 文本居中 */
    margin-bottom: 5px; /* 按钮之间的间距 */
    box-sizing: border-box; /* 边框计算在宽度内 */
    transition: background-color 0.3s; /* 背景色变化的过渡效果 */
}

.btn--green {
    font-weight: 500;
    border-radius: 8px;
    background-color: #34C759; /* 微信绿 */
    color: white; /* 字体颜色 */
}

.btn--white {
    font-weight: 500;
    border-radius: 8px;
    background-color: rgba(192, 191, 191, 0.25); /* 白色按钮的背景色 */
    color: black; /* 字体颜色 */
}

.faq, .service-provider {
    font-family: 'Microsoft YaHei', sans-serif; /* 使用微软雅黑字体 */
    text-align: center; /* 文本居中 */
    color: #002188; /* 辅助文字颜色 */
    font-size: 14px; /* 字体大小 */
    margin-bottom: 10px; /* 下边距 */
}

.service-provider {
    font-size: 12px; /* 字体大小可以稍小一些 */
    color: #b2b2b2; /* 更浅的灰色 */
}
</style>