<template>
    <div class="many-why-container">
        <back></back>
        <div class="header">
            <div class="title">
                不要不信邪
                <img src="../assets/img/notWhy.png" alt="不为什么">
            </div>
        </div>
        <div class="content-container" ref="container">
            <div 
                v-for="index in displayedItems" 
                :key="index"
                class="why-item"
            >
                不为什么
            </div>
        </div>
    </div>
</template>

<script>
import Back from "@/components/back.vue";

export default {
    name: 'ManyWhy',
    components: { Back },
    
    data() {
        return {
            totalItems: 100000,
            itemsPerPage: 1000,
            loadedItems: 1000
        };
    },

    computed: {
        displayedItems() {
            return Array.from({ length: this.loadedItems }, (_, index) => index);
        }
    },

    mounted() {
        this.initScrollListener();
    },

    beforeDestroy() {
        this.removeScrollListener();
    },

    methods: {
        initScrollListener() {
            window.addEventListener('scroll', this.handleScroll);
        },

        removeScrollListener() {
            window.removeEventListener('scroll', this.handleScroll);
        },

        handleScroll() {
            const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
            const scrollThreshold = 100;
            
            if (scrollTop + clientHeight >= scrollHeight - scrollThreshold) {
                this.loadMoreItems();
            }
        },

        loadMoreItems() {
            if (this.loadedItems < this.totalItems) {
                this.loadedItems += this.itemsPerPage;
            }
        }
    }
}
</script>

<style scoped>
.many-why-container {
    background: rgba(185, 231, 195, 0.35);
    min-height: 100vh;
}

.header {
    display: flex;
    justify-content: center;
    padding: 20px 0;
}

.title {
    font-size: 2.5rem;
    color: #dc0000;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title img {
    max-width: 100%;
    height: auto;
    margin-top: 10px;
}

.content-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 5px;
    padding: 20px;
}

.why-item {
    font-size: 10px;
    font-weight: bold;
    padding: 5px;
    transition: transform 0.2s ease;
}

.why-item:hover {
    transform: scale(1.1);
}
</style>