<template>
    <div class="csr-container">
        <back></back>
        <div class="home">
            <div v-for="row in page" :key="row.path" @click="goto(row.path)" class="menu"
                 :style="`background-image: url(${row.background})`" :aria-label="row.name">
                {{ row.name }}
            </div>
        </div>
    </div>
</template>

<script>
import { goto } from "@/utils/pathUtil";
import NavigationBar from "@/components/navigationBar.vue";
import { log } from "@/utils/logHelper.js";
import Back from "@/components/back.vue";

export default {
    name: "scrapped",
    components: { Back, NavigationBar },
    methods: {
        openAdministrator() {
            this.clickTime += 1
            if (this.clickTime > 3) {
                this.goto('backOffice')
            }
        },
        setSeo() {
            // 设置SEO meta标签
            let keywordsContent = 'Lachesism, 我是风暴, 废案页面';
            let keywordsMeta = document.querySelector('meta[name="keywords"]');
            if (!keywordsMeta) {
                keywordsMeta = document.createElement('meta');
                keywordsMeta.setAttribute('name', 'keywords');
                document.head.appendChild(keywordsMeta);
            }
            keywordsMeta.setAttribute('content', keywordsContent);

            let descriptionContent = 'Lachesism废案页面 - 我是风暴';
            let descriptionMeta = document.querySelector('meta[name="description"]');
            if (!descriptionMeta) {
                descriptionMeta = document.createElement('meta');
                descriptionMeta.setAttribute('name', 'description');
                document.head.appendChild(descriptionMeta);
            }
            descriptionMeta.setAttribute('content', descriptionContent);

            // 设置标题
            document.title = 'Lachesism - 废案页面';
        },
        goto
    },
    mounted() {
        this.setSeo()
    },
    data() {
        return {
            clickTime: 0,
            page: [
                {'name': '临时', 'path': 'temp', 'background': require('@/assets/img/cover/temp.png')},
                {'name': '算命轮盘', 'path': 'gossip', 'background': require('@/assets/img/cover/gossip.png')},
                {'name': 'MBTI测试', 'path': 'infp', 'background': require('@/assets/img/cover/infp.png')},
                {'name': 'NotFound', 'path': '404', 'background': require('@/assets/img/cover/404.png')},
                {'name': '王氏祖训', 'path': 'familyMotto', 'background': require('@/assets/img/cover/role.png')},
                {'name': '弱智冒险游戏', 'path': 'simpleGame', 'background': require('@/assets/img/cover/simpleGame.png')},
                {'name': '算大姨妈时间', 'path': 'period', 'background': require('@/assets/img/cover/period.png')},
                {'name': '任务列表', 'path': 'task', 'background': require('@/assets/img/cover/task.png')},
                {'name': '设备信息', 'path': 'deviceInfo', 'background': require('@/assets/img/cover/deviceInfo.png')},
                {'name': '每天看美女', 'path': 'video', 'background': require('@/assets/img/cover/video.png')},
                {'name': '老黄历', 'path': 'lunar', 'background': require('@/assets/img/cover/lunar.png')},
                {'name': '地图', 'path': 'map', 'background': require('@/assets/img/cover/map.png')},
                {'name': '牌', 'path': 'introductory', 'background': require('@/assets/img/cover/balckSun.png')},
                {'name': 'websocket测试', 'path': 'websocket', 'background': require('@/assets/img/cover/websocket.png')},
                {'name': '工具页', 'path': 'tools', 'background': require('@/assets/img/cover/tools.png')},
                {'name': '王氏祖训', 'path': 'familyMotto', 'background': require('@/assets/img/cover/role.png')},
                {'name': '计算器', 'path': 'calculator', 'background': require('@/assets/img/cover/calculator.png')},
            ],
        }
    },
}
</script>

<style scoped>

.csr-container{
    justify-content: center;

    transition: background-color 0.5s ease, background-image 0.5s ease;
    /* 渐变色背景 */
    background-image: linear-gradient(45deg, #ffcccb, #f0f8ff); /* 粉色到淡蓝色的渐变 */

}
.home {
    display: flex;
    margin-top: 2vh;
    flex-wrap: wrap;
    max-width: 100%;
    height: 98%;
    margin-left: -5px;
    padding: 1%;
    justify-content: space-around;
}

.menu {
    color: rgb(251, 252, 251);
    text-shadow: 0 0 10px rgba(243, 4, 88, 0.7),
    0 0 20px rgba(243, 4, 88, 0.5),
    0 0 30px rgba(243, 4, 88, 0.3);
    font-weight: 900;
    font-size: 25px;
    font-family: "Heiti TC", sans-serif;
    text-align: left;
    background-size: cover;
    flex: 1 1 20%;
    min-height: 30vh;
    min-width: 400px;
    border-radius: 8px;
    margin: 1vh;
    box-sizing: border-box;
    box-shadow: 3px 4px 8px rgba(86, 77, 77, 0.7);
}

.menu:hover {
    transform: scale(0.98);
    box-shadow: 8px 10px 12px rgba(86, 77, 77, 0.5);
    transition: all 0.3s ease;
}

.home_title img {
    height: 3vh;
    position: absolute;
    left: 2vh;
    top: 10px;
    cursor: pointer;
}
</style>
