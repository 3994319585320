<template>
    <div class="admin-container">
        <back></back>
        <header class="admin-header">
            <img src="../assets/YunOx2.png" @click="backUpLevel" alt="Back">
        </header>

        <!-- Main Menu -->
        <div class="admin-content" v-if="module == null">
            <div class="menu-grid">
                <div class="menu-item file-item" @click="choseModule(1)">
                    <i class="fas fa-folder"></i>
                    <span>文件管理</span>
                </div>
                <div class="menu-item database-item" @click="choseModule(2)">
                    <i class="fas fa-database"></i>
                    <span>数据库信息</span>
                </div>
            </div>
        </div>

        <!-- Module Content -->
        <div class="module-content" v-if="module !== null">
            <!-- File Management Module -->
            <transition name="slide-fade" mode="out-in">
                <div class="file-manager" v-if="module === 1">
                    <div class="sidebar" v-if="files === null">
                        <div class="sidebar-header" @click="isShowImgDist=false">目录导航</div>
                        <nav class="sidebar-nav">
                            <div class="nav-item" @click="getDistInfo('/file/')">
                                <i class="fas fa-file"></i>文件
                            </div>
                            <div class="nav-group">
                                <div class="nav-item" @click="isShowImgDist=!isShowImgDist">
                                    <i class="fas fa-image"></i>图片
                                    <i class="fas fa-chevron-down" v-if="!isShowImgDist"></i>
                                    <i class="fas fa-chevron-up" v-else></i>
                                </div>
                                <div class="nav-submenu" v-show="isShowImgDist">
                                    <div class="nav-item" @click="getDistInfo('/image/ultraman/')">奥特曼</div>
                                    <div class="nav-item" @click="getDistInfo('/image/ttf/equipment/')">云顶装备</div>
                                    <div class="nav-item" @click="getDistInfo('/image/ttf/character/')">云顶英雄</div>
                                </div>
                            </div>
                            <div class="nav-item" @click="getDistInfo('/doc/')">
                                <i class="fas fa-file-alt"></i>文档
                            </div>
                            <div class="nav-item" @click="getDistInfo('/icon/')">
                                <i class="fas fa-user-circle"></i>头像
                            </div>
                        </nav>
                    </div>

                    <div class="file-content" v-if="files !== null">
                        <div class="content-header">
                            <button class="back-btn" @click="files=null">
                                <i class="fas fa-arrow-left"></i> 返回
                            </button>
                        </div>
                        <div class="file-list">
                            <div v-for="file in files" :key="file" class="file-item">
                                <div class="file-info">
                                    <img :src="getFileSrc(file)" class="file-icon" @click="getImg(file)">
                                    <span class="file-name">{{ formatFileName(file.fileName) }}</span>
                                    <span class="file-size">{{ file.size }}</span>
                                </div>
                                <div class="file-actions">
                                    <button class="action-btn download" @click="download(file)">
                                        <i class="fas fa-download"></i>
                                    </button>
                                    <button class="action-btn delete" @click="deleteFile(file)">
                                        <i class="fas fa-trash"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>

            <!-- Database Module -->
            <transition name="slide-fade" mode="out-in">
                <div class="database-manager" v-if="module === 2">
                    <div class="sidebar" v-if="dbFiles === null">
                        <div class="sidebar-header">数据库管理</div>
                        <nav class="sidebar-nav">
                            <div class="nav-item" @click="getDBInfo(1)">
                                <i class="fas fa-file-code"></i>文件记录
                            </div>
                            <div class="nav-item" @click="getDBInfo(2)">
                                <i class="fas fa-comments"></i>消息记录
                            </div>
                        </nav>
                    </div>

                    <div class="db-content" v-if="dbFiles !== null">
                        <div class="content-header">
                            <button class="back-btn" @click="dbFiles=null">
                                <i class="fas fa-arrow-left"></i> 返回
                            </button>
                        </div>
                        <div class="db-list">
                            <div v-for="file in dbFiles" :key="file" class="db-item">
                                <div class="content-text">{{ file.content }}</div>
                                <div class="code-block">
                                    <span class="code-tag">code</span>
                                    <code>{{ file.code }}</code>
                                    <button class="delete-btn" @click="deleteFromDBFile(file)">
                                        <i class="fas fa-times"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import Back from "@/components/back.vue";
import {deleteDBFile, deleteFile, getDBFileInfo, getFolderInfo} from "@/api/yunoApi";

export default {
    name: "admin",
    components: {Back},
    data() {
        return {
            path: null,
            module: null,
            files: null,
            dbFiles: null,
            isShowImgDist: false,
            type: null,
        }
    },
    methods: {
        deleteFromDBFile(file) {
            deleteDBFile(this.type, file.id).then(() => {
                this.getDBInfo(this.type)
            })
        },
        getDBInfo(type) {
            this.type = type
            getDBFileInfo(type).then(res => {
                this.dbFiles = res
            })
        },
        getFileSrc(file) {
            const imageTypes = ['png', 'jpeg', 'jpg', 'gif', 'bmp', 'tiff', 'tif', 'webp', 'svg']
            const fileType = file.type.toLowerCase()
            return imageTypes.includes(fileType) 
                ? file.url 
                : 'https://www.yunox.cn/image/blank.png'
        },
        deleteFile(file) {
            deleteFile({path: file.path}).then(res => {
                if (res) {
                    this.getFolderInfo()
                } else {
                    this.$message.error("删除失败")
                }
            })
        },
        download(file) {
            const link = document.createElement('a')
            link.href = file.url
            link.download = file.fileName
            link.click()
        },
        getImg(file) {
            const imageTypes = ['png', 'jpeg', 'jpg', 'gif', 'bmp', 'tiff', 'tif', 'webp', 'svg']
            if (imageTypes.includes(file.type.toLowerCase())) {
                this.download(file)
            }
        },
        formatFileName(fileName) {
            const dotIndex = fileName.lastIndexOf('.')
            if (dotIndex > 6) {
                return fileName.substring(dotIndex - 6) 
            }
            return fileName
        },
        choseModule(value) {
            this.module = value
        },
        backUpLevel() {
            this.module = null
        },
        getDistInfo(path) {
            this.path = path
            this.getFolderInfo()
        },
        getFolderInfo() {
            getFolderInfo({path: this.path}).then(res => {
                res.forEach(item => {
                    item.url = item.url.replace('/media/image', '/image')
                    item.url = item.url.replace('/media/icon', '/icon')
                    item.url = item.url.replace('/media/file', '/files')
                })
                this.files = res
            })
        }
    }
}
</script>

<style scoped>
.admin-container {
    min-height: 100vh;
    background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
    padding: 1rem;
}

.admin-header {
    background: rgba(255, 255, 255, 0.9);
    border-radius: 1rem;
    padding: 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 1.5rem;
}

.admin-header img {
    max-width: 200px;
    transition: transform 0.3s ease;
}

.admin-header img:hover {
    transform: scale(1.05);
}

.menu-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;
    padding: 1rem;
}

.menu-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    border-radius: 1rem;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    cursor: pointer;
}

.menu-item i {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.menu-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
}

.file-item {
    background: linear-gradient(135deg, #ff9a9e 0%, #fad0c4 100%);
}

.database-item {
    background: linear-gradient(135deg, #84fab0 0%, #8fd3f4 100%);
}

.module-content {
    background: rgba(255, 255, 255, 0.9);
    border-radius: 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.sidebar {
    width: 250px;
    background: rgba(255, 255, 255, 0.95);
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    padding: 1rem;
}

.sidebar-header {
    font-size: 1.25rem;
    font-weight: bold;
    padding: 1rem;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}

.nav-item {
    padding: 0.75rem 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.nav-item:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.nav-submenu {
    padding-left: 1.5rem;
}

.file-list, .db-list {
    padding: 1rem;
}

.file-item, .db-item {
    background: white;
    border-radius: 0.5rem;
    padding: 1rem;
    margin-bottom: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.file-info {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.file-icon {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 0.25rem;
}

.file-actions {
    display: flex;
    gap: 0.5rem;
}

.action-btn {
    padding: 0.5rem;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.download {
    background-color: #4CAF50;
    color: white;
}

.delete {
    background-color: #f44336;
    color: white;
}

/* Animations */
.slide-fade-enter-active,
.slide-fade-leave-active {
    transition: all 0.3s ease;
}

.slide-fade-enter-from {
    transform: translateX(20px);
    opacity: 0;
}

.slide-fade-leave-to {
    transform: translateX(-20px);
    opacity: 0;
}

@media (max-width: 768px) {
    .menu-grid {
        grid-template-columns: 1fr;
    }
    
    .sidebar {
        width: 100%;
    }
}

@media (prefers-reduced-motion: reduce) {
    * {
        animation: none !important;
        transition: none !important;
    }
}
</style>