<template>
    <div>
        <back></back>
        <!-- 表格 -->
        <table border="1">
            <!-- 表头 -->
            <thead>
            <tr>
                <th>异常信息</th>
                <th>堆栈信息</th>
                <th>详情</th>
                <th>请求 URI</th>
                <th>请求方法</th>
                <th>用户代理</th>
                <th>时间</th>
                <th>方法名</th>
            </tr>
            </thead>
            <!-- 表格主体 -->
            <tbody>
            <tr v-for="(log, id) in errLogList.filter(log => log.exceptionMessage)" :key="id">
                <td>{{ log.exceptionMessage }}</td>
                <td>
                    <span v-if="!log.showFullStackTrace">
                        {{
                        log.stackTrace && log.stackTrace.length > 0 ? log.stackTrace.substring(0, 80) + '...' : '无堆栈信息'
                        }}
                    </span>
                </td>
                <td>
                    <button class="btn-expand" @click="showStackTraceInModal(log.stackTrace)">展开</button>
                </td>
                <td>{{ log.requestUri }}</td>
                <td>{{ log.method }}</td>
                <td>{{ log.userAgent }}</td>
                <td>{{ formatTimestamp(log.timestamp) }}</td>
                <td>{{ log.methodName }}</td>
            </tr>
            </tbody>
        </table>

        <!-- 弹窗 -->
        <div v-if="isModalVisible" class="modal" @click="closeModalOutside">
            <div class="modal-content" @click.stop>
                <span class="close-btn" @click="closeModal">&times;</span>
                <pre>{{ currentStackTrace }}</pre>
            </div>
        </div>

        <!-- 加载更多按钮 -->
        <div v-if="errLogList.length < totalCount" class="load-more-container">
            <button class="btn-load-more" @click="getMoreLog" :disabled="isLoading">
                {{ isLoading ? '加载中...' : '加载更多' }}
            </button>
        </div>
    </div>
</template>

<script>
import {getErrorLog} from "@/api/yunoApi";
import {formatTimestamp} from "@/utils/parse";
import Back from "@/components/back.vue";

export default {
    name: "errLog",
    components: {Back},
    data() {
        return {
            errLogList: [],
            pageNum: 1,
            isModalVisible: false,
            currentStackTrace: '',
            totalCount: 0,
            isLoading: false,  // 用于控制按钮的加载状态
        };
    },
    methods: {
        handleScroll() {
            if (this.isLoading || this.errLogList.length >= this.totalCount) return;
            const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
            // 如果滚动到底部并且有更多数据可加载
            if (scrollTop + clientHeight >= scrollHeight - 100) {
                this.getMoreLog();
            }
        },
        formatTimestamp,
        getMoreLog() {
            if (this.isLoading || this.errLogList.length >= this.totalCount) return; // 防止重复点击
            this.isLoading = true;  // 开始加载
            this.pageNum++; // 下一页
            this.getErrorLog();
        },
        getErrorLog() {
            getErrorLog(this.pageNum).then((res) => {
                res.list.forEach(log => {
                    this.errLogList.push({
                        ...log,
                        showFullStackTrace: false,
                    });
                });
                this.totalCount = res.totalCount;
                this.isLoading = false;  // 完成加载
            }).catch(() => {
                this.isLoading = false;  // 出现错误时也停止加载状态
            });
        },
        showStackTraceInModal(stackTrace) {
            this.currentStackTrace = stackTrace || '无堆栈信息';
            this.isModalVisible = true;
        },
        closeModal() {
            this.isModalVisible = false;
            this.currentStackTrace = ''; // 清空堆栈信息
        },
        closeModalOutside(event) {
            if (event.target === event.currentTarget) {
                this.closeModal();
            }
        }
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
        this.getErrorLog(); // 初始化加载日志
    }, destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    }
};
</script>

<style scoped>
/* 表格样式 */
table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 50px;
}

th, td {
    padding: 8px 12px;
    text-align: left;
    border: 1px solid #ddd;
}

th {
    background-color: #f2f2f2;
}

td {
    word-wrap: break-word;
    max-width: 180px;
}

/* 弹窗样式 */
.modal {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    overflow: auto;
}

.modal-content {
    background-color: #fff;
    margin: 5% auto;
    padding: 20px;
    text-align: left;
    width: 90%;
    max-width: 1200px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-height: 80vh;
    overflow-y: auto;
}

.close-btn {
    color: #aaa;
    font-size: 28px;
    font-weight: bold;
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
}

.close-btn:hover,
.close-btn:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

/* 加载更多按钮样式 */
.load-more-container {
    text-align: center;
    margin-top: 20px;
}

.btn-load-more {
    padding: 8px 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
}

.btn-load-more:hover {
    background-color: #0056b3;
}

.btn-load-more:disabled {
    background-color: #d6d6d6;
    cursor: not-allowed;
}

/* 响应式设计 */
@media (max-width: 768px) {
    table {
        font-size: 12px;
    }
}

@media (max-width: 480px) {
    table {
        font-size: 10px;
    }

    td {
        max-width: 150px;
    }
}
</style>
