<template>
    <div>
        <div class="home_title">
            <img src="../assets/img/YunOx3.png" @click="openAdministrator()">
        </div>
        <navigationBar></navigationBar>
        <div class="home">
            <div v-for="row in page" :key="row" @click="goto(row.path)" class="menu"
                 :style="`background-image: url(${row.background})`"> {{ row.name }}
            </div>
        </div>
    </div>
</template>

<script>
import {goto} from "@/utils/pathUtil";
import NavigationBar from "@/components/navigationBar.vue";
import {log} from "@/utils/logHelper.js";

export default {
    name: "home",
    components: {NavigationBar},
    methods: {
        openAdministrator() {
            this.clickTime += 1
            if (this.clickTime > 1) {
                this.goto('backOffice')
                this.clickTime = 0
            }
        },
        setSeo() {
            // 设置页面标题
            document.title = "I am the storm that is approaching";

            // 设置页面描述
            document
                .querySelector('meta[name="description"]')
                .setAttribute("content", "那时魔鬼引诱我，后又告诉我，说我没有资格走那条路");

            // 设置页面关键词
            document
                .querySelector('meta[name="keywords"]')
                .setAttribute("content", "Lachesism, Tools, Ideas, Philosophy, Blog, Creative Writing, Util, food, 思维, 创意, 写作, 哲学, 工具, 生活, 情感, 文学, 心理学, 思考, 深度, 科技, 心灵, 梦想, 心理, 情绪, 创意写作, 人生哲学, 自我成长, 自由思想, 自我表达, 生活哲学, 人文, 自我探索, 思考的艺术, 人生意义, 文化, 艺术, 创意工具, 思维工具, 知识分享, 自我提升, 思维训练, 自由写作, 小说, 故事创作, 创意思维, 日常写作, 心灵成长, 情感表达, 心理咨询, 自我认知, 个人成长, 静思, 创作灵感, 生活方式, 自由生活, 个人哲学, 生活美学, 实用工具 , 表格工具");

            // 设置页面作者
            document
                .querySelector('meta[name="author"]')
                .setAttribute("content", "YunOx");

            // 设置页面版权
            document
                .querySelector('meta[name="copyright"]')
                .setAttribute("content", "YunOx");

            // 设置页面的字符集
            document
                .querySelector('meta[charset]')
                .setAttribute("charset", "UTF-8");

            // 设置社交媒体分享信息（Open Graph）
            document
                .querySelector('meta[property="og:title"]')
                .setAttribute("content", "I am the storm that is approaching");

            document
                .querySelector('meta[property="og:description"]')
                .setAttribute("content", "那时魔鬼引诱我，后又告诉我，说我没有资格走那条路");

            document
                .querySelector('meta[property="og:image"]')
                .setAttribute("content", "https://www.yunox.cn/image/Ali.png"); // 这里填写你页面的预览图链接

            document
                .querySelector('meta[property="og:url"]')
                .setAttribute("content", window.location.href); // 动态获取当前页面 URL

            // 设置 Twitter 卡片（可选，提升 Twitter 分享体验）
            document
                .querySelector('meta[name="twitter:card"]')
                .setAttribute("content", "summary_large_image");

            document
                .querySelector('meta[name="twitter:title"]')
                .setAttribute("content", "I am the storm that is approaching");

            document
                .querySelector('meta[name="twitter:description"]')
                .setAttribute("content", "那时魔鬼引诱我，后又告诉我，说我没有资格走那条路");

            document
                .querySelector('meta[name="twitter:image"]')
                .setAttribute("content", "https://www.yunox.cn/image/Ali.png"); // 这里填写你页面的预览图链接

            // 设置页面的语言（可选，适用于多语言站点）
            document
                .querySelector('meta[name="language"]')
                .setAttribute("content", "zh-CN"); // 简体中文

            // 设置网站的图标（可选）
            document
                .querySelector('link[rel="icon"]')
                .setAttribute("href", "https://www.yunox.cn/image/Ali.png"); // 这里填写你站点的图标链接
        },
        goto
    },
    mounted() {
        this.setSeo()
    },
    data() {
        return {
            clickTime: 0,
            page: [
                {'name': '云顶阵容', 'path': 'lineup', 'background': require('@/assets/img/cover/10110.jpg')},
                {'name': '白日梦规划', 'path': 'dayDream', 'background': require('@/assets/img/cover/lottery.png')},
                {
                    'name': '星座运势️',
                    'path': 'constellation',
                    'background': require('@/assets/img/cover/universe.png')
                },
                {'name': '备忘录', 'path': 'memorandum', 'background': require('@/assets/img/cover/memorandum.png')},
                {'name': '光阴教训', 'path': 'timeLessons', 'background': require('@/assets/img/cover/ouroboros.png')},
                {'name': '答案之书', 'path': 'answer', 'background': require('@/assets/img/cover/answerBook.png')},
                {'name': '网站更新记录', 'path': 'updateLog', 'background': require('@/assets/img/cover/updateLog.png')},
                {'name': '文件转发', 'path': 'postal', 'background': require('@/assets/img/cover/postal.png')},
                {'name': '小纸条', 'path': 'notes', 'background': require('@/assets/img/cover/note.png')},
                {'name': '人物大全', 'path': 'characters', 'background': require('@/assets/img/cover/characters.png')},
                {'name': '每天吃什么', 'path': 'eat', 'background': require('@/assets/img/cover/food.png')},
                {'name': '待办事项', 'path': 'todoList', 'background': require('@/assets/img/cover/todoList.png')},
                {'name': '余额截图', 'path': 'wechat', 'background': require('@/assets/img/cover/noMoney.png')},
                {'name': '十万个不为什么', 'path': 'question', 'background': require('@/assets/img/cover/whyBg.png')},
                {'name': '颜色大全', 'path': 'color', 'background': require('@/assets/img/cover/color.png')},
                {'name': '刑法大全', 'path': 'law', 'background': require('@/assets/img/cover/law.png')},
                {'name': '每天100个单词', 'path': 'word', 'background': require('@/assets/img/cover/dictionary.png')},
                {'name': '老虎机', 'path': 'machine', 'background': require('@/assets/img/cover/jackpot.png')},
                {'name': '记账本', 'path': 'bill', 'background': require('@/assets/img/cover/bill.png')},
                {'name': '快捷复制器', 'path': 'copy', 'background': require('@/assets/img/cover/copy.png')},
                {'name': '二维码生成器', 'path': 'QRCode', 'background': require('@/assets/img/cover/QRCode.png')},
                {
                    'name': '证书生成器',
                    'path': 'generateCert',
                    'background': require('@/assets/img/cover/generateCert.png')
                },
                {'name': '在线做表',
                    'path': 'xlsx',
                    'background': require('@/assets/img/cover/excel.png')
                },
                {'name': '点子收集器/建议', 'path': 'opinion', 'background': require('@/assets/img/cover/suggestion.png')},
                {'name': '废案', 'path': 'scrapped', 'background': require('@/assets/img/cover/scrapped.png')},
                {'name': '更新记录', 'path': 'updateLog', 'background': require('@/assets/img/cover/updateLog.png')},
            ],
        }
    },

}
</script>

<style scoped>

.home {
    display: flex;
    margin-top: 3vh;
    flex-wrap: wrap; /* 允许子元素换行 */
    /*width: 97vw; !* 宽度设为视口宽度 *!*/
    max-width: 100%; /* 确保最大宽度不超过视口宽度 */
    height: 98%;
    margin-left: -5px;
    padding: 1%; /* 给内容留出一些空间，避免紧贴边缘 */
    justify-content: space-around; /* 子元素在主轴上平均分布 */
}

.menu {
    color: rgb(251, 252, 251);
    text-shadow: 0 0 10px rgba(243, 4, 88, 0.7),
    0 0 20px rgba(243, 4, 88, 0.5),
    0 0 30px rgba(243, 4, 88, 0.3);
    font-weight: 900; /* 增加字重，使文字更粗 */
    font-size: 25px;
    font-family: "Heiti TC", sans-serif; /* 确保有合适的后备字体 */
    text-align: left;
    background-size: cover; /* 背景图片覆盖整个元素，保持宽高比 */
    flex: 1 1 20%;
    min-height: 30vh;
    min-width: 400px;
    border-radius: 8px;
    margin: 1vh;
    box-sizing: border-box;
    box-shadow: 3px 4px 8px rgba(86, 77, 77, 0.7); /* 添加阴影效果 */
}

.menu:hover {
    transform: scale(0.98);
    box-shadow: 8px 10px 12px rgba(86, 77, 77, 0.5); /* 更深的阴影 */
    transition: all 0.3s ease; /* 平滑过渡效果 */
}

.home_title {
    display: flex;
    padding-bottom: 1px;
}

.home_title img {
    height: 3vh; /* 图片高度设置为视口高度的3% */
    position: absolute; /* 设置图片为绝对定位 */
    left: 2vh; /* 将图片定位到容器的最左边 */
    top: 10px; /* 如果需要，可以设置图片的顶部位置，这里假设为顶部对齐 */
    cursor: pointer;
}
</style>