<template>
    <div class="bill-page">
        <back></back>
        <div class="author">
            <input
                type="text"
                v-model="author"
                @blur="handleInputEnd"
                @focus="handleInputFocus"
                placeholder="请输入作者"
                class="author-input"
                :class="{'input-focus': isInputFocused}"
            />
        </div>

        <!-- 账单表格 -->
        <div class="billTable">
            <table>
                <thead>
                <tr>
                    <th>总价</th>
                    <th>数量</th>
                    <th>名称</th>
                    <th>单价</th>
                    <th>售价</th>
                    <th>已售</th>
                    <th>剩余</th>
                    <th>毛利</th>
                    <th>净利</th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="item in billList"
                    :key="item.id"
                    class="bill-row"
                    @click="handleRowClick(item)"
                >
                    <td>{{ item.cost.toFixed(2) }}</td>
                    <td>{{ item.count }}</td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.costPrice.toFixed(2) }}</td>
                    <td>{{ item.sellPrice.toFixed(2) }}</td>
                    <td>{{ item.sold }}</td>
                    <td>{{ item.remainder }}</td>
                    <td :class="{'profit-positive': item.grossProfit > 0, 'profit-negative': item.grossProfit < 0}">
                        {{ item.grossProfit.toFixed(2) }}
                    </td>
                    <td :class="{'profit-positive': item.netProfit > 0, 'profit-negative': item.netProfit < 0}">
                        {{ item.netProfit.toFixed(2) }}
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

        <div class="billAddButton" 
             @click="addBill()"
             @mouseenter="handleButtonHover"
             @mouseleave="handleButtonLeave">
            <span class="plus-icon">+</span>
        </div>

        <!-- 统计信息 -->
        <div class="statistics">
            <table>
                <thead>
                <tr>
                    <th>总价</th>
                    <th>数量</th>
                    <th>已售</th>
                    <th>剩余</th>
                    <th>毛利</th>
                    <th>净利</th>
                    <th>总盈利</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>{{ statistics.totalCost.toFixed(2) }}</td>
                    <td>{{ statistics.totalCount }}</td>
                    <td>{{ statistics.totalSold }}</td>
                    <td>{{ statistics.totalRemainder }}</td>
                    <td :class="{'profit-positive': statistics.totalGrossProfit > 0, 'profit-negative': statistics.totalGrossProfit < 0}">
                        {{ statistics.totalGrossProfit.toFixed(2) }}
                    </td>
                    <td :class="{'profit-positive': statistics.totalNetProfit > 0, 'profit-negative': statistics.totalNetProfit < 0}">
                        {{ statistics.totalNetProfit.toFixed(2) }}
                    </td>
                    <td :class="{'profit-positive': statistics.totalProfit > 0, 'profit-negative': statistics.totalProfit < 0}">
                        {{ statistics.totalProfit.toFixed(2) }}
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import Back from "@/components/back.vue";
import { addBill, getBillList } from "@/api/yunoApi";

export default {
    name: "Bill",
    components: { Back },
    data() {
        return {
            billList: [],
            bill: {
                id: 0,
                cost: 0,
                count: 0,
                name: '',
                costPrice: 0,
                sellPrice: 0,
                sold: 0,
                remainder: 0,
                grossProfit: 0,
                netProfit: 0,
                user: null
            },
            statistics: {
                totalCost: 0,
                totalCount: 0,
                totalSold: 0,
                totalRemainder: 0,
                totalGrossProfit: 0,
                totalNetProfit: 0,
                totalProfit: 0
            },
            author: localStorage.getItem('billName') ? localStorage.getItem('billName') : null,
            isInputFocused: false,
            isButtonHovered: false
        };
    },
    methods: {
        handleInputFocus() {
            this.isInputFocused = true;
        },
        handleInputEnd() {
            this.isInputFocused = false;
            if (this.author === '') {
                localStorage.removeItem('billName')
            } else {
                localStorage.setItem('billName', this.author)
                this.getBillList()
            }
        },
        handleButtonHover() {
            this.isButtonHovered = true;
        },
        handleButtonLeave() {
            this.isButtonHovered = false;
        },
        handleRowClick(item) {
            // 添加行点击效果
            console.log('Row clicked:', item);
        },
        addBill() {
            if (!this.author) {
                alert('请先输入作者名称');
                return;
            }
            this.bill.user = this.author
            addBill(this.bill).then(res => {
                this.bill.id = res
                this.billList.push({...this.bill})
                this.bill = {
                    id: 0,
                    cost: 0,
                    count: 0,
                    name: '',
                    costPrice: 0,
                    sellPrice: 0,
                    sold: 0,
                    remainder: 0,
                    grossProfit: 0,
                    netProfit: 0,
                    author: null
                }
            }).catch(err => {
                console.error('添加账单失败:', err);
            })
        },
        getBillList() {
            if (this.author === '') return;
            const bill = { user: this.author }
            getBillList(bill).then(res => {
                this.billList = res
            }).catch(err => {
                console.error('获取账单列表失败:', err);
            })
        },
        calculateStatistics() {
            const stats = {
                totalCost: 0,
                totalCount: 0,
                totalSold: 0,
                totalRemainder: 0,
                totalGrossProfit: 0,
                totalNetProfit: 0,
                totalProfit: 0
            };
            
            this.billList.forEach(item => {
                stats.totalCost += item.cost;
                stats.totalCount += item.count;
                stats.totalSold += item.sold;
                stats.totalRemainder += item.remainder;
                stats.totalGrossProfit += item.grossProfit;
                stats.totalNetProfit += item.netProfit;
            });
            
            stats.totalProfit = stats.totalGrossProfit + stats.totalNetProfit;
            this.statistics = stats;
        },
        setSeo() {
            document.title = "账单管理 - 记录和管理您的账单信息";
            document
                .querySelector('meta[name="description"]')
                .setAttribute("content", "在此页面，您可以查看和管理您的账单信息，包括总价、数量、毛利和净利等。输入作者名称以保存账单记录。");
            document
                .querySelector('meta[name="keywords"]')
                .setAttribute("content", "账单管理, 账单记录, 财务统计, 收入, 支出, 毛利, 净利");
        }
    },
    mounted() {
        this.setSeo()
        this.getBillList()
        this.calculateStatistics()
    },
    watch: {
        billList: {
            handler(newVal) {
                this.calculateStatistics()
            },
            deep: true
        }
    }
};
</script>

<style scoped>
.bill-page {
    font-family: 'Helvetica Neue', sans-serif;
    background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
    min-height: 100vh;
    padding: 20px;
}

/* Header (Author input) */
.author {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.author-input {
    padding: 12px 20px;
    font-size: 16px;
    border: 2px solid #e0e0e0;
    border-radius: 25px;
    width: 200px;
    background-color: rgba(255, 255, 255, 0.9);
    transition: all 0.3s ease;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.author-input:hover {
    border-color: #90caf9;
}

.input-focus {
    border-color: #2196f3;
    box-shadow: 0 0 10px rgba(33,150,243,0.3);
}

/* Table styling */
table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    margin-top: 20px;
    background-color: white;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

th, td {
    padding: 15px;
    text-align: center;
    font-size: 14px;
    border-bottom: 1px solid #eee;
}

th {
    background-color: #2196f3;
    color: white;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
}

td {
    background-color: rgba(255, 255, 255, 0.95);
    transition: all 0.3s ease;
}

.bill-row {
    cursor: pointer;
    transition: all 0.3s ease;
}

.bill-row:hover td {
    background-color: #e3f2fd;
    transform: translateY(-2px);
}

.profit-positive {
    color: #4caf50;
    font-weight: bold;
}

.profit-negative {
    color: #f44336;
    font-weight: bold;
}

/* Add Bill Button */
.billAddButton {
    position: relative;
    margin: 30px auto;
    background: linear-gradient(145deg, #2196f3, #1976d2);
    color: white;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    font-size: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 6px 15px rgba(33,150,243,0.3);
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.billAddButton:hover {
    transform: translateY(-3px) scale(1.05);
    box-shadow: 0 8px 20px rgba(33,150,243,0.4);
}

.billAddButton:active {
    transform: translateY(1px);
}

.plus-icon {
    transition: transform 0.3s ease;
}

.billAddButton:hover .plus-icon {
    transform: rotate(90deg);
}

/* Statistics Table */
.statistics {
    margin-top: 40px;
}

.statistics th {
    background-color: #673ab7;
}

.statistics td {
    font-weight: 600;
    font-size: 15px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .bill-page {
        padding: 10px;
    }
    
    .author-input {
        width: 150px;
    }
    
    table {
        font-size: 12px;
    }
    
    th, td {
        padding: 10px 5px;
    }
    
    .billAddButton {
        width: 50px;
        height: 50px;
        font-size: 24px;
    }
}

@media (max-width: 480px) {
    .statistics {
        overflow-x: auto;
    }
}
</style>
