<template>
    <div id="app" class="infp_container">
        <header class="header">
            <h1>16种人格测试</h1>
            <p>回答以下问题，了解你的性格类型。</p>
        </header>

        <div class="test-container" v-if="currentQuestion < questions.length">
            <div class="question-card">
                <p class="question-text">{{ questions[currentQuestion].question }}</p>
                <div class="options">
                    <label v-for="(option, idx) in questions[currentQuestion].options" :key="idx"
                           @click="selectAnswer(option)" class="option-label">
                        <input type="radio"
                               :name="'question-' + currentQuestion"
                               :value="option"
                               v-model="answers[currentQuestion]"
                               class="radio-input"> <!-- Keep the radio input visible for accessibility -->
                        <span class="option-text">{{ option }}</span>
                    </label>
                </div>
                <div class="progress-bar" v-if="currentQuestion < questions.length - 1">
                    <div class="progress"
                         :style="{ width: (currentQuestion + 1) / questions.length * 100 + '%' }"></div>
                </div>
            </div>
            <button @click="previousQuestion" class="nav-btn" :disabled="currentQuestion === 0">上一题</button>
            <button @click="nextQuestion" class="nav-btn" :disabled="answers.filter(Boolean).length < 2">下一题</button>
        </div>

        <div v-if="currentQuestion >= questions.length" class="result-container">
            <h2>测试结果：</h2>
            <p>{{ result }}</p>
            <p>维度分析：</p>
            <ul class="dimension-list">
                <li>内向 vs 外向: {{ dimensions.I_E }}</li>
                <li>直觉 vs 感觉: {{ dimensions.N_S }}</li>
                <li>判断 vs 知觉: {{ dimensions.J_P }}</li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PersonalityTest',
    data() {
        return {
            questions: [
                {
                    "question": "你喜欢和很多人交往，参加聚会吗？",
                    "options": ["1. 完全不喜欢", "2. 偶尔喜欢", "3. 中立", "4. 喜欢", "5. 非常喜欢"],
                    "dimension": "E_I"
                },
                {
                    "question": "你通常更喜欢独自待在一个安静的地方，还是在人多热闹的环境中？",
                    "options": ["1. 更喜欢独自待在安静的地方", "2. 偶尔喜欢独自待在安静的地方", "3. 中立", "4. 偶尔喜欢热闹的环境", "5. 更喜欢热闹的环境"],
                    "dimension": "E_I"
                },
                {
                    "question": "你更倾向于通过实践来学习新事物，还是通过理论来理解它们？",
                    "options": ["1. 完全通过实践", "2. 更多通过实践", "3. 中立", "4. 更多通过理论", "5. 完全通过理论"],
                    "dimension": "S_N"
                },
                {
                    "question": "在做决策时，你更依赖自己直觉的判断，还是依据事实和细节？",
                    "options": ["1. 完全依赖事实和细节", "2. 更多依赖事实和细节", "3. 中立", "4. 更多依赖直觉", "5. 完全依赖直觉"],
                    "dimension": "S_N"
                },
                {
                    "question": "你通常会根据逻辑和分析来做决策，还是根据个人的感受和价值观？",
                    "options": ["1. 完全依赖逻辑和分析", "2. 更多依赖逻辑和分析", "3. 中立", "4. 更多依赖感受和价值观", "5. 完全依赖感受和价值观"],
                    "dimension": "T_F"
                },
                {
                    "question": "当你面临困境时，你会更多考虑如何避免伤害他人，还是如何做出最理智的决策？",
                    "options": ["1. 更多考虑理智的决策", "2. 偶尔考虑理智的决策", "3. 中立", "4. 更多考虑他人的感受", "5. 完全考虑他人的感受"],
                    "dimension": "T_F"
                },
                {
                    "question": "你是否喜欢计划并有条理地安排事情，还是更喜欢灵活应变，按兴趣来做？",
                    "options": ["1. 完全喜欢有条理的计划", "2. 更多喜欢有条理的计划", "3. 中立", "4. 更多喜欢灵活应变", "5. 完全喜欢灵活应变"],
                    "dimension": "J_P"
                },
                {
                    "question": "你喜欢处理已经确定的任务，还是喜欢面对新的挑战和变化？",
                    "options": ["1. 喜欢处理已确定的任务", "2. 更多喜欢已确定的任务", "3. 中立", "4. 更多喜欢新的挑战", "5. 喜欢新的挑战"],
                    "dimension": "J_P"
                },
                {
                    "question": "你更喜欢在做决定时有明确的结论，还是享受在选择中保持灵活？",
                    "options": ["1. 完全喜欢明确结论", "2. 更多喜欢明确结论", "3. 中立", "4. 更多喜欢灵活性", "5. 完全喜欢灵活性"],
                    "dimension": "J_P"
                },
                {
                    "question": "你更倾向于关注大局和可能性，还是关注眼前的实际细节？",
                    "options": ["1. 完全关注实际细节", "2. 更多关注实际细节", "3. 中立", "4. 更多关注大局", "5. 完全关注大局"],
                    "dimension": "S_N"
                },
                {
                    "question": "你在做决策时，通常会考虑到长期的影响，还是更注重短期的实际情况？",
                    "options": ["1. 完全注重短期情况", "2. 更多注重短期情况", "3. 中立", "4. 更多注重长期影响", "5. 完全注重长期影响"],
                    "dimension": "S_N"
                },
                {
                    "question": "你更喜欢在团队中担任领导角色，还是更倾向于独自完成工作？",
                    "options": ["1. 完全不喜欢领导", "2. 偶尔喜欢领导", "3. 中立", "4. 喜欢领导", "5. 非常喜欢领导"],
                    "dimension": "E_I"
                },
                {
                    "question": "你通常在社交活动中感到充满能量，还是感到精疲力尽？",
                    "options": ["1. 完全精疲力尽", "2. 偶尔精疲力尽", "3. 中立", "4. 偶尔充满能量", "5. 完全充满能量"],
                    "dimension": "E_I"
                },
                {
                    "question": "你是否喜欢预先做好详细的计划，并严格按照计划执行？",
                    "options": ["1. 完全不喜欢", "2. 偶尔喜欢", "3. 中立", "4. 喜欢", "5. 非常喜欢"],
                    "dimension": "J_P"
                },
                {
                    "question": "在面对复杂的任务时，你是否喜欢一步步来，还是更倾向于找到整体的框架和思路？",
                    "options": ["1. 完全喜欢一步步来", "2. 偶尔喜欢一步步来", "3. 中立", "4. 偶尔喜欢找到整体框架", "5. 完全喜欢找到整体框架"],
                    "dimension": "S_N"
                },
                {
                    "question": "你通常会在乎别人如何看待自己，还是更关心自己对事物的理解和判断？",
                    "options": ["1. 完全不在乎", "2. 偶尔在乎", "3. 中立", "4. 更多在乎自己理解", "5. 完全在乎自己理解"],
                    "dimension": "T_F"
                },
                {
                    "question": "你喜欢在讨论中直接表达自己的意见，还是更倾向于聆听他人的想法？",
                    "options": ["1. 完全喜欢聆听", "2. 偶尔喜欢聆听", "3. 中立", "4. 偶尔喜欢表达", "5. 完全喜欢表达"],
                    "dimension": "E_I"
                },
                {
                    "question": "你是否觉得自己有时太过于细心，甚至显得过于挑剔？",
                    "options": ["1. 完全不觉得", "2. 偶尔觉得", "3. 中立", "4. 经常觉得", "5. 总是觉得"],
                    "dimension": "S_N"
                },
                {
                    "question": "在处理日常事务时，你更倾向于严格按照规则办事，还是灵活变通，按需调整？",
                    "options": ["1. 完全按照规则办事", "2. 更多按照规则办事", "3. 中立", "4. 更多按需调整", "5. 完全按需调整"],
                    "dimension": "J_P"
                },
                {
                    "question": "你是否容易从过去的经历中吸取教训，还是倾向于尝试新方法而不拘泥于过去？",
                    "options": ["1. 完全倾向于尝试新方法", "2. 偶尔倾向于新方法", "3. 中立", "4. 偶尔倾向于从过去吸取经验", "5. 完全倾向于从过去吸取经验"],
                    "dimension": "S_N"
                },
                {
                    "question": "你喜欢做事的时候有一个明确的目标和路径吗？",
                    "options": ["1. 完全不喜欢", "2. 偶尔喜欢", "3. 中立", "4. 喜欢", "5. 非常喜欢"],
                    "dimension": "J_P"
                },
                {
                    "question": "你更喜欢通过实际体验来了解事物，还是通过理论和概念来理解它们？",
                    "options": ["1. 完全通过实际体验", "2. 更多通过实际体验", "3. 中立", "4. 更多通过理论", "5. 完全通过理论"],
                    "dimension": "S_N"
                },
                {
                    "question": "你是否觉得自己有时太过注重感情，而忽视了逻辑和事实？",
                    "options": ["1. 完全不觉得", "2. 偶尔觉得", "3. 中立", "4. 经常觉得", "5. 总是觉得"],
                    "dimension": "T_F"
                },
                {
                    "question": "你喜欢在活动中担任组织者的角色吗？",
                    "options": ["1. 完全不喜欢", "2. 偶尔喜欢", "3. 中立", "4. 喜欢", "5. 非常喜欢"],
                    "dimension": "E_I"
                },
                {
                    "question": "在解决问题时，你是否更倾向于依据事物的实际情况，而不是考虑理论上的可能性？",
                    "options": ["1. 完全倾向实际情况", "2. 更多倾向实际情况", "3. 中立", "4. 更多倾向理论", "5. 完全倾向理论"],
                    "dimension": "S_N"
                }
            ],
            answers: [], // 保存用户答案
            currentQuestion: 0, // 当前问题索引
            result: null, // 显示结果
            dimensions: { // 维度分析
                I_E: 0,
                N_S: 0,
                J_P: 0,
            },
        };
    },
    methods: {
        selectAnswer(option) {
            this.answers[this.currentQuestion] = option;
            this.nextQuestion();
        },
        nextQuestion() {
            if (this.currentQuestion < this.questions.length) {
                this.currentQuestion++;
            }
            if (this.currentQuestion === this.questions.length) {
                this.calculateResult();
            }
        },
        previousQuestion() {
            if (this.currentQuestion > 0) {
                this.currentQuestion--;
            }
        },
        calculateResult() {
            // 根据选择的答案计算结果
            this.answers.forEach((answer, index) => {
                const question = this.questions[index];
                if (question.dimension === 'E_I') {
                    this.dimensions.I_E += this.getScore(answer, 'E_I');
                } else if (question.dimension === 'N_S') {
                    this.dimensions.N_S += this.getScore(answer, 'N_S');
                } else if (question.dimension === 'T_F') {
                    this.dimensions.T_F += this.getScore(answer, 'T_F');
                } else if (question.dimension === 'J_P') {
                    this.dimensions.J_P += this.getScore(answer, 'J_P');
                }
            });

            this.result = this.determinePersonalityType();
        },
        determinePersonalityType() {
            const {I_E, N_S, T_F, J_P} = this.dimensions;
            const personalityType = [
                I_E > 0 ? 'I' : 'E',
                N_S > 0 ? 'N' : 'S',
                T_F > 0 ? 'T' : 'F',
                J_P > 0 ? 'J' : 'P'
            ].join('');
            return `你的性格类型是: ${personalityType}`;
        },
        getScore(answer, dimension) {
            const scoreMap = {
                E_I: {
                    "1. 完全不喜欢": 1,
                    "2. 偶尔喜欢": 2,
                    "3. 中立": 3,
                    "4. 喜欢": 4,
                    "5. 非常喜欢": 5
                },
                N_S: {
                    "1. 完全通过实践": 1,
                    "2. 更多通过实践": 2,
                    "3. 中立": 3,
                    "4. 更多通过理论": 4,
                    "5. 完全通过理论": 5
                },
                T_F: {
                    "1. 完全依赖逻辑": 1,
                    "2. 更多依赖逻辑": 2,
                    "3. 中立": 3,
                    "4. 更多依赖感受": 4,
                    "5. 完全依赖感受": 5
                },
                J_P: {
                    "1. 完全喜欢有条理": 1,
                    "2. 更多喜欢有条理": 2,
                    "3. 中立": 3,
                    "4. 更多喜欢灵活性": 4,
                    "5. 完全喜欢灵活性": 5
                }
            };

            return scoreMap[dimension][answer] || 0;
        }
    },
}
;
</script>

<style scoped>


.infp_container {
    max-width: 100%;
    margin: 0 auto;
    padding: 20px;
    background-color: #f8f8f8;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Header */
.header {
    text-align: center;
    margin-bottom: 30px;
}

.header h1 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 10px;
}

.header p {
    font-size: 1rem;
    color: #666;
}

/* Test container */
.test-container {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 100%;
    max-width: 600px;
    margin-bottom: 20px;
}

/* Question card */
.question-card {
    margin-bottom: 20px;
}

.question-text {
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 20px;
}

/* Options styling */
.options {
    display: flex;
    flex-direction: column;
}

.option-label {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 15px;
    transition: background-color 0.3s, transform 0.2s ease;
}

.option-label:hover {
    background-color: #f0f0f0;
    transform: translateX(5px);
}

.radio-input {
    margin-right: 10px;
    transform: scale(1.2);
}

.option-text {
    font-size: 1rem;
    color: #333;
}

/* Progress bar */
.progress-bar {
    width: 100%;
    height: 10px;
    background-color: #e0e0e0;
    border-radius: 5px;
    margin-top: 20px;
}

.progress {
    height: 100%;
    background-color: #4CAF50;
    border-radius: 5px;
    transition: width 0.5s ease-in-out;
}

/* Navigation buttons */
.nav-btn {
    background-color: #4CAF50;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s;
    margin-top: 20px;
}

.nav-btn:disabled {
    background-color: #b0b0b0;
    cursor: not-allowed;
}

.nav-btn:hover:not(:disabled) {
    background-color: #45a049;
}

/* Result container */
.result-container {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 100%;
    max-width: 600px;
    margin-top: 30px;
}

.result-container h2 {
    font-size: 1.8rem;
    color: #333;
    margin-bottom: 10px;
}

.result-container p {
    font-size: 1rem;
    color: #666;
    margin-bottom: 10px;
}

.dimension-list {
    list-style: none;
    margin: 0;
    padding-left: 0;
}

.dimension-list li {
    font-size: 1rem;
    color: #444;
    margin-bottom: 8px;
}

/* Media Queries for responsiveness */
@media screen and (max-width: 768px) {
    .header h1 {
        font-size: 2rem;
    }

    .question-text {
        font-size: 1rem;
    }

    .option-text {
        font-size: 0.9rem;
    }

    .nav-btn {
        font-size: 0.9rem;
    }

    .result-container h2 {
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 480px) {
    .infp_container {
        padding: 10px;
    }

    .header h1 {
        font-size: 1.6rem;
    }

    .question-text {
        font-size: 0.9rem;
    }

    .option-text {
        font-size: 0.8rem;
    }

    .nav-btn {
        width: 100%;
        font-size: 0.8rem;
    }
}

</style>
