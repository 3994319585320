import service from "@/api/service";
import axios from "axios";

export async function getSixtyCert(name, XLocation) {
    let res = await service({
        url: '/wmj/getSixtyCert',
        method: 'post',
        params: {name: name, XLocation: XLocation}
    })
    return res.data
}
export async function getESixtyCert(certVO) {
    let res = await service({
        url: '/wmj/getESixtyCert',
        method: 'post',
        data: certVO
    })
    return res.data
}
export async function getHundredCert(name, XLocation) {
    let res = await service({
        url: '/wmj/getHundredCert',
        method: 'post',
        params: {name: name, XLocation: XLocation}
    })
    return res.data
}
export async function getEHundredCert(certVO) {
    let res = await service({
        url: '/wmj/getEHundredCert',
        method: 'post',
        data: certVO
    })
    return res.data
}

export async function getHundredFiftyCert(name, XLocation) {
    let res = await service({
        url: '/wmj/getHundredFiftyCert',
        method: 'post',
        params: {name: name, XLocation: XLocation}
    })
    return res.data
}
export async function getEHundredFiftyCert(certVO) {
    let res = await service({
        url: '/wmj/getEHundredFiftyCert',
        method: 'post',
        data: certVO
    })
    return res.data
}

export async function uploadPic(file) {
    let formData = new FormData();
    formData.append('file', file);
    let res = await service({
        url: '/wmj/uploadPic',
        method: 'post',
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    })
    return res.data
}
export async function edit100PicSize(file) {
    let formData = new FormData();
    formData.append('file', file);
    let res = await service({
        url: '/wmj/edit100PicSize',
        method: 'post',
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    })
    return res.data
}

export async function edit150PicSize(file) {
    let formData = new FormData();
    formData.append('file', file);
    let res = await service({
        url: '/wmj/edit150PicSize',
        method: 'post',
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
    return res.data
}

export async function getCookie() {
    let res = await service({
        url: '/wmj/getCookie',
        method: 'post',
    })
    return res.data
}

export async function getCaptchaImage(cookie) {
    let res = await service({
        url: '/wmj/getCaptchaImage',
        method: 'post',
        params: {cookie: cookie}
    })
    return res.data
}

export async function queryCert(certQueryVO) {
    let res = await service({
        url: '/wmj/queryCert',
        method: 'post',
        data: certQueryVO
    })
    return res.data
}