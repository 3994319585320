<template>
    <div>
        <back></back>
        <div class="introductory-container">
            <div class="introductory-type">
                <div class="type-btn"
                     v-for="type in types"
                     :key="type"
                     :class="{ active: selectedType === type }"
                     @click="filterCards(type)">
                    {{ type }}
                </div>
            </div>
            <div class="totalCard">
                <div class="card" v-for="item in filteredCards" :key="item.name">
                    <div class="card-frame">
                        <div class="magic-circle"></div>
                        <div class="frame-header">
                            <div class="name">{{ item.name }}</div>
                            <div class="star-rating">
                                <span class="star" v-for="n in item.cost" :key="n">★</span>
                            </div>
                        </div>

                        <div class="card-image">
                            <div class="image-inner">
                                <div class="magic-runes top"></div>
                                <div class="magic-runes bottom"></div>
                                <div class="card-ornament top-left"></div>
                                <div class="card-ornament top-right"></div>
                                <div class="card-ornament bottom-left"></div>
                                <div class="card-ornament bottom-right"></div>
                                <div class="floating-crystal">{{ item.name }}</div>
                            </div>
                        </div>

                        <div class="frame-type-line">
                            <div class="type-ornament left"></div>
                            <span>{{ item.type }}</span>
                            <div class="type-ornament right"></div>
                        </div>

                        <div class="frame-text-box">
                            <div class="scroll-ornament top"></div>
                            <div class="description-wrapper">
                                <p class="description">{{ item.describe }}</p>
                            </div>
                            <div class="scroll-ornament bottom"></div>
                            <div class="magical-sparkles"></div>
                        </div>

                        <div class="frame-bottom-info">
                            <div class="corner-ornament left"></div>
                            <div class="rarity-indicator">
                                <div class="inner-glow"></div>
                            </div>
                            <div class="corner-ornament right"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Back from "@/components/back.vue";

export default {
    name: "Introductory",
    components: {Back},
    data() {
        return {
            selectedType: '全部',
            types: ['全部', '武器', '技能', '人物'],
            card: [
                {name: 'A', describe: 'a', cost: 1, type: '武器'},
                {name: 'B', describe: 'b', cost: 2, type: '武器'},
                {name: 'C', describe: 'c', cost: 3, type: '技能'},
                {name: 'D', describe: 'd', cost: 4, type: '技能'},
                {name: 'E', describe: 'e', cost: 5, type: '人物'},
                {name: 'F', describe: 'f', cost: 6, type: '人物'},
                {name: 'G', describe: 'g', cost: 1, type: '武器'},
                {name: 'H', describe: 'h', cost: 2, type: '技能'},
                {name: 'I', describe: 'i', cost: 1, type: '人物'},
                {name: 'J', describe: 'j', cost: 3, type: '武器'},
                {name: 'K', describe: 'k', cost: 2, type: '技能'},
                {name: 'L', describe: 'l', cost: 2, type: '人物'},
                {name: 'M', describe: 'm', cost: 3, type: '武器'},
                {name: 'N', describe: 'n', cost: 4, type: '技能'},
                {name: 'O', describe: 'o', cost: 5, type: '人物'},
                {name: 'P', describe: 'p', cost: 2, type: '武器'},
                {name: 'Q', describe: 'q', cost: 1, type: '技能'},
                {name: 'R', describe: 'r', cost: 1, type: '人物'},
                {name: 'S', describe: 's', cost: 1, type: '武器'},
                {name: 'T', describe: 't', cost: 5, type: '技能'},
                {name: 'U', describe: 'u', cost: 4, type: '人物'},
                {name: 'V', describe: 'v', cost: 2, type: '武器'},
                {name: 'W', describe: 'w', cost: 3, type: '技能'},
                {name: 'X', describe: 'x', cost: 1, type: '人物'},
                {name: 'Y', describe: 'y', cost: 5, type: '武器'},
                {name: 'Z', describe: 'z', cost: 2, type: '技能'}
            ]
        }
    },
    computed: {
        filteredCards() {
            if (this.selectedType === '全部') {
                return this.card;
            }
            return this.card.filter(item => item.type === this.selectedType);
        }
    },
    methods: {
        filterCards(type) {
            this.selectedType = type;
        }
    }
}
</script>

<style scoped>
.introductory-container {
    margin-top: 40px;
    min-height: 100vh;
    background: linear-gradient(135deg, #0f0f1e 0%, #1a1a3e 100%);
    padding: 20px;
    position: relative;
    overflow: hidden;
}

.introductory-container::before {
    content: '';
    position: absolute;
    width: 200%;
    height: 200%;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100'%3E%3Ccircle cx='50' cy='50' r='2' fill='%23ffffff10'/%3E%3C/svg%3E");
    animation: backgroundFloat 60s linear infinite;
    opacity: 0.3;
}

@keyframes backgroundFloat {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

.introductory-type {
    display: flex;
    gap: 15px;
    margin-bottom: 30px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

.type-btn {
    background: rgba(255, 255, 255, 0.1);
    padding: 12px 24px;
    border-radius: 25px;
    color: #fff;
    cursor: pointer;
    transition: all 0.4s ease;
    border: 1px solid rgba(255, 255, 255, 0.2);
    flex: 1;
    text-align: center;
    position: relative;
    overflow: hidden;
    font-size: 1.1em;
}

.type-btn::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle, rgba(255, 255, 255, 0.2) 0%, transparent 70%);
    transform: scale(0);
    transition: transform 0.6s ease;
}

.type-btn:hover::before {
    transform: scale(1);
}

.type-btn:hover {
    background: rgba(255, 255, 255, 0.2);
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(255, 255, 255, 0.2);
}

.type-btn.active {
    background: linear-gradient(45deg, #7400b8, #5e60ce);
    box-shadow: 0 4px 15px rgba(116, 0, 184, 0.4);
    border: none;
}

.totalCard {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 35px;
    padding: 20px;
    max-width: 1400px;
    margin: 0 auto;
}

.card {
    perspective: 1500px;
    transition: transform 0.4s ease;
}

.card:hover {
    transform: translateY(-15px) scale(1.03);
    z-index: 1;
}

.card-frame {
    position: relative;
    height: 500px;
    border-radius: 20px;
    background: linear-gradient(145deg, #2a1b3d 0%, #1a1a2e 100%);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.7),
    inset 0 0 20px rgba(147, 51, 234, 0.3);
    overflow: hidden;
    transition: all 0.4s ease;
    border: 2px solid transparent;
    background-clip: padding-box;
}

.magic-circle {
    position: absolute;
    width: 300%;
    height: 300%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3E%3Ccircle cx='50' cy='50' r='45' fill='none' stroke='%23ffffff10' stroke-width='0.5'/%3E%3Cpath d='M50,5 L50,95 M5,50 L95,50' stroke='%23ffffff10' stroke-width='0.5'/%3E%3C/svg%3E") center/contain;
    animation: rotateCircle 20s linear infinite;
    opacity: 0.1;
    pointer-events: none;
}

@keyframes rotateCircle {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

.frame-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background: linear-gradient(to right, #4a1c7c, #7209b7);
    color: white;
    border-bottom: 3px solid rgba(147, 51, 234, 0.3);
    position: relative;
}

.star-rating {
    margin-top: 8px;
}

.star {
    color: #ffd700;
    text-shadow: 0 0 8px #ff4d00;
    font-size: 1.3em;
    margin: 0 3px;
    animation: starPulse 2s infinite alternate;
}

@keyframes starPulse {
    from {
        transform: scale(1);
        text-shadow: 0 0 8px #ff4d00;
    }
    to {
        transform: scale(1.2);
        text-shadow: 0 0 15px #ff4d00, 0 0 30px #ff4d00;
    }
}

.name {
    font-weight: bold;
    font-size: 1.8em;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5),
    0 0 10px rgba(147, 51, 234, 0.8);
    letter-spacing: 3px;
}

.card-image {
    height: 260px;
    background: linear-gradient(45deg, #1a1a2e, #4a1c7c);
    padding: 15px;
    position: relative;
    overflow: hidden;
}

.magic-runes {
    position: absolute;
    width: 100%;
    height: 20px;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 20'%3E%3Ctext x='10' y='15' fill='%23ffffff20' font-family='Arial'>✧❈❋✺✹</text%3E%3C/svg%3E") repeat-x;
    opacity: 0.3;
}

.magic-runes.top {
    top: 10px;
}

.magic-runes.bottom {
    bottom: 10px;
    transform: rotate(180deg);
}

.floating-crystal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2.5em;
    color: white;
    text-shadow: 0 0 10px #7400b8;
    animation: crystalFloat 3s ease-in-out infinite;
}

@keyframes crystalFloat {
    0%, 100% {
        transform: translate(-50%, -50%) scale(1);
    }
    50% {
        transform: translate(-50%, -60%) scale(1.1);
    }
}

.frame-type-line {
    height: 40px;
    background: linear-gradient(to right, #4a1c7c, #7209b7);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
    position: relative;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 1.2em;
}

.frame-text-box {
    padding: 25px;
    color: #fff;
    font-size: 1.1em;
    background: rgba(26, 26, 46, 0.9);
    height: 140px;
    position: relative;
    overflow: hidden;
}

.description-wrapper {
    height: 100%;
    overflow-y: auto;
    padding-right: 10px;
}

.description-wrapper::-webkit-scrollbar {
    width: 5px;
}

.description-wrapper::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
}

.description-wrapper::-webkit-scrollbar-thumb {
    background: rgba(147, 51, 234, 0.5);
    border-radius: 5px;
}

.magical-sparkles {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'%3E%3Ccircle cx='5' cy='5' r='1' fill='%23ffffff10'/%3E%3C/svg%3E");
    animation: sparkleFloat 20s linear infinite;
    pointer-events: none;
}

@keyframes sparkleFloat {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-100%);
    }
}

.frame-bottom-info {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 45px;
    background: linear-gradient(to right, #4a1c7c, #7209b7);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.rarity-indicator {
    width: 35px;
    height: 35px;
    background: radial-gradient(circle at center, #fff, #7400b8);
    border-radius: 50%;
    position: relative;
    box-shadow: 0 0 20px rgba(147, 51, 234, 0.8);
}

.inner-glow {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25px;
    height: 25px;
    background: white;
    border-radius: 50%;
    animation: magicPulse 2s infinite;
}

@keyframes magicPulse {
    0% {
        opacity: 0.5;
        transform: translate(-50%, -50%) scale(0.8);
        filter: hue-rotate(0deg);
    }
    50% {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1.2);
        filter: hue-rotate(180deg);
    }
    100% {
        opacity: 0.5;
        transform: translate(-50%, -50%) scale(0.8);
        filter: hue-rotate(360deg);
    }
}
</style>