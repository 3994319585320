<template>
    <div>
        <back></back>
        <div class="title">{{ title }}</div>
        <div class="characterList">
            <div v-for="row in chessPieces" :key="row.position" class="characterList-info">
                <!-- 使用 v-bind 动态绑定边框颜色 -->
                <img :src="row.imgUrl" :style="{ borderColor: getBorderColor(row.position) }" alt="Character" class="characterList-image">
                <div class="characterList-equipment">
                    <img v-if="row.equipmentOne" :src="row.equipmentOne" alt=""
                         class="characterList-equipment-image">
                    <img v-if="row.equipmentTwo" :src="row.equipmentTwo" alt=""
                         class="characterList-equipment-image">
                    <img v-if="row.equipmentThree" :src="row.equipmentThree" alt="e"
                         class="characterList-equipment-image">
                </div>
                <div class="characterName">{{row.characterName}}</div>
            </div>
        </div>
        <div class="chessboard">
            <div v-for="row in 4" :key="row" class="row" :class="{ 'offset': row % 2 === 0 }">
                <div v-for="col in 7" :key="col" class="hexagon"
                     :style="{ backgroundColor: getBorderColor(row * 10 + col) }">
                    <img :src="getImageUrl(row * 10 + col)" alt="">
                </div>
            </div>
            <div style="position: absolute;padding-top: 1vh">
                <div v-for="row in 4" :key="row" class="row" :class="{ 'offset': row % 2 === 0 }">
                    <div v-for="col in 7" :key="col" class="hexagon3">
                        <div class="equipment_div">
                            <img :src="getEquipmentUrl(row * 10 + col)" alt="">
                            <img :src="getEquipment2Url(row * 10 + col)" alt="">
                            <img :src="getEquipment3Url(row * 10 + col)" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {getLineupInfo} from "@/api/tftApi";
import Back from "@/components/back.vue";
import {log} from "@/utils/logHelper.js";

export default {
    name: "newTft",
    components: {Back},
    data() {
        return {
            title: '',
            chessPieces: []
        }
    },
    methods: {
        getImageUrl(position) {
            const piece = this.chessPieces.find(piece => piece.position === position);
            return piece ? (piece.imgUrl ? piece.imgUrl : 'https://www.yunox.cn/image/blank.png') : 'https://www.yunox.cn/image/black.png';
        },
        getEquipmentUrl(position) {
            const piece = this.chessPieces.find(piece => piece.position === position);
            return piece ? piece.equipmentOne : '';
        },
        getEquipment2Url(position) {
            const piece = this.chessPieces.find(piece => piece.position === position);
            return piece ? piece.equipmentTwo : '';
        },
        getEquipment3Url(position) {
            const piece = this.chessPieces.find(piece => piece.position === position);
            return piece ? piece.equipmentThree : '';
        },
        getBorderColor(position) {
            const piece = this.chessPieces.find(piece => piece.position === position);
            if (piece) {
                switch (piece.cost) {
                    case 1:
                        return 'rgba(117, 117, 117, 0.35)';
                    case 2:
                        return 'rgb(83,194,76)';
                    case 3:
                        return 'rgb(16,149,220)';
                    case 4:
                        return 'rgb(176,6,168)';
                    case 5:
                        return 'rgb(248,198,73)';
                    default:
                        return 'black';
                }
            } else {
                return 'black';
            }
        },
        getLineUpInfo(id) {
            getLineupInfo(id).then(res => {
                this.title = res.formationName
                this.chessPieces = res.characterInfoList
            })
        }
    },
    mounted() {
        const id = this.$route.query.id; // 获取查询参数中的 id
        if (id) {
            this.getLineUpInfo(id);
        }
    }
}
</script>

<style scoped>
.title {
    font-weight: bold;
    color: black;
    font-size: 20px;
}

.chessboard {
    /*max-width: 450px;*/
    /*padding: 15px 15px 15px 10px;*/
    /*border: 2px solid rgba(227, 225, 225, 0.53);*/
    border-radius: 8px;
    /*box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);*/
    display: flex;
    flex-direction: column;
}

.row {
    display: flex;
}

.row.offset {
    margin-left: 3vh; /* 每一行错开的距离 */
}

.hexagon {
    position: relative;
    width: 5.5vh;
    height: 6vh;
    margin-left: 6px;
    background-color: red; /* 将背景颜色设置为红色 */
    padding: 2px; /* 为六边形添加1px的内边距 */
    box-sizing: border-box; /* 确保padding不会增加元素的总宽度和高度 */
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}

.hexagon3 {
    position: relative;
    width: 5.5vh;
    height: 6vh;
    margin-left: 6px;
}

.hexagon :hover {
    cursor: pointer;
    scale: 1.1;
}

.hexagon img {
    max-height: 98%;
    object-fit: contain;
}

.hexagon3 img {
    height: 1.2vh;
}

.equipment_div {
    gap: 2px;
    margin-top: 68%;
    display: flex;
    position: absolute;
    text-align: center;
    margin-left: 0.5vh;
}

.characterList {
    margin-top: 20px;
    border-radius: 8px;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    max-width: 100%; /* 确保图片容器宽度 */
}

.characterList-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1px;
}

.characterList-image {
    width: 4.5vh;
    height: auto;
    border: 2px solid transparent; /* 设置透明边框，实际颜色将通过 JS 动态设置 */
}

.characterList-equipment {
    margin-top: -8px;
    min-height: 12px;
    display: flex;
    justify-content: center;
}

.characterList-equipment img {
    width: 12px;
    height: 12px;
    margin: 0 0.5px;
}
.characterName{
    font-size: 10px;
    color: #504f4f;
    font-weight: bolder;
}
</style>