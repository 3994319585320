<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<script>
export default {
    name: 'App',
}
</script>

<style>
/* 确保 html 和 body 高度为 100% */
html, body {
    height: 100%;
    margin: 0; /* 去除默认的外边距 */
}

/* 设置 #app 高度和宽度都为 100% */
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    /*height: 100vh;*/
    /*width: 99vw;*/
}
</style>
