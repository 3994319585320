<template>
    <div class="container">
        <back></back>
        <h2 class="title">在线生成表格</h2>
        <!-- 表格展示 -->
        <div class="table-container">
            <table>
                <thead>
                <tr>
                    <!-- 列表头 -->
                    <th v-for="(col, colIndex) in columns" :key="'header-' + colIndex">
                        <textarea
                                v-model="columns[colIndex]"
                                :placeholder="'输入列名 ' + (colIndex + 1)"
                                class="column-input"
                        />
                        <!-- 列操作按钮容器 -->
                        <div class="column-buttons">
                            <!-- 插入列按钮 -->
                            <button @click="insertColumn(colIndex)" class="column-insert-button">插入列</button>
                            <!-- 删除列按钮 -->
                            <button @click="removeColumn(colIndex)" class="column-remove-button">删除列</button>
                        </div>
                    </th>
                    <th>
                        <button @click="addColumn" class="action-button">添加列</button>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(row, rowIndex) in tableData" :key="'row-' + rowIndex">
                    <!-- 表格每一行 -->
                    <td v-for="(cell, cellIndex) in row" :key="'cell-' + cellIndex">
                        <textarea
                                v-model="tableData[rowIndex][cellIndex]"
                                placeholder="输入内容"
                                class="cell-input"
                        />
                    </td>
                    <td>
                        <button @click="removeRow(rowIndex)" class="action-button" style="background-color: #f44336">
                            删除行
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>

            <div class="actions">
                <button @click="addRow" class="action-button" style="width: 300px;font-size: 30px;padding: 5px">再加一行</button>
            </div>
        </div>


        <!-- 实时预览区域 -->
        <div class="preview-container">
            <h3>实时预览</h3>
            <div class="preview-row header">
                <div class="preview-cell" v-for="(col, colIndex) in columns" :key="'preview-header-' + colIndex">
                    {{ col || '' }}
                </div>
            </div>
            <div v-for="(row, rowIndex) in tableData" :key="'preview-row-' + rowIndex" class="preview-row">
                <div class="preview-cell" v-for="(cell, cellIndex) in row" :key="'preview-cell-' + cellIndex" v-html="formatCell(cell)">
                </div>
            </div>
        </div>

        <div class="actions">
            <button @click="exportToExcel" class="action-button" style="background-color: #0a0916;margin-top: 40px">导出 Excel
            </button>
        </div>
    </div>
</template>

<script>
import * as XLSX from 'xlsx';
import Back from "@/components/back.vue";

export default {
    components: {Back},
    data() {
        return {
            columns: ['',''], // 初始列名
            tableData: [
                ['','']
            ] // 初始数据
        };
    },
    methods: {
        formatCell(cell) {
            // 将换行符转化为 <br> 标签
            return cell ? cell.replace(/\n/g, '<br>') : '';
        },
        // 添加一行
        addRow() {
            this.tableData.push(this.columns.map(() => '')); // 新行，空白单元格
        },

        // 删除一行
        removeRow(rowIndex) {
            this.tableData.splice(rowIndex, 1);
        },

        // 添加一列（添加到最后）
        addColumn() {
            this.columns.push(''); // 添加新列
            this.tableData.forEach(row => row.push('')); // 所有行添加新列
        },

        // 插入一列（在指定列后插入）
        insertColumn(colIndex) {
            this.columns.splice(colIndex + 1, 0, ''); // 在指定位置后插入空白列标题
            this.tableData.forEach(row => row.splice(colIndex + 1, 0, '')); // 在所有行指定位置后插入空白数据
        },

        // 删除一列
        removeColumn(colIndex) {
            // 删除列标题
            this.columns.splice(colIndex, 1);
            // 删除所有行中该列的数据
            this.tableData.forEach(row => row.splice(colIndex, 1));
        },

        // 导出为 Excel 文件
        exportToExcel() {
            const ws_data = [this.columns, ...this.tableData]; // 将列标题和数据结合成一个二维数组

            // 处理换行符
            const processedData = ws_data.map(row => row.map(cell => {
                if (typeof cell === 'string') {
                    // 替换换行符
                    return cell.replace(/\n/g, '\r\n');
                }
                return cell;
            }));

            const ws = XLSX.utils.aoa_to_sheet(processedData); // 将数据转换为工作表
            const wb = XLSX.utils.book_new(); // 创建一个工作簿
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1'); // 将工作表添加到工作簿
            XLSX.writeFile(wb, '表格.xlsx'); // 导出 Excel 文件
        },
        setSeo() {
            // 设置页面标题
            document.title = "在线生成表格 - 简便易用的表格编辑器";

            // 设置页面描述
            document
                .querySelector('meta[name="description"]')
                .setAttribute("content", "通过此工具在线创建和编辑表格，支持动态添加行列，导出 Excel 文件，适用于各种数据处理需求，方便快捷的表格编辑体验。");

            // 设置页面关键词
            document
                .querySelector('meta[name="keywords"]')
                .setAttribute("content", "在线表格生成, 表格编辑器, 数据处理, Excel 导出, 在线工具, 表格工具, 数据编辑, 自定义表格");

            // 设置页面作者
            document
                .querySelector('meta[name="author"]')
                .setAttribute("content", "yunox");

            // 设置页面版权
            document
                .querySelector('meta[name="copyright"]')
                .setAttribute("content", "yunox");

            // 设置页面的字符集
            document
                .querySelector('meta[charset]')
                .setAttribute("charset", "UTF-8");

            // 设置社交媒体分享信息（Open Graph）
            document
                .querySelector('meta[property="og:title"]')
                .setAttribute("content", "表格在线导出工具");

            document
                .querySelector('meta[property="og:description"]')
                .setAttribute("content", "通过此工具，轻松在线创建和编辑表格，支持导出 Excel 文件，适合各类数据处理需求。");

            document
                .querySelector('meta[property="og:image"]')
                .setAttribute("content", "https://www.yunox.cn/images/preview.jpg"); // 这里填写你页面的预览图链接

            document
                .querySelector('meta[property="og:url"]')
                .setAttribute("content", window.location.href); // 动态获取当前页面 URL

            // 设置 Twitter 卡片（可选，提升 Twitter 分享体验）
            document
                .querySelector('meta[name="twitter:card"]')
                .setAttribute("content", "summary_large_image");

            document
                .querySelector('meta[name="twitter:title"]')
                .setAttribute("content", "表格在线导出工具");

            document
                .querySelector('meta[name="twitter:description"]')
                .setAttribute("content", "通过此工具，轻松在线创建和编辑表格，支持导出 Excel 文件，适合各类数据处理需求。");

            document
                .querySelector('meta[name="twitter:image"]')
                .setAttribute("content", "https://www.yunox.cn/image/excel.png"); // 这里填写你页面的预览图链接

            // 设置页面的语言（可选，适用于多语言站点）
            document
                .querySelector('meta[name="language"]')
                .setAttribute("content", "zh-CN"); // 简体中文

            // 设置网站的图标（可选）
            document
                .querySelector('link[rel="icon"]')
                .setAttribute("href", "https://www.yunox.cn/icon/favicon.ico"); // 这里填写你站点的图标链接
        }
    },mounted() {
        this.setSeo()
    }
};
</script>

<style scoped>
/* 基本容器样式 */
.container {
    font-family: 'Arial', sans-serif;
    padding: 20px;
    margin: 0 auto;
}

/* 标题 */
.title {
    text-align: center;
    font-size: 28px; /* 增加标题字体大小 */
    color: #333;
    margin-bottom: 20px;
}

/* 表格容器 */
.table-container {
    width: 100%;
    overflow-x: auto;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* 增加阴影效果 */
    padding: 10px;
    background-color: #fff;
}

/* 表格样式 */
table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 10px;
}

/* th 和 td 样式，支持文字换行 */
th, td {
    padding: 5px;
    text-align: center;
    border: 1px solid #ddd;
    background-color: #fafafa;
    word-wrap: break-word; /* 允许文字换行 */
}

th {
    background-color: #f2f2f2;
    color: #555;
}

/* 最后一列按钮列窄一点 */
.table-container td:last-child {
    width: 100px; /* 限制最后一列的宽度 */
    text-align: center; /* 使按钮居中 */
}

/* 输入框样式 */
textarea, .column-input {
    width: 95%; /* 增加输入框宽度 */
    padding: 8px; /* 增加内边距 */
    height: 40px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 14px;
    transition: border-color 0.3s ease;
    resize: none; /* 禁用 textarea 的大小调整 */
}

textarea:focus, .column-input:focus {
    border-color: #4CAF50;
    outline: none;
}

/* 列操作按钮容器 */
.column-buttons {
    display: flex;
    justify-content: center; /* 左右居中 */
    gap: 12px; /* 按钮之间的间距 */
    margin-top: 10px;
}

/* 删除列按钮样式 */
button.column-remove-button {
    padding: 8px 16px; /* 增加内边距 */
    background-color: #f44336; /* 红色 */
    color: white;
    border: none;
    border-radius: 10px; /* 更圆的圆角，提升现代感 */
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* 添加阴影效果 */
}

button.column-remove-button:hover {
    background-color: #d32f2f; /* 红色 hover 效果 */
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

button.column-remove-button:active {
    background-color: #f44336;
    transform: translateY(0);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* 插入列按钮样式 */
button.column-insert-button {
    padding: 8px 16px; /* 增加内边距 */
    background-color: #35b461; /* 蓝色 */
    color: white;
    border: none;
    border-radius: 10px; /* 更圆的圆角，提升现代感 */
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* 添加阴影效果 */
}

button.column-insert-button:hover {
    background-color: #2a9e4f; /* 绿色 hover 效果 */
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

button.column-insert-button:active {
    background-color: #35b461;
    transform: translateY(0);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* 添加行和导出按钮样式 */
button.action-button {
    padding: 12px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 10px;
    font-size: 16px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
}

button.action-button:hover {
    background-color: #2ab40b;
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

button.action-button:active {
    background-color: #2ab40b;
    transform: translateY(0);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* 按钮容器样式 */
.actions {
    text-align: center;
    margin-top: 20px;
    display: flex;
    flex-direction: column; /* 让按钮上下排列 */
    align-items: center; /* 水平居中 */
}
/* 修改预览容器的表格布局 */
.preview-container {
    text-align: left;
    font-family: 'Arial', sans-serif;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    overflow-x: auto; /* 允许横向滚动 */
}

.preview-row {
    min-height: 30px;
    height: auto; /* 允许自动调整高度 */
    display: table-row; /* 变成表格行 */
    border-bottom: 1px solid #ddd;
}

.preview-cell {
    height: 30px;

    display: table-cell; /* 变成表格单元格 */
    padding: 8px 12px;
    border-bottom: 1px solid rgba(134, 134, 134, 0.5);
    border-right: 1px solid rgba(134, 134, 134, 0.5);
    font-size: 14px;
    color: #333;
    text-align: center;
    min-width: 120px; /* 设置最小宽度，防止过窄 */
    word-wrap: break-word;  /* 自动换行 */
    box-sizing: border-box;
    width: auto;  /* 自动调整宽度 */
    white-space: pre-wrap; /* 允许换行符换行 */
    word-break: break-word; /* 长单词折行 */
}

/* 标题行 */
.preview-row.header .preview-cell {
    font-weight: bold;
    background-color: #f4f4f4;
    color: #000;
    border-top: 2px solid #aaa;
}



/* 去掉第一列的左边框 */
.preview-cell:first-child {
    border-left: 1px solid rgba(134, 134, 134, 0.5);
}

/* 鼠标悬停时的行背景色 */
.preview-row:hover {
    background-color: #f9f9f9;
}

/* 空单元格的文字颜色 */
.preview-cell.empty {
    color: #888;
}

/* 偶数列背景颜色 */
.preview-cell:nth-child(even) {
    background-color: #f9f9f9;
}

/* 奇数列背景颜色 */
.preview-cell:nth-child(odd) {
    background-color: #fff;
}

/* 标题行的奇数列背景颜色 */
.preview-row.header .preview-cell:nth-child(odd) {
    background-color: #e9e9e9;
}


</style>
