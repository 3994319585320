import Vue from 'vue';
import VueRouter from 'vue-router';

// 导入各个组件
import tft from "@/views/tft.vue";
import lineupList from "@/views/LineUpList.vue";
import createLineup from "@/views/createLineup.vue";
import home from "@/views/home.vue";
import homePage from "@/views/homePage.vue";
import answerBook from "@/views/answerBook.vue";
import question from "@/views/ManyWhy.vue";
import constellation from "@/views/Constellation.vue";
import whatToEat from "@/views/whatToEat.vue";
import wechatWallet from "@/views/wechatWallet.vue";
import criminalLaw from "@/views/law.vue";
import gossip from "@/views/gossip.vue";
import animatedCharacters from "@/views/animatedCharacters.vue";
import notes from "@/views/notes.vue";
import postal from "@/views/postal.vue";
import admin from "@/views/admin.vue";
import BackOffice from "@/views/BackOffice.vue";
import calculator from "@/views/calculator.vue";
import todoList from "@/views/todoList.vue";
import familyMotto from "@/views/familyMotto.vue";
import bill from "@/views/bill.vue";
import copy from "@/views/copy.vue";
import newTft from "@/views/tft.vue";
import introductory from "@/views/Introductory.vue";
import word from "@/views/word.vue";
import game from "@/views/game.vue";
import slotMachine from "@/views/slotMachine.vue";
import log from "@/views/log.vue";
import QRCode from "@/views/QRCode.vue";
import scanUpload from "@/views/scanUpload.vue";
import memorandum from "@/views/memorandum.vue";
import video from "@/views/video.vue";
import dayDream from "@/views/dayDream.vue";
import timeLessons from "@/views/timeLessons.vue";
import personalPage from "@/views/personalPage.vue";
import period from "@/views/period.vue";
import websocket from "@/views/websocketTest.vue";
import tools from "@/views/tools.vue";
import deviceInfo from "@/views/deviceInfo.vue";
import infp from "@/views/infp.vue";
import map from "@/views/map.vue";
import lunar from "@/views/lunar.vue";
import scrapped from "@/views/scrapped.vue";
import temp from "@/views/temp.vue";
import generateCert from "@/views/generateCert.vue";
import color from "@/views/color.vue";
import certInquire from "@/views/certInquire.vue";
import errLog from "@/views/errLog.vue";
import opinion from "@/views/opinion.vue";
import xlsx from "@/views/xlsx.vue";
import textShare from "@/views/textShare.vue";
import foldersIndex from "@/views/foldersIndex.vue";
import updateLog from "@/views/log.vue";
import task from "@/views/task.vue";
// import wiki from "@/views/wiki.vue";
import notFound from "@/views/notFound.vue";
import simpleGame from "@/views/simpleGame.vue";

Vue.use(VueRouter);

const routes = [
    { path: '/', name: 'home', component: home },
    { path: '/lineup', name: 'lineup', component: lineupList },
    { path: '/tft', name: 'tft', component: tft },
    { path: '/add', name: 'createLineup', component: createLineup },
    { path: '/answer', name: 'answerBook', component: answerBook },
    { path: '/question', name: 'question', component: question },
    { path: '/constellation', name: 'constellation', component: constellation },
    { path: '/eat', name: 'whatToEat', component: whatToEat },
    { path: '/wechat', name: 'wechatWallet', component: wechatWallet },
    { path: '/law', name: 'criminalLaw', component: criminalLaw },
    { path: '/gossip', name: 'gossip', component: gossip },
    { path: '/characters', name: 'ultraman', component: animatedCharacters },
    { path: '/notes', name: 'note', component: notes },
    { path: '/postal', name: 'postal', component: postal },
    { path: '/admin', name: 'admin', component: admin },
    { path: '/backOffice', name: 'backOffice', component: BackOffice },
    { path: '/calculator', name: 'calculator', component: calculator },
    { path: '/todoList', name: 'todoList', component: todoList },
    { path: '/familyMotto', name: 'familyMotto', component: familyMotto },
    { path: '/bill', name: 'bill', component: bill },
    { path: '/copy', name: 'copy', component: copy },
    { path: '/newTft', name: 'newTft', component: newTft },
    { path: '/introductory', name: 'introductory', component: introductory },
    { path: '/word', name: 'new', component: word },
    { path: '/game', name: 'game', component: game },
    { path: '/machine', name: 'machine', component: slotMachine },
    { path: '/log', name: 'log', component: log },
    { path: '/QRCode', name: 'QRCode', component: QRCode },
    { path: '/scanUpload', name: 'scanUpload', component: scanUpload },
    { path: '/homePage', name: 'homePage', component: homePage },
    { path: '/memorandum', name: 'memorandum', component: memorandum },
    { path: '/video', name: 'video', component: video },
    { path: '/dayDream', name: 'dayDream', component: dayDream },
    { path: '/timeLessons', name: 'timeLessons', component: timeLessons },
    { path: '/personalPage', name: 'personalPage', component: personalPage },
    { path: '/simpleGame', name: 'simpleGame', component: simpleGame },
    { path: '/period', name: 'period', component: period },
    { path: '/websocket', name: 'websocket', component: websocket },
    { path: '/tools', name: 'tools', component: tools },
    { path: '/deviceInfo', name: 'deviceInfo', component: deviceInfo },
    { path: '/infp', name: 'infp', component: infp },
    { path: '/map', name: 'map', component: map },
    { path: '/lunar', name: 'lunar', component: lunar },
    { path: '/scrapped', name: 'scrapped', component: scrapped },
    // {path: '/wiki1', name: 'wiki', component: wiki},
    {path: '/404', name: 'notFound', component: notFound},
    {path: '/temp', name: 'wheel', component: temp},
    {path: '/generateCert', name: 'generateCert', component: generateCert},
    {path: '/color', name: 'color', component: color},
    {path: '/certInquire', name: 'certInquire', component: certInquire},
    {path: '/errLog', name: 'errLog', component: errLog},
    {path: '/opinion', name: 'opinion', component: opinion},
    {path: '/xlsx', name: 'xlsx', component: xlsx},
    {path: '/textShare', name: 'textShare', component: textShare},
    {path: '/foldersIndex', name: 'foldersIndex', component: foldersIndex},
    {path: '/updateLog', name: 'updateLog', component: updateLog},
    {path: '/task', name: 'task', component: task},
    // {path: '/xls', name: 'notFound', component: notFound},
];

const router = new VueRouter({
    mode: 'history', // 改为 'history'
    base: process.env.BASE_URL,
    routes,
});

export default router;
