<template>
    <div class="animated-characters">
        <back></back>
        <div class="banner" :class="{ 'banner--transparent': !isShow }" @click="isShow=!isShow">
            <img src="@/assets/img/ultramanBg.png" alt="Banner">
        </div>

        <div class="character-grid">
            <div v-for="item in animatedCharacterList" 
                 :key="item.id" 
                 class="character-card"
                 :class="{ 'character-card--transparent': !isShow }"
                 @click="getDetail(item)">
                <div class="character-card__image">
                    <img :src="item.icon" :alt="item.name">
                </div>
                <div class="character-card__name">
                    {{ item.name }}
                </div>
            </div>
        </div>

        <div v-if="!isShow && animatedCharacter" 
             class="character-detail"
             @click="isShow=!isShow">
            <div class="character-detail__header">
                <div class="character-detail__avatar">
                    <img :src="animatedCharacter.icon" :alt="animatedCharacter.name">
                </div>
                <div class="character-detail__info">
                    <h2 class="character-detail__name">{{ animatedCharacter.name }}</h2>
                    <div class="character-detail__tags">
                        <span class="tag">{{ animatedCharacter.description }}</span>
                        <span class="tag">{{ animatedCharacter.source }}</span>
                    </div>
                </div>
                <div class="character-detail__close">点击关闭</div>
            </div>
            <div class="character-detail__content">
                <img :src="animatedCharacter.imgUrl" :alt="animatedCharacter.name">
                <p class="character-detail__description">{{ animatedCharacter.introduce }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import Back from "@/components/back.vue";
import {getAnimatedCharacterList} from "@/api/yunoApi";

export default {
    name: "animatedCharacters",
    components: {Back},
    data() {
        return {
            animatedCharacter: null,
            isShow: true,
            pageNum: 1,
            pageSize: window.innerWidth > 600 ? 70 : 28,
            animatedCharacterList: [],
            isFetching: false
        };
    },
    methods: {
        getDetail(item) {
            this.isShow = !this.isShow;
            this.animatedCharacter = item;
        },
        async getAnimatedCharacterList() {
            if (this.isFetching) return;
            
            this.isFetching = true;
            try {
                const res = await getAnimatedCharacterList(this.pageNum, this.pageSize);
                this.animatedCharacterList = [...this.animatedCharacterList, ...res.list];
            } finally {
                this.isFetching = false;
            }
        },
        handleScroll() {
            if (this.isFetching) return;

            const {scrollTop, scrollHeight, clientHeight} = document.documentElement;
            if (scrollTop + clientHeight >= scrollHeight - 200) {
                this.pageNum++;
                this.getAnimatedCharacterList();
            }
        },
        setSeo() {
            document.title = "动漫头像展示 - 点击查看大图及人物基本信息";
            document
                .querySelector('meta[name="description"]')
                .setAttribute("content", "在这里，您可以浏览精美的动漫头像，点击头像查看更大图，同时了解每个动漫人物的基本信息和背景。立即探索，发现更多动漫角色！");
            document
                .querySelector('meta[name="keywords"]')
                .setAttribute("content", "动漫头像, 动漫人物, 头像大图, 动漫角色, 动漫基本信息, 动漫图片");
        }
    },
    mounted() {
        this.setSeo()
        this.getAnimatedCharacterList();
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    }
}
</script>

<style scoped>
.animated-characters {
    padding: 20px;
    min-height: 100vh;
    background-color: #f5f5f5;
}

.banner {
    margin: 20px 0;
    transition: opacity 0.3s ease;
}

.banner img {
    border-radius: 5px;
    width: 100%;
    height: 8%;
    background-color: #c00000;
}

.banner--transparent {
    opacity: 0.2;
}

.character-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 20px;
    padding: 20px;
    background: rgba(47, 158, 217, 0.1);
    border-radius: 12px;
}

.character-card {
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.character-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.character-card--transparent {
    opacity: 0.2;
}

.character-card__image {
    aspect-ratio: 1;
    overflow: hidden;
}

.character-card__image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.character-card:hover .character-card__image img {
    transform: scale(1.1);
}

.character-card__name {
    padding: 10px;
    text-align: center;
    font-weight: 600;
    background: #f8f9fa;
}

.character-detail {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 800px;
    max-height: 90vh;
    overflow-y: auto;
    background: rgba(255, 255, 255, 0.95);
    border-radius: 16px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    padding: 24px;
    z-index: 1000;
}

.character-detail__header {
    display: grid;
    grid-template-columns: auto 1fr auto;
    gap: 20px;
    align-items: center;
    margin-bottom: 24px;
}

.character-detail__avatar img {
    width: 100px;
    height: 100px;
    border-radius: 12px;
    object-fit: cover;
}

.character-detail__name {
    margin: 0 0 10px;
    font-size: 24px;
    color: #333;
}

.character-detail__tags {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
}

.tag {
    padding: 6px 12px;
    background: rgba(143, 166, 243, 0.1);
    color: #8fa6f3;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 600;
}

.character-detail__close {
    color: #666;
    font-size: 14px;
    cursor: pointer;
}

.character-detail__content img {
    width: 100%;
    border-radius: 12px;
    margin-bottom: 20px;
}

.character-detail__description {
    line-height: 1.6;
    color: #444;
}

@media (max-width: 768px) {
    .character-grid {
        grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
        gap: 10px;
        padding: 10px;
    }

    .character-detail {
        padding: 16px;
    }

    .character-detail__header {
        grid-template-columns: auto 1fr;
    }

    .character-detail__close {
        grid-column: 1 / -1;
        text-align: right;
    }
}
</style>