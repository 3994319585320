<template>
    <div>
        <back></back>
        <!-- 主屏幕区域 -->
        <div :class="['colorBg', { 'sidebar-expanded': isSidebarExpanded }]">
            <!-- 主屏幕内容区 -->
        </div>

        <!-- 侧边栏，颜色选择器 -->
        <div v-if="isSidebarExpanded" class="color-sidebar">
            <div class="color-picker">
                <div
                        class="color-box"
                        v-for="(color, index) in colors"
                        :key="index"
                        :style="{
                        backgroundColor: color.hex,
                        transform: selectedColor === color.hex ? 'scale(1.2)' : 'scale(1)',
                        animation: selectedColor === color.hex ? 'none' : 'jump 0.5s infinite alternate'
                    }"
                        @click="selectColor(color.hex)"
                >
                    <span class="color-name">{{ color.chineseName }}</span>
                    <span class="color-hex">{{ color.hex }}</span>
                </div>
            </div>
        </div>

        <!-- 侧边栏按钮，用于切换侧边栏的显示与隐藏 -->
        <button class="toggle-sidebar" :style="{ right: isSidebarExpanded ? '320px' : '0' }" @click="toggleSidebar">
            <span v-if="isSidebarExpanded">&#10005;</span> <!-- 收起按钮 -->
            <span v-else>&#9776;</span> <!-- 展开按钮 -->
        </button>
    </div>
</template>

<script>
import Back from "@/components/back.vue";

export default {
    components: {Back},
    data() {
        return {
            isSidebarExpanded: true,
            selectedColor: null,
            colors: [
                {chineseName: "精白", "hex": "#ffffff"},
                {chineseName: "胡粉色", "hex": "#fffffc"},
                {chineseName: "象牙白", "hex": "#fffbf0"},
                {chineseName: "白粉", "hex": "#fff2df"},
                {chineseName: "鸟の子色", "hex": "#fff1cf"},
                {chineseName: "鹅黄", "hex": "#fff143"},
                {chineseName: "菜の花色", "hex": "#ffec47"},
                {chineseName: "中黄", "hex": "#ffea00"},
                {chineseName: "黄支子色", "hex": "#ffdb4f"},
                {chineseName: "蒲公英色", "hex": "#ffd900"},
                {chineseName: "金色", "hex": "#FFD700"},
                {chineseName: "姜黄", "hex": "#ffc773"},
                {chineseName: "雌黄", "hex": "#ffc64b"},
                {chineseName: "琥珀色", "hex": "#FFBF00"},
                {chineseName: "藤黄", "hex": "#ffb61e"},
                {chineseName: "粉红", "hex": "#ffb3a7"},
                {chineseName: "爱马仕黄", "hex": "#FFB300"},
                {chineseName: "杏黄", "hex": "#ffa631"},
                {chineseName: "灰橙色", "hex": "#FFA500"},
                {chineseName: "橙黄", "hex": "#ffa400"},
                {chineseName: "杏红", "hex": "#ff8c31"},
                {chineseName: "橘黄", "hex": "#ff8936"},
                {chineseName: "珊瑚色", "hex": "#FF7F50"},
                {chineseName: "橘红", "hex": "#ff7500"},
                {chineseName: "丹", "hex": "#ff4e20"},
                {chineseName: "朱红", "hex": "#ff4c00"},
                {chineseName: "洋红", "hex": "#ff4777"},
                {chineseName: "朱砂", "hex": "#ff461f"},
                {chineseName: "炎", "hex": "#ff3300"},
                {chineseName: "火红", "hex": "#ff2d51"},
                {chineseName: "大红", "hex": "#ff2121"},
                {chineseName: "洋红", "hex": "#ff0097"},
                {chineseName: "玫瑰红", "hex": "#FF007F"},
                {chineseName: "黄檗色", "hex": "#fef263"},
                {chineseName: "薄桜", "hex": "#fdeff2"},
                {chineseName: "薄卵色", "hex": "#fde8d0"},
                {chineseName: "蜂蜜色", "hex": "#fddea5"},
                {chineseName: "鱼肚白", "hex": "#fcefe8"},
                {chineseName: "肌色", "hex": "#fce2c4"},
                {chineseName: "卵色", "hex": "#fcd575"},
                {chineseName: "向日葵色", "hex": "#fcc800"},
                {chineseName: "生成り色", "hex": "#fbfaf5"},
                {chineseName: "花葉色", "hex": "#fbd26b"},
                {chineseName: "支子色", "hex": "#fbca4d"},
                {chineseName: "鸭黄", "hex": "#faff72"},
                {chineseName: "鬱金色", "hex": "#fabf14"},
                {chineseName: "橙色", "hex": "#fa8c35"},
                {chineseName: "雄黄", "hex": "#f9c89b"},
                {chineseName: "酡颜", "hex": "#f9906f"},
                {chineseName: "白磁", "hex": "#f8fbf8"},
                {chineseName: "象牙色", "hex": "#f8f4e6"},
                {chineseName: "淡黄", "hex": "#f8e58c"},
                {chineseName: "萱草色", "hex": "#f8b862"},
                {chineseName: "山吹色", "hex": "#f8b500"},
                {chineseName: "卯の花色", "hex": "#f7fcfe"},
                {chineseName: "藤黄", "hex": "#f7c114"},
                {chineseName: "洒落柿", "hex": "#f7bd8f"},
                {chineseName: "杏色", "hex": "#f7b977"},
                {chineseName: "虹色", "hex": "#f6bfbc"},
                {chineseName: "赤香", "hex": "#f6b894"},
                {chineseName: "柑子色", "hex": "#f6ad49"},
                {chineseName: "刈安色", "hex": "#f5e56b"},
                {chineseName: "珊瑚色", "hex": "#f5b1aa"},
                {chineseName: "一斤染", "hex": "#f5b199"},
                {chineseName: "砥粉色", "hex": "#f4dda5"},
                {chineseName: "申布伦黄", "hex": "#F4D03F"},
                {chineseName: "鸨色", "hex": "#f4b3c2"},
                {chineseName: "沙色", "hex": "#F4A460"},
                {chineseName: "桃红", "hex": "#f47983"},
                {chineseName: "荼白", "hex": "#f3f9f1"},
                {chineseName: "乳白色", "hex": "#f3f3f3"},
                {chineseName: "白練", "hex": "#f3f3f2"},
                {chineseName: "淡香", "hex": "#f3bf88"},
                {chineseName: "金茶", "hex": "#f39800"},
                {chineseName: "朱膘", "hex": "#f36838"},
                {chineseName: "彤", "hex": "#f35336"},
                {chineseName: "女郎花", "hex": "#f2f2b0"},
                {chineseName: "缟", "hex": "#f2ecde"},
                {chineseName: "洗柿", "hex": "#f2c9ac"},
                {chineseName: "赤金", "hex": "#f2be45"},
                {chineseName: "红梅色", "hex": "#f2a0a1"},
                {chineseName: "石榴红", "hex": "#f20c00"},
                {chineseName: "肉色", "hex": "#f1bf99"},
                {chineseName: "曙色", "hex": "#f19072"},
                {chineseName: "雪白", "hex": "#f0fcff"},
                {chineseName: "铅白", "hex": "#f0f0f4"},
                {chineseName: "薄香", "hex": "#f0cfa0"},
                {chineseName: "缃色", "hex": "#f0c239"},
                {chineseName: "桃色", "hex": "#f09199"},
                {chineseName: "薄红", "hex": "#f0908d"},
                {chineseName: "蜜柑色", "hex": "#f08300"},
                {chineseName: "银红", "hex": "#f05654"},
                {chineseName: "品红", "hex": "#f00056"},
                {chineseName: "香色", "hex": "#efcd9a"},
                {chineseName: "宍色", "hex": "#efab93"},
                {chineseName: "嫣红", "hex": "#ef7a82"},
                {chineseName: "牙色", "hex": "#eedeb0"},
                {chineseName: "玉蜀黍色", "hex": "#eec362"},
                {chineseName: "撫子色", "hex": "#eebbcb"},
                {chineseName: "珊瑚朱色", "hex": "#ee836f"},
                {chineseName: "紫罗兰", "hex": "#EE82EE"},
                {chineseName: "甚三红", "hex": "#ee827c"},
                {chineseName: "黄丹", "hex": "#ee7948"},
                {chineseName: "橙色", "hex": "#ee7800"},
                {chineseName: "练色", "hex": "#ede4cd"},
                {chineseName: "蒸栗色", "hex": "#ede1a9"},
                {chineseName: "浅黄", "hex": "#edd3a1"},
                {chineseName: "藕色", "hex": "#edd1d8"},
                {chineseName: "柿色", "hex": "#ed6d3d"},
                {chineseName: "妃色", "hex": "#ed5736"},
                {chineseName: "真朱", "hex": "#ec6d71"},
                {chineseName: "鉛丹色", "hex": "#ec6d51"},
                {chineseName: "人参色", "hex": "#ec6800"},
                {chineseName: "藍白", "hex": "#ebf6f7"},
                {chineseName: "金糸雀色", "hex": "#ebd842"},
                {chineseName: "深支子", "hex": "#eb9b6f"},
                {chineseName: "赤紫", "hex": "#eb6ea5"},
                {chineseName: "照柿", "hex": "#eb6238"},
                {chineseName: "朱色", "hex": "#eb6101"},
                {chineseName: "樱草色", "hex": "#eaff56"},
                {chineseName: "月白", "hex": "#eaf4fc"},
                {chineseName: "白菫色", "hex": "#eaedf7"},
                {chineseName: "素色", "hex": "#eae5e3"},
                {chineseName: "金色", "hex": "#eacd76"},
                {chineseName: "金赤", "hex": "#ea5506"},
                {chineseName: "霜色", "hex": "#e9f1f6"},
                {chineseName: "银白", "hex": "#e9e7ef"},
                {chineseName: "灰白色", "hex": "#e9e4d4"},
                {chineseName: "桜鼠", "hex": "#e9dfe5"},
                {chineseName: "雄黄", "hex": "#e9bb1d"},
                {chineseName: "蔷薇色", "hex": "#e9546b"},
                {chineseName: "韩红", "hex": "#e95464"},
                {chineseName: "躑躅色", "hex": "#e95295"},
                {chineseName: "白花色", "hex": "#e8ecef"},
                {chineseName: "灰桜", "hex": "#e8d3d1"},
                {chineseName: "灰梅", "hex": "#e8d3c7"},
                {chineseName: "红緋", "hex": "#e83929"},
                {chineseName: "紫水晶", "hex": "#e7e7eb"},
                {chineseName: "牡丹色", "hex": "#e7609e"},
                {chineseName: "灰黄緑", "hex": "#e6eae3"},
                {chineseName: "淡紫色", "hex": "#E6E6FA"},
                {chineseName: "淡红藤", "hex": "#e6cde3"},
                {chineseName: "黄金", "hex": "#e6b422"},
                {chineseName: "赤", "hex": "#e60033"},
                {chineseName: "白梅鼠", "hex": "#e5e4e6"},
                {chineseName: "石竹色", "hex": "#e5abbe"},
                {chineseName: "薄红梅", "hex": "#e597b2"},
                {chineseName: "枯草色", "hex": "#e4dc8a"},
                {chineseName: "鸨鼠", "hex": "#e4d2d8"},
                {chineseName: "藕荷色", "hex": "#e4c6d0"},
                {chineseName: "水柿", "hex": "#e4ab9b"},
                {chineseName: "小麦色", "hex": "#e49e61"},
                {chineseName: "丹色", "hex": "#e45e32"},
                {chineseName: "莹白", "hex": "#e3f9fd"},
                {chineseName: "黄栌", "hex": "#e29c45"},
                {chineseName: "猩緋", "hex": "#e2041b"},
                {chineseName: "桃花色", "hex": "#e198b4"},
                {chineseName: "黄茶", "hex": "#e17b34"},
                {chineseName: "素", "hex": "#e0f0e9"},
                {chineseName: "鸭卵青", "hex": "#e0eee8"},
                {chineseName: "若芽色", "hex": "#e0ebaf"},
                {chineseName: "枯色", "hex": "#e0c38c"},
                {chineseName: "ときがら茶", "hex": "#e09e87"},
                {chineseName: "纁", "hex": "#e0815e"},
                {chineseName: "浅绯", "hex": "#df7163"},
                {chineseName: "饴色", "hex": "#deb068"},
                {chineseName: "絹鼠", "hex": "#dddcd6"},
                {chineseName: "白茶", "hex": "#ddbb99"},
                {chineseName: "肉桂色", "hex": "#dd7a56"},
                {chineseName: "白鼠", "hex": "#dcdddd"},
                {chineseName: "薄梅鼠", "hex": "#dcd6d9"},
                {chineseName: "砂色", "hex": "#dcd3b2"},
                {chineseName: "緑黄色", "hex": "#dccb18"},
                {chineseName: "酡红", "hex": "#dc3023"},
                {chineseName: "白藤色", "hex": "#dbd0e6"},
                {chineseName: "赤朽葉色", "hex": "#db8449"},
                {chineseName: "海棠红", "hex": "#db5a6b"},
                {chineseName: "秋香色", "hex": "#d9b611"},
                {chineseName: "櫨染", "hex": "#d9a62e"},
                {chineseName: "红赤", "hex": "#d9333f"},
                {chineseName: "若菜色", "hex": "#d8e698"},
                {chineseName: "伽羅色", "hex": "#d8a373"},
                {chineseName: "鶸色", "hex": "#d7cf3a"},
                {chineseName: "赤白橡", "hex": "#d7a98c"},
                {chineseName: "红", "hex": "#d7003a"},
                {chineseName: "月白", "hex": "#d6ecf0"},
                {chineseName: "白緑", "hex": "#d6e9ca"},
                {chineseName: "亜麻色", "hex": "#d6c6af"},
                {chineseName: "退红", "hex": "#d69090"},
                {chineseName: "黄櫨染", "hex": "#d66a35"},
                {chineseName: "真赭", "hex": "#d57c6b"},
                {chineseName: "薄雲鼠", "hex": "#d4dcda"},
                {chineseName: "蕎麦切色", "hex": "#d4dcd6"},
                {chineseName: "薄柿", "hex": "#d4acad"},
                {chineseName: "暁鼠", "hex": "#d3cfd9"},
                {chineseName: "牡丹鼠", "hex": "#d3ccd6"},
                {chineseName: "枯野色", "hex": "#d3cbc6"},
                {chineseName: "枯黄", "hex": "#d3b17d"},
                {chineseName: "黄朽葉色", "hex": "#d3a243"},
                {chineseName: "绯色", "hex": "#d3381c"},
                {chineseName: "巧克力色", "hex": "#D2691E"},
                {chineseName: "芥子色", "hex": "#d0af4c"},
                {chineseName: "洗朱", "hex": "#d0826c"},
                {chineseName: "今样色", "hex": "#d0576b"},
                {chineseName: "夏虫色", "hex": "#cee4ae"},
                {chineseName: "赤丹", "hex": "#ce5242"},
                {chineseName: "江戸茶", "hex": "#cd8c5c"},
                {chineseName: "樺色", "hex": "#cd5e3c"},
                {chineseName: "紅藤色", "hex": "#cca6bf"},
                {chineseName: "丁香色", "hex": "#cca4e3"},
                {chineseName: "菖蒲色", "hex": "#cc7eb1"},
                {chineseName: "白橡", "hex": "#cbb994"},
                {chineseName: "紅鬱金", "hex": "#cb8347"},
                {chineseName: "茜色", "hex": "#cb3a56"},
                {chineseName: "遠州茶", "hex": "#ca8269"},
                {chineseName: "琥珀", "hex": "#ca6924"},
                {chineseName: "柳黄", "hex": "#c9dd22"},
                {chineseName: "长春色", "hex": "#c97586"},
                {chineseName: "樱桃色", "hex": "#c93756"},
                {chineseName: "赫赤", "hex": "#c91f37"},
                {chineseName: "深緋", "hex": "#c9171e"},
                {chineseName: "柳鼠", "hex": "#c8d5bb"},
                {chineseName: "霞色", "hex": "#c8c2c6"},
                {chineseName: "潤色", "hex": "#c8c2be"},
                {chineseName: "昏黄", "hex": "#c89b40"},
                {chineseName: "山吹茶", "hex": "#c89932"},
                {chineseName: "银朱", "hex": "#c85554"},
                {chineseName: "中红", "hex": "#c85179"},
                {chineseName: "绯红", "hex": "#c83c23"},
                {chineseName: "若苗色", "hex": "#c7dc68"},
                {chineseName: "木蘭色", "hex": "#c7b370"},
                {chineseName: "抹茶色", "hex": "#c5c56a"},
                {chineseName: "赤红", "hex": "#c53d43"},
                {chineseName: "浅紫", "hex": "#c4a3bf"},
                {chineseName: "若草色", "hex": "#c3d825"},
                {chineseName: "卡其色", "hex": "#C3B091"},
                {chineseName: "黄土色", "hex": "#c39143"},
                {chineseName: "狐色", "hex": "#c38743"},
                {chineseName: "土器色", "hex": "#c37854"},
                {chineseName: "赤", "hex": "#c3272b"},
                {chineseName: "枣红", "hex": "#c32136"},
                {chineseName: "花白", "hex": "#c2ccd0"},
                {chineseName: "白藍", "hex": "#c1e4e9"},
                {chineseName: "裏葉柳", "hex": "#c1d8ac"},
                {chineseName: "青白", "hex": "#c0ebd7"},
                {chineseName: "灰青", "hex": "#c0c6c9"},
                {chineseName: "银色", "hex": "#C0C0C0"},
                {chineseName: "薄葡萄", "hex": "#c0a2c7"},
                {chineseName: "梅鼠", "hex": "#c099a0"},
                {chineseName: "灰玫瑰", "hex": "#C08081"},
                {chineseName: "榛色", "hex": "#bfa46f"},
                {chineseName: "骆驼色", "hex": "#bf794e"},
                {chineseName: "琥珀色", "hex": "#bf783a"},
                {chineseName: "桜色", "hex": "#bf242a"},
                {chineseName: "千草鼠", "hex": "#bed3ca"},
                {chineseName: "青磁鼠", "hex": "#bed2c3"},
                {chineseName: "殷红", "hex": "#be002f"},
                {chineseName: "嫩绿", "hex": "#bddd22"},
                {chineseName: "松花色", "hex": "#bce672"},
                {chineseName: "水色", "hex": "#bce2e8"},
                {chineseName: "土色", "hex": "#bc763c"},
                {chineseName: "若紫", "hex": "#bc64a4"},
                {chineseName: "蟹壳青", "hex": "#bbcdc5"},
                {chineseName: "淡藤色", "hex": "#bbc8e6"},
                {chineseName: "藤色", "hex": "#bbbcde"},
                {chineseName: "红桦色", "hex": "#bb5548"},
                {chineseName: "赤茶", "hex": "#bb5535"},
                {chineseName: "代赭", "hex": "#bb5520"},
                {chineseName: "薄萌葱", "hex": "#badcad"},
                {chineseName: "老银", "hex": "#bacac6"},
                {chineseName: "朱緋", "hex": "#ba2636"},
                {chineseName: "若葉色", "hex": "#b9d08b"},
                {chineseName: "黄唐色", "hex": "#b98c46"},
                {chineseName: "胭脂", "hex": "#b94047"},
                {chineseName: "黄緑", "hex": "#b8d200"},
                {chineseName: "鸨浅葱", "hex": "#b88884"},
                {chineseName: "桑染", "hex": "#b79b5b"},
                {chineseName: "栌色", "hex": "#b77b57"},
                {chineseName: "茜色", "hex": "#b7282e"},
                {chineseName: "黄橡", "hex": "#b68d4c"},
                {chineseName: "煉瓦色", "hex": "#b55233"},
                {chineseName: "梅染", "hex": "#b48a76"},
                {chineseName: "丁子茶", "hex": "#b4866b"},
                {chineseName: "紅紫", "hex": "#b44c97"},
                {chineseName: "利休白茶", "hex": "#b3ada0"},
                {chineseName: "檀", "hex": "#b36d61"},
                {chineseName: "茶色", "hex": "#b35c44"},
                {chineseName: "柴染", "hex": "#b28c6e"},
                {chineseName: "棕色", "hex": "#b25d25"},
                {chineseName: "苗色", "hex": "#b0ca71"},
                {chineseName: "雪青", "hex": "#b0a4e3"},
                {chineseName: "提香红", "hex": "#B05923"},
                {chineseName: "柳绿", "hex": "#afdd22"},
                {chineseName: "銀鼠", "hex": "#afafb0"},
                {chineseName: "焦香", "hex": "#ae7c58"},
                {chineseName: "枇杷茶", "hex": "#ae7c4f"},
                {chineseName: "棕黄", "hex": "#ae7000"},
                {chineseName: "薄鈍", "hex": "#adadad"},
                {chineseName: "青朽葉", "hex": "#ada250"},
                {chineseName: "芝翫茶", "hex": "#ad7e4e"},
                {chineseName: "香染", "hex": "#ad7d4c"},
                {chineseName: "秘色色", "hex": "#abced8"},
                {chineseName: "赭", "hex": "#ab6953"},
                {chineseName: "萌黄", "hex": "#aacf53"},
                {chineseName: "雀茶", "hex": "#aa4f37"},
                {chineseName: "梅紫", "hex": "#aa4c8f"},
                {chineseName: "茶鼠", "hex": "#a99e93"},
                {chineseName: "绾", "hex": "#a98175"},
                {chineseName: "柳色", "hex": "#a8c97f"},
                {chineseName: "山葵色", "hex": "#a8bf93"},
                {chineseName: "薄色", "hex": "#a89dac"},
                {chineseName: "驼色", "hex": "#a88462"},
                {chineseName: "胡桃色", "hex": "#a86f4c"},
                {chineseName: "苏芳香", "hex": "#a86965"},
                {chineseName: "乌金", "hex": "#a78e44"},
                {chineseName: "深海老茶", "hex": "#a73836"},
                {chineseName: "錆青磁", "hex": "#a6c8b2"},
                {chineseName: "藤鼠", "hex": "#a6a5c4"},
                {chineseName: "半色", "hex": "#a69abd"},
                {chineseName: "菜種油色", "hex": "#a69425"},
                {chineseName: "藤紫", "hex": "#a59aca"},
                {chineseName: "利休茶", "hex": "#a59564"},
                {chineseName: "胡桃染", "hex": "#a58f86"},
                {chineseName: "艾绿", "hex": "#a4e2c6"},
                {chineseName: "葱黄", "hex": "#a3d900"},
                {chineseName: "薄墨色", "hex": "#a3a3a2"},
                {chineseName: "瓶覗", "hex": "#a2d7dd"},
                {chineseName: "浅苏芳", "hex": "#a25768"},
                {chineseName: "真红", "hex": "#a22041"},
                {chineseName: "蓝灰色", "hex": "#a1afc9"},
                {chineseName: "柳茶", "hex": "#a1a46d"},
                {chineseName: "油色", "hex": "#a19361"},
                {chineseName: "宗伝唐茶", "hex": "#a16d5d"},
                {chineseName: "空色", "hex": "#a0d8ef"},
                {chineseName: "素鼠", "hex": "#9fa0a0"},
                {chineseName: "砺茶", "hex": "#9f6f55"},
                {chineseName: "柿渋色", "hex": "#9f563a"},
                {chineseName: "葱绿", "hex": "#9ed900"},
                {chineseName: "豆绿", "hex": "#9ed048"},
                {chineseName: "錫色", "hex": "#9ea1a3"},
                {chineseName: "灰汁色", "hex": "#9e9478"},
                {chineseName: "鳩羽鼠", "hex": "#9e8b8e"},
                {chineseName: "苏芳", "hex": "#9e3d3f"},
                {chineseName: "空五倍子色", "hex": "#9d896c"},
                {chineseName: "京紫", "hex": "#9d5b8b"},
                {chineseName: "胭脂", "hex": "#9d2933"},
                {chineseName: "赭", "hex": "#9c5333"},
                {chineseName: "青白橡", "hex": "#9ba88d"},
                {chineseName: "棕红", "hex": "#9b4400"},
                {chineseName: "紅鳶", "hex": "#9a493f"},
                {chineseName: "青丹", "hex": "#99ab4e"},
                {chineseName: "灰紫", "hex": "#9999CC"},
                {chineseName: "薄荷绿", "hex": "#98FF98"},
                {chineseName: "若緑", "hex": "#98d98e"},
                {chineseName: "灰茶", "hex": "#98623c"},
                {chineseName: "深川鼠", "hex": "#97a791"},
                {chineseName: "薄鼠", "hex": "#9790a4"},
                {chineseName: "豆青", "hex": "#96ce54"},
                {chineseName: "小豆色", "hex": "#96514d"},
                {chineseName: "茶色", "hex": "#965042"},
                {chineseName: "檜皮色", "hex": "#965036"},
                {chineseName: "桔梗鼠", "hex": "#95949a"},
                {chineseName: "鳩羽色", "hex": "#95859c"},
                {chineseName: "桑茶", "hex": "#956f29"},
                {chineseName: "赭色", "hex": "#955539"},
                {chineseName: "柿茶", "hex": "#954e2a"},
                {chineseName: "鳶色", "hex": "#95483f"},
                {chineseName: "鼠色", "hex": "#949495"},
                {chineseName: "生壁色", "hex": "#94846a"},
                {chineseName: "渋纸色", "hex": "#946243"},
                {chineseName: "青柠色", "hex": "#93DFB8"},
                {chineseName: "淡萌黄", "hex": "#93ca76"},
                {chineseName: "柳染", "hex": "#93b881"},
                {chineseName: "薄青", "hex": "#93b69c"},
                {chineseName: "根岸色", "hex": "#938b4b"},
                {chineseName: "千草色", "hex": "#92b5a9"},
                {chineseName: "长春花蓝", "hex": "#92A8D1"},
                {chineseName: "鶯色", "hex": "#928c36"},
                {chineseName: "江戸鼠", "hex": "#928178"},
                {chineseName: "黄海松茶", "hex": "#918754"},
                {chineseName: "朽葉色", "hex": "#917347"},
                {chineseName: "二藍", "hex": "#915c8b"},
                {chineseName: "灰绿色", "hex": "#90EE90"},
                {chineseName: "竜胆色", "hex": "#9079ad"},
                {chineseName: "利休色", "hex": "#8f8667"},
                {chineseName: "弁柄色", "hex": "#8f2e14"},
                {chineseName: "枯茶", "hex": "#8d6449"},
                {chineseName: "紫色", "hex": "#8d4bbb"},
                {chineseName: "鶸茶", "hex": "#8c8861"},
                {chineseName: "路考茶", "hex": "#8c7042"},
                {chineseName: "煎茶色", "hex": "#8c6450"},
                {chineseName: "绛紫", "hex": "#8c4356"},
                {chineseName: "豆がら茶", "hex": "#8b968d"},
                {chineseName: "赭石色", "hex": "#8B4513"},
                {chineseName: "绛红", "hex": "#8B0000"},
                {chineseName: "褐色", "hex": "#8a3b00"},
                {chineseName: "赤錆色", "hex": "#8a3319"},
                {chineseName: "勿忘草色", "hex": "#89c3eb"},
                {chineseName: "肥後煤竹", "hex": "#897858"},
                {chineseName: "秋色", "hex": "#896c39"},
                {chineseName: "古代紫", "hex": "#895b8a"},
                {chineseName: "浅緑", "hex": "#88cb7f"},
                {chineseName: "水色", "hex": "#88ada6"},
                {chineseName: "利休鼠", "hex": "#888e7e"},
                {chineseName: "源氏鼠", "hex": "#888084"},
                {chineseName: "煤色", "hex": "#887f7a"},
                {chineseName: "梅幸茶", "hex": "#887938"},
                {chineseName: "紫", "hex": "#884898"},
                {chineseName: "紫苑色", "hex": "#867ba9"},
                {chineseName: "銀煤竹", "hex": "#856859"},
                {chineseName: "栗梅", "hex": "#852e19"},
                {chineseName: "浅縹", "hex": "#84b9cb"},
                {chineseName: "青藤色", "hex": "#84a2d4"},
                {chineseName: "紅碧", "hex": "#8491c3"},
                {chineseName: "赭石", "hex": "#845a33"},
                {chineseName: "白群", "hex": "#83ccd2"},
                {chineseName: "松葉色", "hex": "#839b5c"},
                {chineseName: "鶸萌黄", "hex": "#82ae46"},
                {chineseName: "棕绿", "hex": "#827100"},
                {chineseName: "茄子紺", "hex": "#824880"},
                {chineseName: "蒂芙尼蓝", "hex": "#81D8CF"},
                {chineseName: "酱紫", "hex": "#815476"},
                {chineseName: "紫酱", "hex": "#815463"},
                {chineseName: "水浅葱", "hex": "#80aba9"},
                {chineseName: "湊鼠", "hex": "#80989b"},
                {chineseName: "灰色", "hex": "#808080"},
                {chineseName: "橄榄绿", "hex": "#808000"},
                {chineseName: "青莲", "hex": "#801dae"},
                {chineseName: "缥", "hex": "#7fecad"},
                {chineseName: "青竹色", "hex": "#7ebeab"},
                {chineseName: "青磁色", "hex": "#7ebea5"},
                {chineseName: "灰色", "hex": "#7d7d7d"},
                {chineseName: "草地绿", "hex": "#7CFC00"},
                {chineseName: "棕黑", "hex": "#7c4b00"},
                {chineseName: "石青", "hex": "#7bcfa6"},
                {chineseName: "草色", "hex": "#7b8d42"},
                {chineseName: "鉛色", "hex": "#7b7c7d"},
                {chineseName: "国防色", "hex": "#7b6c3e"},
                {chineseName: "紅檜皮", "hex": "#7b4741"},
                {chineseName: "蒲葡", "hex": "#7a4171"},
                {chineseName: "竹青", "hex": "#789262"},
                {chineseName: "唐茶", "hex": "#783c1d"},
                {chineseName: "海老茶", "hex": "#773c30"},
                {chineseName: "老竹色", "hex": "#769164"},
                {chineseName: "山鳩色", "hex": "#767c6b"},
                {chineseName: "黄枯茶", "hex": "#765c47"},
                {chineseName: "栗色", "hex": "#762f07"},
                {chineseName: "墨灰", "hex": "#758a99"},
                {chineseName: "苍色", "hex": "#75878a"},
                {chineseName: "黎", "hex": "#75664d"},
                {chineseName: "赤銅色", "hex": "#752100"},
                {chineseName: "江戸紫", "hex": "#745399"},
                {chineseName: "暗紅色", "hex": "#74325c"},
                {chineseName: "鈍色", "hex": "#727171"},
                {chineseName: "海松色", "hex": "#726d40"},
                {chineseName: "樺茶色", "hex": "#726250"},
                {chineseName: "乌色", "hex": "#725e82"},
                {chineseName: "紫鼠", "hex": "#71686c"},
                {chineseName: "媚茶", "hex": "#716246"},
                {chineseName: "鶯茶", "hex": "#715c1f"},
                {chineseName: "蔚蓝", "hex": "#70f3ff"},
                {chineseName: "石板灰", "hex": "#708090"},
                {chineseName: "藤納戸", "hex": "#706caa"},
                {chineseName: "葡萄鼠", "hex": "#705b67"},
                {chineseName: "菫色", "hex": "#7058a3"},
                {chineseName: "煤竹色", "hex": "#6f514c"},
                {chineseName: "焦茶", "hex": "#6f4b3e"},
                {chineseName: "麹塵", "hex": "#6e7955"},
                {chineseName: "褐色", "hex": "#6e511e"},
                {chineseName: "栗皮茶", "hex": "#6d3c32"},
                {chineseName: "藍鼠", "hex": "#6c848d"},
                {chineseName: "錆色", "hex": "#6c3524"},
                {chineseName: "葡萄茶", "hex": "#6c2c2f"},
                {chineseName: "青鈍", "hex": "#6b7b6e"},
                {chineseName: "岩井茶", "hex": "#6b6f59"},
                {chineseName: "黝", "hex": "#6b6882"},
                {chineseName: "灰豆绿", "hex": "#6A8326"},
                {chineseName: "璃寛茶", "hex": "#6a5d21"},
                {chineseName: "薄緑", "hex": "#69b076"},
                {chineseName: "薄花色", "hex": "#698aab"},
                {chineseName: "苔色", "hex": "#69821b"},
                {chineseName: "若竹色", "hex": "#68be8d"},
                {chineseName: "紅掛花色", "hex": "#68699b"},
                {chineseName: "赤褐色", "hex": "#683f36"},
                {chineseName: "青紫", "hex": "#674598"},
                {chineseName: "菖蒲色", "hex": "#674196"},
                {chineseName: "黝黑", "hex": "#665757"},
                {chineseName: "茶褐色", "hex": "#664032"},
                {chineseName: "本紫", "hex": "#65318e"},
                {chineseName: "葡萄色", "hex": "#640125"},
                {chineseName: "濃色", "hex": "#634950"},
                {chineseName: "玄色", "hex": "#622a1d"},
                {chineseName: "栗色", "hex": "#60281e"},
                {chineseName: "紫鳶", "hex": "#5f414b"},
                {chineseName: "黧", "hex": "#5d513c"},
                {chineseName: "錆浅葱", "hex": "#5c9291"},
                {chineseName: "舛花色", "hex": "#5b7e91"},
                {chineseName: "柳煤竹", "hex": "#5b6356"},
                {chineseName: "薄花桜", "hex": "#5a79ba"},
                {chineseName: "海松茶", "hex": "#5a544b"},
                {chineseName: "藤煤竹", "hex": "#5a5359"},
                {chineseName: "凡戴克棕", "hex": "#5A3E36"},
                {chineseName: "新橋色", "hex": "#59b9c6"},
                {chineseName: "墨", "hex": "#595857"},
                {chineseName: "丼鼠", "hex": "#595455"},
                {chineseName: "滅紫", "hex": "#594255"},
                {chineseName: "黒茶", "hex": "#583822"},
                {chineseName: "黛紫", "hex": "#574266"},
                {chineseName: "藍媚茶", "hex": "#56564b"},
                {chineseName: "桔梗色", "hex": "#5654a2"},
                {chineseName: "紫棠", "hex": "#56004f"},
                {chineseName: "涅色", "hex": "#554738"},
                {chineseName: "桑の実色", "hex": "#55295b"},
                {chineseName: "铜绿", "hex": "#549688"},
                {chineseName: "黒橡", "hex": "#544a47"},
                {chineseName: "憲法色", "hex": "#543f32"},
                {chineseName: "薄群青", "hex": "#5383c3"},
                {chineseName: "錆御納戸", "hex": "#53727d"},
                {chineseName: "消炭色", "hex": "#524e4d"},
                {chineseName: "紅消鼠", "hex": "#524748"},
                {chineseName: "葡萄色", "hex": "#522f60"},
                {chineseName: "似せ紫", "hex": "#513743"},
                {chineseName: "薄縹", "hex": "#507ea4"},
                {chineseName: "墨色", "hex": "#50616d"},
                {chineseName: "濃鼠", "hex": "#4f455c"},
                {chineseName: "紺桔梗", "hex": "#4d5aaf"},
                {chineseName: "褐色", "hex": "#4d4c61"},
                {chineseName: "紅桔梗", "hex": "#4d4398"},
                {chineseName: "群青", "hex": "#4c8dae"},
                {chineseName: "群青色", "hex": "#4c6cb3"},
                {chineseName: "御召御納戸", "hex": "#4c6473"},
                {chineseName: "紫檀", "hex": "#4c221b"},
                {chineseName: "宝蓝", "hex": "#4b5cc4"},
                {chineseName: "皇家紫", "hex": "#4B0082"},
                {chineseName: "紺藍", "hex": "#4a488e"},
                {chineseName: "黛", "hex": "#4a4266"},
                {chineseName: "千歳茶", "hex": "#494a41"},
                {chineseName: "深紫", "hex": "#493759"},
                {chineseName: "缁色", "hex": "#493131"},
                {chineseName: "青碧", "hex": "#48c0a3"},
                {chineseName: "錆鉄御納戸", "hex": "#485859"},
                {chineseName: "緑青色", "hex": "#47885e"},
                {chineseName: "青碧", "hex": "#478384"},
                {chineseName: "革色", "hex": "#475950"},
                {chineseName: "錆鼠", "hex": "#47585c"},
                {chineseName: "仙斎茶", "hex": "#474b42"},
                {chineseName: "藍墨茶", "hex": "#474a4d"},
                {chineseName: "灰青色", "hex": "#4682B4"},
                {chineseName: "紫紺", "hex": "#460e44"},
                {chineseName: "鉄御納戸", "hex": "#455765"},
                {chineseName: "蓝", "hex": "#44cef6"},
                {chineseName: "紺鼠", "hex": "#44617b"},
                {chineseName: "御召茶", "hex": "#43676b"},
                {chineseName: "檳榔子染", "hex": "#433d3c"},
                {chineseName: "黒鳶", "hex": "#432f2f"},
                {chineseName: "黛绿", "hex": "#426666"},
                {chineseName: "熨斗目花色", "hex": "#426579"},
                {chineseName: "黛蓝", "hex": "#425066"},
                {chineseName: "鸦青", "hex": "#424c50"},
                {chineseName: "黯", "hex": "#41555d"},
                {chineseName: "青绿", "hex": "#40E0D0"},
                {chineseName: "草绿", "hex": "#40de5a"},
                {chineseName: "赤墨", "hex": "#3f312b"},
                {chineseName: "碧蓝", "hex": "#3eede7"},
                {chineseName: "緑", "hex": "#3eb370"},
                {chineseName: "杜若色", "hex": "#3e62ad"},
                {chineseName: "翡翠色", "hex": "#3de1ad"},
                {chineseName: "玄青", "hex": "#3d3b4f"},
                {chineseName: "雪松绿", "hex": "#3C493A"},
                {chineseName: "木賊色", "hex": "#3b7960"},
                {chineseName: "藏蓝", "hex": "#3b2e7e"},
                {chineseName: "虫襖", "hex": "#3a5b52"},
                {chineseName: "藍鉄", "hex": "#393f4c"},
                {chineseName: "青褐", "hex": "#393e4f"},
                {chineseName: "乌黑", "hex": "#392f41"},
                {chineseName: "翡翠色", "hex": "#38b48b"},
                {chineseName: "露草色", "hex": "#38a1db"},
                {chineseName: "羊羹色", "hex": "#383c3c"},
                {chineseName: "黒緑", "hex": "#333631"},
                {chineseName: "千歳緑", "hex": "#316745"},
                {chineseName: "煤黑", "hex": "#312520"},
                {chineseName: "香奈儿黑", "hex": "#303030"},
                {chineseName: "黒紅", "hex": "#302833"},
                {chineseName: "天鵞絨", "hex": "#2f5d50"},
                {chineseName: "玉色", "hex": "#2edfa3"},
                {chineseName: "藏青", "hex": "#2e4e7e"},
                {chineseName: "紫黒", "hex": "#2e2930"},
                {chineseName: "天色", "hex": "#2ca9e1"},
                {chineseName: "高麗納戸", "hex": "#2c4f54"},
                {chineseName: "蝋色", "hex": "#2b2b2b"},
                {chineseName: "碧绿", "hex": "#2add9c"},
                {chineseName: "花浅葱", "hex": "#2a83a2"},
                {chineseName: "深縹", "hex": "#2a4073"},
                {chineseName: "鉄黒", "hex": "#281a14"},
                {chineseName: "縹色", "hex": "#2792c3"},
                {chineseName: "青藍", "hex": "#274a78"},
                {chineseName: "黒壇", "hex": "#250d00"},
                {chineseName: "憲法黒茶", "hex": "#241a08"},
                {chineseName: "森林绿", "hex": "#228B22"},
                {chineseName: "紺色", "hex": "#223a70"},
                {chineseName: "松柏绿", "hex": "#21a675"},
                {chineseName: "褐返", "hex": "#203744"},
                {chineseName: "百入茶", "hex": "#1f3134"},
                {chineseName: "瑠璃色", "hex": "#1e50a2"},
                {chineseName: "留紺", "hex": "#1c305c"},
                {chineseName: "碧色", "hex": "#1bd1a5"},
                {chineseName: "瑠璃紺", "hex": "#19448e"},
                {chineseName: "紺青", "hex": "#192f60"},
                {chineseName: "靛蓝色", "hex": "#191970"},
                {chineseName: "烏羽色", "hex": "#180614"},
                {chineseName: "靛青", "hex": "#177cb0"},
                {chineseName: "鉄紺", "hex": "#17184b"},
                {chineseName: "石绿", "hex": "#16a951"},
                {chineseName: "石青", "hex": "#1685a9"},
                {chineseName: "藍色", "hex": "#165e83"},
                {chineseName: "紺瑠璃", "hex": "#164a84"},
                {chineseName: "漆黑", "hex": "#161823"},
                {chineseName: "暗黒色", "hex": "#16160e"},
                {chineseName: "濃藍", "hex": "#0f2350"},
                {chineseName: "葱青", "hex": "#0eb83a"},
                {chineseName: "漆黒", "hex": "#0d0015"},
                {chineseName: "绿沈", "hex": "#0c8918"},
                {chineseName: "青葱", "hex": "#0aa344"},
                {chineseName: "靛蓝", "hex": "#065279"},
                {chineseName: "松花绿", "hex": "#057748"},
                {chineseName: "青瓷色", "hex": "#02A4D3"},
                {chineseName: "常磐緑", "hex": "#028760"},
                {chineseName: "绿色", "hex": "#00e500"},
                {chineseName: "青色", "hex": "#00e09e"},
                {chineseName: "青翠", "hex": "#00e079"},
                {chineseName: "天蓝色", "hex": "#00BFFF"},
                {chineseName: "油绿", "hex": "#00bc12"},
                {chineseName: "青緑", "hex": "#00a497"},
                {chineseName: "浅葱色", "hex": "#00a3af"},
                {chineseName: "花緑青", "hex": "#00a381"},
                {chineseName: "青", "hex": "#0095d9"},
                {chineseName: "薄藍", "hex": "#0094c8"},
                {chineseName: "納戸色", "hex": "#008899"},
                {chineseName: "马尔斯绿", "hex": "#008751"},
                {chineseName: "紺碧", "hex": "#007bbb"},
                {chineseName: "宝石蓝", "hex": "#007BA7"},
                {chineseName: "常磐色", "hex": "#007b43"},
                {chineseName: "萌葱色", "hex": "#006e54"},
                {chineseName: "深緑", "hex": "#00552e"},
                {chineseName: "鉄色", "hex": "#005243"},
                {chineseName: "花青", "hex": "#003472"},
                {chineseName: "绀青", "hex": "#003371"},
                {chineseName: "普鲁士蓝", "hex": "#003152"},
                {chineseName: "克莱因蓝", "hex": "#002FA7"},
                {chineseName: "濡羽色", "hex": "#000b00"},
                {chineseName: "深蓝色", "hex": "#00008B"},
                {chineseName: "海军蓝", "hex": "#000080"},
                {chineseName: "黑色", "hex": "#000000"},
            ] ,
        };
    },
    methods: {
        setSeo(){
            document.title = "颜色选择器 - 改变背景色体验";
            document
                .querySelector('meta[name="description"]')
                .setAttribute("content", "体验色彩的魅力！使用我们的颜色选择器轻松改变页面背景色，享受丰富的视觉效果。");
            document
                .querySelector('meta[name="keywords"]')
                .setAttribute("content", "颜色选择器, 背景色, 色彩体验, 前端开发, 动态背景, 色彩工具");
        },
        toggleSidebar() {
            this.isSidebarExpanded = !this.isSidebarExpanded;
        },
        selectColor(color) {
            this.selectedColor = color;
            this.changeBackgroundColor(color);
        },
        changeBackgroundColor(color) {
            const colorBgElement = document.querySelector('.colorBg');
            if (colorBgElement) {
                colorBgElement.style.backgroundColor = color;
            }
            document.body.style.backgroundColor = color;
        },
    },
    mounted() {
        this.setSeo()
    }
};
</script>

<style scoped>
/* 主屏幕背景 */
.colorBg {
    transition: background-color 0.5s ease;
    background-color: rgba(80, 80, 80, 0.47);
    position: relative;
    overflow: hidden;
}

/* 颜色选择器样式 */
.color-picker {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: center;
}

.color-box {
    width: 80px;
    height: 48px; /* 保持原样的高度，适当增加视觉效果 */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s, box-shadow 0.3s;
}

.color-box:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.color-name,
.color-hex {
    color: white;
    font-size: 12px;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
    text-align: center;
}

.color-name {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 4px;
}

/* 侧边栏样式 */
.color-sidebar {
    position: fixed;
    top: 0;
    right: 0;
    width: 300px; /* 侧边栏宽度 */
    height: 100%;
    background: rgba(169, 162, 162, 0.4); /* 透明的毛玻璃效果 */
    backdrop-filter: blur(15px); /* 增加磨砂效果 */
    z-index: 10;
    padding: 20px;
    overflow-y: auto;
    border-left: 2px solid rgba(0, 0, 0, 0.2);
    border-radius: 0 0 0 15px;
}

/* 按钮样式，点击切换侧边栏的显示与隐藏 */
.toggle-sidebar {
    position: fixed;
    top: 50%;
    background-color: #4CAF50; /* 绿色背景 */
    color: white;
    border: none;
    padding: 8px; /* 调整按钮大小 */
    cursor: pointer;
    z-index: 15;
    font-size: 18px; /* 调整字体大小 */
    width: 40px; /* 调整按钮宽度 */
    height: 40px; /* 调整按钮高度 */
    border-radius: 50%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.toggle-sidebar:hover {
    background-color: #ff0745; /* 深绿色 */
    transform: scale(1.05);
    cursor: pointer;
}

.toggle-sidebar:active {
    transform: scale(0.95); /* 按下时缩小 */
}

/* 按钮的展开和收起符号 */
.toggle-sidebar span {
    display: inline-block;
    width: 100%;
    text-align: center;
}

/* 动画效果 */
@keyframes jump {
    0% { transform: translateY(0); }
    100% { transform: translateY(-5px); }
}
</style>
