// import pinyin from 'js-pinyin'

Date.prototype.format = function (fmt) {
    let o = {
        'M+': this.getMonth() + 1,                 // 月份
        'd+': this.getDate(),                    // 日
        'h+': this.getHours(),                   // 小时
        'm+': this.getMinutes(),                 // 分
        's+': this.getSeconds(),                 // 秒
        'q+': Math.floor((this.getMonth() + 3) / 3), // 季度
        S: this.getMilliseconds()             // 毫秒
    };
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length));
    }
    for (let k in o) {
        if (new RegExp('(' + k + ')').test(fmt)) {
            fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));
        }
    }
    return fmt;
}

// 传入日期，获取上一个物料填报月（21号到下个月20号，算做本月）
export function getRequireDate(date) {
    let yMonth = '';
    let curMonth = date.getMonth();
    if (date.getDate() <= 20) {
        if (date.getMonth === 0) {
            yMonth = (date.getFullYear() - 1) + '-12';
        } else {
            yMonth = date.getFullYear() + '-' + (curMonth < 10 ? '0' + curMonth.toString() : curMonth.toString());
        }
    } else {
        yMonth = date.format('yyyy-MM');
    }
    return yMonth;
}

export function getDate(strDate) {
    let date = eval('new Date(' + strDate.replace(/\d+(?=-[^-]+$)/,
        (a) => parseInt(a, 10) - 1).match(/\d+/g) + ')');
    return date;
}
export function getPatternTime(pattern) {
    const date = new Date();

    const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
    };

    // 替换pattern中的字符，生成对应的格式
    const formatPattern = pattern.replace('yyyy', date.getFullYear())
        .replace('MM', String(date.getMonth() + 1).padStart(2, '0'))
        .replace('dd', String(date.getDate()).padStart(2, '0'))
        .replace('HH', String(date.getHours()).padStart(2, '0'))
        .replace('mm', String(date.getMinutes()).padStart(2, '0'))
        .replace('ss', String(date.getSeconds()).padStart(2, '0'));

    return formatPattern;
}
export function parseTime(time) {
    const date = new Date(time);
    const weekDays = ['日', '一', '二', '三', '四', '五', '六'];
    const year = date.getFullYear();
    const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    const hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
    const minute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    const second = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
    const dayOfWeek = weekDays[date.getDay()];
    return `${year}年${month}月${day}日 ${hour}:${minute}:${second} 星期${dayOfWeek}`;
}

export function parseAge(birthday) {
    const birthDate = new Date(birthday);
    const currentDate = new Date();

    // 计算年龄
    let age = currentDate.getFullYear() - birthDate.getFullYear();
    // 如果当前月份小于出生月份，或者当前月份与出生月份相等但当前日期小于出生日期，则年龄减1
    if (currentDate.getMonth() < birthDate.getMonth() ||
        (currentDate.getMonth() === birthDate.getMonth() && currentDate.getDate() < birthDate.getDate())) {
        age--;
    }
    return age + '岁';
}

export function parseTime2(time) {
    const date = new Date(time);
    const weekDays = ['日', '一', '二', '三', '四', '五', '六'];
    const year = date.getFullYear();
    const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    const hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
    const minute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    const second = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
    const dayOfWeek = weekDays[date.getDay()];
    return `${year}年${month}月${day}日 ${hour}:${minute}:${second}`;
}

export function parseTime3(time) {
    const date = new Date(time);
    const weekDays = ['日', '一', '二', '三', '四', '五', '六'];
    const year = date.getFullYear();
    const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    return `${year}-${month}-${day}`;
}
export function parseTime4(time) {
    const date = new Date(time);
    const hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
    const minute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    return `${hour}:${minute}`;
}
export function parseTime5(time) {
    const date = new Date(time);
    const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    const hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
    const minute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    const second = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
    return `${month}.${day} ${hour}:${minute}:${second}`;
}
export function getTime() {
    const date = new Date();
    const weekDays = ['日', '一', '二', '三', '四', '五', '六'];
    const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    const hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
    const minute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    const second = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
    const dayOfWeek = weekDays[date.getDay()];
    return `${month}月${day}日 星期${dayOfWeek} ${hour}:${minute}:${second}`;
}

export function getTime2() {
    const date = new Date();
    const weekDays = ['日', '一', '二', '三', '四', '五', '六'];
    const year = date.getFullYear();
    const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    const hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
    const minute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    const second = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
    const dayOfWeek = weekDays[date.getDay()];
    return `${year}${month}${day}${hour}${minute}${second}`;
}
export function getTime3() {
    const date = new Date();
    const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();

    return `${month}.${day}`;
}
export function getTime4() {
    const date = new Date();
    const hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
    const minute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    const second = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
    return `${hour}:${minute}:${second}`;
}

export function getDateArray(time) {
    const date = new Date(time);
    const year = date.getFullYear();
    const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    const hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
    const minute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    return [year, month, day, hour, minute];
}

// 正则匹配？后面元素
export function getCaption(url, parameter) {
    let index = url.lastIndexOf(parameter);
    url = url.substring(index + 1, url.length);
    return url;
}

export function getTimeyyyyMMddHHmm() {
    const date = new Date();
    const weekDays = ['日', '一', '二', '三', '四', '五', '六'];
    const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    const dayOfWeek = weekDays[date.getDay()];
    return `${month}月${day}日 星期${dayOfWeek}`;
}

export function getTodayAndTomorrow(days) {
    const date = new Date();
    date.setDate(date.getDate() + days); // 将日期加1，获取明天的日期
    const weekDays = ['日', '一', '二', '三', '四', '五', '六'];
    const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    const dayOfWeek = weekDays[date.getDay()];
    return `${month}月${day}日 星期${dayOfWeek}`;

}

export function getTodayTime() {
    // 获取当前日期的00:00:01
    const startOfToday = new Date();
    startOfToday.setHours(0, 0, 1, 0); // 设置小时、分钟、秒和毫秒
    // 获取当前日期的23:59:59
    const endOfToday = new Date();
    endOfToday.setHours(23, 59, 59, 0); // 设置小时、分钟、秒和毫秒

    return {
        beginTime: startOfToday,
        endTime: endOfToday
    };
}

export function getWeekTime() {
    // 获取当前日期
    const today = new Date();

    // 获取七天前的日期
    const sevenDaysAgo = new Date(today);
    sevenDaysAgo.setDate(today.getDate() - 7);

    // 获取七天前的起始时间（00:00:01）
    const startSevenDaysAgo = new Date(sevenDaysAgo);
    startSevenDaysAgo.setHours(0, 0, 1, 0); // 设置小时、分钟、秒和毫秒

    // 获取今天的结束时间（23:59:59）
    const endOfToday = new Date(today);
    endOfToday.setHours(23, 59, 59, 0); // 设置小时、分钟、秒和毫秒

    return {
        beginTime: startSevenDaysAgo,
        endTime: endOfToday
    };
}
export function get60Days(time) {
    // 获取当前日期
    const today = new Date(time);

    // 获取30天前的日期
    const thirtyDaysBefore = new Date(today);
    thirtyDaysBefore.setDate(today.getDate() - 30);

    // 获取30天前的起始时间（00:00:01）
    const startTime = new Date(thirtyDaysBefore);
    startTime.setHours(0, 0, 1, 0); // 设置小时、分钟、秒和毫秒

    // 获取30天后的日期
    const thirtyDaysLater = new Date(today);
    thirtyDaysLater.setDate(today.getDate() + 30);

    // 获取30天后的结束时间（23:59:59.999）
    const endTime = new Date(thirtyDaysLater);
    endTime.setHours(23, 59, 59, 999); // 设置小时、分钟、秒和毫秒

    return {
        beginTime: startTime,
        endTime: endTime
    };
}
export function calibrationTime(time) {
    const currentTime = new Date().getTime();
    const inputTime = new Date(time).getTime();

    const diffHours = Math.abs((currentTime - inputTime) / (1000 * 60 * 60));
    return diffHours <= 12;
}

export function checkIsToday(time) {
    const current = new Date();
    const input = new Date(time);

    // 将当前时间和输入时间的日期设置为当天的开始，即00:00:00
    current.setHours(0, 0, 0, 0);
    input.setHours(0, 0, 0, 0);

    // 比较年月日，如果相同，则表示是同一天
    return current.getDate() === input.getDate() &&
        current.getMonth() === input.getMonth() &&
        current.getFullYear() === input.getFullYear();
}
export function formatTimestamp(timestamp) {
    const date = new Date(...timestamp);  // 传入时间戳数组
    return date.toISOString().replace('T', ' ').substring(0, 19);  // 转换为常用格式
}