<template>
    <div id="app">
        <div class="game-container">
            <h1>冒险游戏</h1>
            <div class="game-status">
                <p>生命值: {{ player.hp }} | 攻击力: {{ player.attack }} | 等级: {{ player.level }}</p>
                <p>经验: {{ player.experience }} / {{ player.nextLevelExperience }}</p>
                <p>物品: {{ player.inventory.join(", ") || "没有物品" }}</p>
            </div>

            <div class="game-story">
                <p>{{ currentStory.text }}</p>
            </div>

            <div class="game-options">
                <button v-for="(option, index) in currentStory.options"
                        :key="index"
                        @click="chooseOption(option)">
                    {{ option.text }}
                </button>
            </div>

            <div v-if="player.hp <= 0" class="game-over">
                <p>游戏结束！你的角色死亡了。</p>
                <button @click="restartGame">重新开始</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            player: {
                hp: 100,
                attack: 10,
                level: 1,
                experience: 0,
                nextLevelExperience: 100,
                inventory: [],
                statusEffects: [], // 用于存储玩家的状态效果
            },
            currentStory: {
                text: "你站在一个黑暗的森林入口。你可以选择进入森林或回头。",
                options: [
                    {text: "进入森林", nextStory: 'encounter_monster'},
                    {text: "回头走", nextStory: 'exit_forest'}
                ]
            },
            story: {
                encounter_monster: {
                    text: "你遇到了一只怪物！怪物准备攻击你！",
                    options: [
                        {text: "战斗", nextStory: 'fight_monster'},
                        {text: "逃跑", nextStory: 'escape_forest'}
                    ]
                },
                fight_monster: {
                    text: "你选择与怪物战斗！",
                    options: [
                        {text: "攻击", nextStory: 'monster_attacks'},
                        {text: "防御", nextStory: 'defend'}
                    ]
                },
                monster_attacks: {
                    text: "怪物攻击了你，你受到了伤害！",
                    options: [
                        {text: "继续战斗", nextStory: 'fight_monster'},
                        {text: "逃跑", nextStory: 'escape_forest'}
                    ]
                },
                defend: {
                    text: "你成功防御了怪物的攻击！你有机会反击！",
                    options: [
                        {text: "反击", nextStory: 'win_battle'},
                        {text: "逃跑", nextStory: 'escape_forest'}
                    ]
                },
                win_battle: {
                    text: "你击败了怪物！你赢得了战斗！",
                    options: [
                        {text: "继续冒险", nextStory: 'encounter_monster'},
                        {text: "退出游戏", nextStory: 'exit_game'}
                    ]
                },
                escape_forest: {
                    text: "你成功逃脱了森林。",
                    options: [
                        {text: "继续冒险", nextStory: 'encounter_monster'},
                        {text: "退出游戏", nextStory: 'exit_game'}
                    ]
                },
                exit_forest: {
                    text: "你安全地离开了森林。",
                    options: [
                        {text: "退出游戏", nextStory: 'exit_game'}
                    ]
                },
                exit_game: {
                    text: "游戏结束，谢谢游玩！",
                    options: []
                }
            }
        };
    },
    methods: {
        chooseOption(option) {
            const nextStory = this.story[option.nextStory];
            if (nextStory) {
                this.currentStory = nextStory;
            }

            // 处理战斗、逃跑、获得物品等操作
            if (option.nextStory === 'fight_monster') {
                this.fightMonster();
            } else if (option.nextStory === 'escape_forest') {
                this.escapeForest();
            } else if (option.nextStory === 'win_battle') {
                this.winBattle();
            }

            // 检查是否升级
            this.checkLevelUp();
        },

        fightMonster() {
            const monsterAttack = Math.floor(Math.random() * 15) + 5; // 怪物攻击
            const playerDamage = Math.max(this.player.attack - Math.floor(Math.random() * 3), 0); // 玩家伤害

            this.player.hp -= monsterAttack; // 玩家受伤
            this.player.experience += 20; // 获取经验

            // 模拟怪物战斗
            if (this.player.hp <= 0) {
                this.currentStory = this.story.exit_game;
            } else {
                this.currentStory = this.story.monster_attacks;
            }

            // 战斗结果
            this.checkLevelUp();
        },

        escapeForest() {
            this.player.hp += 10; // 逃跑后恢复生命
            this.currentStory = this.story.escape_forest;
        },

        winBattle() {
            this.player.experience += 50; // 战斗胜利后增加经验
            // 给玩家物品奖励
            if (Math.random() > 0.5) {
                this.player.inventory.push("治疗药水");
            } else {
                this.player.inventory.push("强化药水");
            }
            this.currentStory = this.story.win_battle;
        },

        checkLevelUp() {
            if (this.player.experience >= this.player.nextLevelExperience) {
                this.player.level += 1;
                this.player.attack += 5; // 每升一级攻击力增加
                this.player.nextLevelExperience = Math.floor(this.player.nextLevelExperience * 1.5); // 增加下一级的经验要求
                this.player.experience = 0; // 清空经验
                this.currentStory = {
                    text: `恭喜你升级了！当前等级: ${this.player.level}`,
                    options: [
                        {text: "继续冒险", nextStory: 'encounter_monster'},
                        {text: "退出游戏", nextStory: 'exit_game'}
                    ]
                };
            }
        },

        restartGame() {
            this.player = {
                hp: 100,
                attack: 10,
                level: 1,
                experience: 0,
                nextLevelExperience: 100,
                inventory: [],
                statusEffects: [],
            };
            this.currentStory = {
                text: "你站在一个黑暗的森林入口。你可以选择进入森林或回头。",
                options: [
                    {text: "进入森林", nextStory: 'encounter_monster'},
                    {text: "回头走", nextStory: 'exit_forest'}
                ]
            };
        }
    }
};
</script>

<style scoped>
#app {
    font-family: Arial, sans-serif;
    text-align: center;
}

.game-container {
    max-width: 600px;
    margin: 0 auto;
}

.game-status {
    margin: 10px 0;
}

.game-story {
    margin: 20px 0;
}

.game-options button {
    margin: 10px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
}

.game-options button:hover {
    background-color: #f0f0f0;
}

.game-over {
    margin-top: 20px;
    font-size: 18px;
    color: red;
}
</style>
