<template>
    <div class="fun-tools-container">
        <h1>在线工具集</h1>

        <div class="tools-grid">
            <!-- JSON格式化 -->
            <div class="tool-card">
                <h3>JSON格式化</h3>
                <div class="json-formatter">
                    <textarea v-model="jsonInput" placeholder="输入JSON字符串..." class="textarea"></textarea>
                    <div class="btn-group">
                        <button @click="formatJSON" class="btn btn-blue" :disabled="!jsonInput">格式化</button>
                        <button @click="minifyJSON" class="btn btn-green" :disabled="!jsonInput">压缩</button>
                    </div>
                    <pre class="output">{{ jsonOutput || '无效的JSON格式' }}</pre>
                </div>
            </div>

            <!-- AES加解密 -->
            <div class="tool-card">
                <h3>AES加解密</h3>
                <div class="aes-crypto">
                    <input v-model="aesKey" placeholder="输入密钥..." class="crypto-input" :disabled="!aesInput">
                    <textarea v-model="aesInput" placeholder="输入要加密/解密的内容..." class="textarea"></textarea>
                    <div class="btn-group">
                        <button @click="aesEncrypt" class="btn btn-purple" :disabled="!aesKey || !aesInput">加密</button>
                        <button @click="aesDecrypt" class="btn btn-orange" :disabled="!aesKey || !aesInput">解密</button>
                    </div>
                    <textarea v-model="aesOutput" readonly placeholder="结果将在此显示" class="textarea"></textarea>
                </div>
            </div>

            <!-- RSA加解密 -->
            <div class="tool-card">
                <h3>RSA加解密</h3>
                <div class="rsa-crypto">
                    <div class="key-group">
                        <button @click="generateRSAKeys" class="btn btn-blue">生成密钥对</button>
                        <textarea v-model="publicKey" placeholder="公钥" readonly class="textarea"></textarea>
                        <textarea v-model="privateKey" placeholder="私钥" readonly class="textarea"></textarea>
                    </div>
                    <textarea v-model="rsaInput" placeholder="输入要加密/解密的内容..." class="textarea"></textarea>
                    <div class="btn-group">
                        <button @click="rsaEncrypt" class="btn btn-purple" :disabled="!rsaInput || !publicKey">加密</button>
                        <button @click="rsaDecrypt" class="btn btn-orange" :disabled="!rsaInput || !privateKey">解密</button>
                    </div>
                    <textarea v-model="rsaOutput" readonly placeholder="结果将在此显示" class="textarea"></textarea>
                </div>
            </div>

            <!-- MD5加密 -->
            <div class="tool-card">
                <h3>MD5加密</h3>
                <div class="md5-crypto">
                    <textarea v-model="md5Input" placeholder="输入要加密的内容..." class="textarea"></textarea>
                    <button @click="generateMD5" class="btn btn-green" :disabled="!md5Input">生成MD5</button>
                    <input v-model="md5Output" readonly class="crypto-output" placeholder="MD5值将显示在此">
                </div>
            </div>

            <!-- 进制转换 -->
            <div class="tool-card">
                <h3>进制转换</h3>
                <div class="base-conversion">
                    <div class="input-group">
                        <input v-model="inputValue" placeholder="输入数字" class="crypto-input number-input" @input="validateInput"/>
                        <select v-model="inputBase" class="base-select">
                            <option value="2">二进制 (Binary)</option>
                            <option value="8">八进制 (Octal)</option>
                            <option value="10">十进制 (Decimal)</option>
                            <option value="16">十六进制 (Hex)</option>
                            <option value="32">三十二进制 (Base32)</option>
                            <option value="36">三十六进制 (Base36)</option>
                        </select>
                    </div>
                    <div class="btn-group">
                        <button @click="convertBase" class="btn btn-convert" :disabled="!isValidInput">转换</button>
                        <button @click="clearConversion" class="btn btn-clear" :disabled="!convertedValues">清除</button>
                    </div>
                    <div v-if="convertedValues" class="conversion-results">
                        <div class="result-item" v-for="(value, base) in displayResults" :key="base">
                            <span class="base-label">{{ base }}:</span>
                            <span class="base-value">{{ value }}</span>
                            <button class="btn btn-copy" @click="copyToClipboard(value)">复制</button>
                        </div>
                    </div>
                    <div v-if="error" class="error-message">{{ error }}</div>
                </div>
            </div>

            <div class="tool-card">
                <h3>大小写工具</h3>
                <div class="casing-tools">
                    <textarea v-model="casingInput" placeholder="输入内容..." class="textarea"></textarea>
                    <textarea v-model="casingOutput" readonly placeholder="转换后的内容在此显示..." class="textarea"></textarea>
                    <div class="btn-group">
                        <button @click="toUpperCase" class="btn btn-blue" :disabled="!casingInput">转换成大写</button>
                        <button @click="toLowerCase" class="btn btn-green" :disabled="!casingInput">转换成小写</button>
                        <button @click="toTitleCase" class="btn btn-purple" :disabled="!casingInput">单词首字母大写</button>
                        <button @click="toSentenceCase" class="btn btn-orange" :disabled="!casingInput">句子首字母大写</button>
                    </div>
                    <div class="word-count" v-if="casingInput">字数统计: {{ wordCount }} 个字符</div>
                </div>
            </div>

            <!-- 数字大写转换器 -->
            <div class="tool-card">
                <h3>数字大写转换器</h3>
                <div class="uppercase-numbers">
                    <input v-model="numberInput" type="text" class="crypto-input" placeholder="输入数字内容...">
                    <button @click="convertNumber" class="btn btn-blue" :disabled="!numberInput">转换</button>
                    <input v-model="numberOutput" readonly class="crypto-output" placeholder="转换成中文大写">
                </div>
            </div>

            <div class="tool-card">
                <h3>日期格式化</h3>
                <div class="date-formatter">
                    <input v-model="dateInput" type="text" placeholder="输入日期 (例如 2024-12-17)" class="crypto-input">
                    <select v-model="dateFormat" class="base-select">
                        <option value="MM/dd/yyyy">MM/dd/yyyy</option>
                        <option value="yyyy-MM-dd">yyyy-MM-dd</option>
                        <option value="dd MMMM yyyy">dd MMMM yyyy</option>
                        <option value="dd/MM/yyyy">dd/MM/yyyy</option>
                    </select>
                    <button @click="formatDate" class="btn btn-blue" :disabled="!dateInput">格式化</button>
                    <input v-model="formattedDate" readonly class="crypto-output" placeholder="格式化后的日期将显示在此">
                </div>
            </div>

            <!-- 文本去重 -->
            <div class="tool-card">
                <h3>文本去重</h3>
                <div class="text-deduplication">
                    <textarea v-model="textInput" placeholder="输入文本,每行一个..." class="textarea"></textarea>
                    <button @click="removeDuplicates" class="btn btn-blue" :disabled="!textInput">去重</button>
                    <textarea v-model="textOutput" readonly placeholder="去重后的内容将显示在此" class="textarea"></textarea>
                </div>
            </div>

            <!-- 倒计时/计时器 -->
            <div class="tool-card">
                <h3>倒计时/计时器</h3>
                <div class="timer-tool">
                    <input v-model="timerInput" type="number" placeholder="设置倒计时秒数" class="crypto-input">
                    <button @click="startTimer" class="btn btn-blue" :disabled="!timerInput || timerRunning">开始倒计时</button>
                    <button @click="stopTimer" class="btn btn-orange" :disabled="!timerRunning">停止</button>
                    <div v-if="remainingTime >= 0" class="timer-display">
                        <p>剩余时间：{{ remainingTime }} 秒</p>
                    </div>
                    <div v-if="timerDone" class="timer-done-message">
                        <p>倒计时结束！</p>
                    </div>
                </div>
            </div>

            <!-- 文本反转 -->
            <div class="tool-card">
                <h3>文本反转</h3>
                <div class="text-reversal">
                    <textarea v-model="reverseInput" placeholder="输入文本..." class="textarea"></textarea>
                    <button @click="reverseText" class="btn btn-blue" :disabled="!reverseInput">反转文本</button>
                    <textarea v-model="reverseOutput" readonly placeholder="反转后的文本将显示在此" class="textarea"></textarea>
                </div>
            </div>

        </div>
    </div>
</template>

<style scoped>
.fun-tools-container {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

h1 {
    text-align: center;
    color: #333;
}

.tools-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
}

.tool-card {
    background-color: #fff;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
}

.tool-card:hover {
    transform: scale(1.02);
}

.textarea {
    width: 90%;
    height: 100px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: none;
    transition: border-color 0.2s;
}

.textarea:focus {
    border-color: #007bff;
}

.btn-group {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.btn {
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.btn-blue {
    background-color: #007bff;
    color: white;
}

.btn-blue:hover {
    background-color: #0056b3;
}

.btn-green {
    background-color: #28a745;
    color: white;
}

.btn-green:hover {
    background-color: #218838;
}

.btn-purple {
    background-color: #6f42c1;
    color: white;
}

.btn-purple:hover {
    background-color: #5a32a3;
}

.btn-orange {
    background-color: #fd7e14;
    color: white;
}

.btn-orange:hover {
    background-color: #e67e22;
}

.btn-copy {
    background-color: #ffc107;
    color: white;
}

.btn-copy:hover {
    background-color: #e0a800;
}

.crypto-output {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f8f9fa;
}

.error-message {
    color: red;
    margin-top: 10px;
}

.timer-display {
    margin-top: 10px;
    font-weight: bold;
}

.timer-done-message {
    color: green;
    font-weight: bold;
    margin-top: 10px;
}

.word-count {
    margin-top: 10px;
    font-style: italic;
}
</style>

<script>
import {encrypt, decrypt} from '@/utils/AESUtil.js';
import {generateRSAKeys, rsaEncrypt, rsaDecrypt} from '@/utils/RSAUtil.js';
import CryptoJS from 'crypto-js'
import {convertToChineseCurrency} from "@/utils/NumbersUtil";
import dayjs from 'dayjs';

export default {
    name: "idea4",
    data() {
        return {
            // JSON格式化
            jsonInput: '',
            jsonOutput: '',

            // AES加解密
            aesKey: '',
            aesInput: '',
            aesOutput: '',

            // RSA加解密
            publicKey: '',
            privateKey: '',
            rsaInput: '',
            rsaOutput: '',

            // MD5加密
            md5Input: '',
            md5Output: '',

            // 进制转换
            inputValue: '',
            inputBase: '10',
            convertedValues: null,
            error: null,
            isValidInput: false,

            // 大小写转换
            casingInput: '',
            casingOutput: '',

            // 数字大写转换
            numberInput: '',
            numberOutput: '',

            // 日期格式化
            dateInput: '',
            dateFormat: 'yyyy-MM-dd',
            formattedDate: '',

            // 文本去重
            textInput: '',
            textOutput: '',

            // 倒计时
            timerInput: '',
            remainingTime: -1,
            timerDone: false,
            timerRunning: false,
            timerInterval: null,

            // 文本反转
            reverseInput: '',
            reverseOutput: ''
        }
    },
    computed: {
        displayResults() {
            if (!this.convertedValues) return {};
            return {
                '二进制 (Binary)': this.convertedValues.binary,
                '八进制 (Octal)': this.convertedValues.octal,
                '十进制 (Decimal)': this.convertedValues.decimal,
                '十六进制 (Hex)': this.convertedValues.hexadecimal,
                '三十二进制 (Base32)': this.convertedValues.base32,
                '三十六进制 (Base36)': this.convertedValues.base36
            };
        },
        wordCount() {
            if (!this.casingInput) return 0;
            return this.casingInput.length;
        }
    },
    methods: {
        validateInput() {
            this.error = null;
            const base = parseInt(this.inputBase);
            const validChars = this.getValidCharsForBase(base);
            const regex = new RegExp(`^[${validChars}]+$`, 'i');

            if (!this.inputValue) {
                this.isValidInput = false;
                return;
            }

            if (!regex.test(this.inputValue)) {
                this.error = `请输入有效的${base}进制数字`;
                this.isValidInput = false;
                return;
            }

            this.isValidInput = true;
        },

        getValidCharsForBase(base) {
            const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
            return chars.substring(0, base);
        },

        convertBase() {
            try {
                const inputNum = parseInt(this.inputValue, parseInt(this.inputBase));

                if (isNaN(inputNum)) {
                    throw new Error('无效的输入数字');
                }

                this.convertedValues = {
                    binary: inputNum.toString(2),
                    octal: inputNum.toString(8),
                    decimal: inputNum.toString(10),
                    hexadecimal: inputNum.toString(16).toUpperCase(),
                    base32: this.convertToBase32(inputNum),
                    base36: inputNum.toString(36).toUpperCase()
                };
                this.error = null;
            } catch (e) {
                this.error = e.message;
                this.convertedValues = null;
            }
        },

        convertToBase32(num) {
            if (num === 0) return '0';
            const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
            let result = '';
            while (num > 0) {
                result = chars[num % 32] + result;
                num = Math.floor(num / 32);
            }
            return result;
        },

        clearConversion() {
            this.inputValue = '';
            this.convertedValues = null;
            this.error = null;
            this.isValidInput = false;
        },

        async copyToClipboard(text) {
            try {
                await navigator.clipboard.writeText(text);
                this.$message.success('复制成功');
            } catch (err) {
                this.$message.error('复制失败');
                console.error('复制失败:', err);
            }
        },

        // JSON格式化
        formatJSON() {
            try {
                const parsed = JSON.parse(this.jsonInput);
                this.jsonOutput = JSON.stringify(parsed, null, 2);
            } catch (e) {
                this.jsonOutput = '无效的JSON格式';
            }
        },
        minifyJSON() {
            try {
                const parsed = JSON.parse(this.jsonInput);
                this.jsonOutput = JSON.stringify(parsed);
            } catch (e) {
                this.jsonOutput = '无效的JSON格式';
            }
        },

        // AES加密
        aesEncrypt() {
            try {
                this.aesOutput = encrypt(this.aesInput, this.aesKey);
            } catch (e) {
                this.aesOutput = '加密失败: ' + e.message;
            }
        },
        aesDecrypt() {
            try {
                this.aesOutput = decrypt(this.aesInput, this.aesKey);
            } catch (e) {
                this.aesOutput = '解密失败: ' + e.message;
            }
        },

        // 生成RSA密钥对
        generateRSAKeys() {
            const {privateKey, publicKey} = generateRSAKeys();
            this.privateKey = privateKey;
            this.publicKey = publicKey;
        },
        // RSA加密
        rsaEncrypt() {
            try {
                this.rsaOutput = rsaEncrypt(this.publicKey, this.rsaInput);
            } catch (e) {
                this.rsaOutput = '加密失败: ' + e.message;
            }
        },
        // RSA解密
        rsaDecrypt() {
            try {
                this.rsaOutput = rsaDecrypt(this.privateKey, this.rsaInput);
            } catch (e) {
                this.rsaOutput = '解密失败: ' + e.message;
            }
        },

        // MD5加密
        generateMD5() {
            this.md5Output = CryptoJS.MD5(this.md5Input).toString();
        },

        // 大小写转换方法
        toUpperCase() {
            this.casingOutput = this.casingInput.toUpperCase();
        },
        toLowerCase() {
            this.casingOutput = this.casingInput.toLowerCase();
        },
        toTitleCase() {
            this.casingOutput = this.casingInput
                .toLowerCase()
                .split(' ')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');
        },
        toSentenceCase() {
            this.casingOutput = this.casingInput
                .toLowerCase()
                .replace(/(^\w|\.\s+\w)/g, letter => letter.toUpperCase());
        },

        // 数字转中文大写
        convertNumber() {
            this.numberOutput = convertToChineseCurrency(this.numberInput);
        },

        // 日期格式化
        formatDate() {
            try {
                const date = dayjs(this.dateInput);
                if (!date.isValid()) {
                    throw new Error('无效的日期格式');
                }
                this.formattedDate = date.format(this.dateFormat.replace(/y/g, 'Y'));
            } catch (e) {
                this.formattedDate = '无效的日期格式';
            }
        },

        // 文本去重
        removeDuplicates() {
            const lines = this.textInput.split('\n');
            const uniqueLines = [...new Set(lines)];
            this.textOutput = uniqueLines.join('\n');
        },

        // 倒计时
        startTimer() {
            if (this.timerRunning) return;
            
            this.remainingTime = parseInt(this.timerInput);
            this.timerDone = false;
            this.timerRunning = true;
            
            this.timerInterval = setInterval(() => {
                if (this.remainingTime > 0) {
                    this.remainingTime--;
                } else {
                    this.timerDone = true;
                    this.timerRunning = false;
                    clearInterval(this.timerInterval);
                }
            }, 1000);
        },

        stopTimer() {
            if (this.timerInterval) {
                clearInterval(this.timerInterval);
                this.timerRunning = false;
                this.remainingTime = -1;
                this.timerDone = false;
            }
        },

        // 文本反转
        reverseText() {
            this.reverseOutput = this.reverseInput.split('').reverse().join('');
        }
    },
    beforeDestroy() {
        if (this.timerInterval) {
            clearInterval(this.timerInterval);
        }
    }
}
</script>
