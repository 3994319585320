import CryptoJS from 'crypto-js';


/**
 * 加密 --api
 * @param word string
 * @returns {*} string
 */
export function generatePassword(password) {
    if (password == null) {
        password = ''
    }
    const neededLength = 32 - password.length;
    if (neededLength <= 0) {
        return password;
    }
    const paddingArray = new Array(neededLength).fill('a').map(() => 'a');
    return password + paddingArray.join('');
}

export function encrypt(content, key) {
    key = generatePassword(key);
    const secretKey = CryptoJS.enc.Utf8.parse(key);
    const srcs = CryptoJS.enc.Utf8.parse(content);
    const encrypted = CryptoJS.AES.encrypt(srcs, secretKey, {mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7});
    return encrypted.toString();
}

/**
 * 解密 --api
 * @param word string
 * @returns {*} string
 */
export function decrypt(content, key) {
    key = generatePassword(key);
    const secretKey = CryptoJS.enc.Utf8.parse(key);
    const decrypt = CryptoJS.AES.decrypt(content, secretKey, {mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7});
    return CryptoJS.enc.Utf8.stringify(decrypt).toString();
}
export function decrypt2(content, key) {
    key = generatePassword(key);
    const secretKey = CryptoJS.enc.Utf8.parse(key);
    const parsedContent = CryptoJS.enc.Base64.parse(content);
    const decrypted = CryptoJS.AES.decrypt(parsedContent, secretKey, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });
    const decryptedText = CryptoJS.enc.Utf8.stringify(decrypted);
    if (!decryptedText) {
        return decrypted.toString(CryptoJS.enc.Base64); // 返回 Base64 编码的二进制数据
    }
    return decryptedText;
}
