<template>
    <div class="todo-container">
        <quit v-if="!isAdding"></quit>
        <div class="create-button" @click="isAdding=true" v-if="!isAdding">+</div>
        <div class="image-repeater"></div>
        <div class="title">TO DO LIST</div>
        <div class="search">
            <div>
                <label for="status">状态：</label>
                <select id="status" name="status" v-model="status" @blur="handleInputEnd">
                    <option value=''>全部</option>
                    <option value=0>待完成</option>
                    <option value=1>已完成</option>
                </select>
            </div>
            <div>作者: <input type="text" v-model="author" @blur="handleInputEnd" placeholder="作者"></div>
            <div>内容: <input type="text" v-model="info" @blur="handleInputEnd" placeholder="内容"></div>
        </div>
        <div class="toDoList" v-if="!isAdding">
            <div v-for="item in toDoList" :key="item.id" class="todoInfo">
                <div class="checkBox" @click="finish(item)">{{ item.status === 1 ? '✔️' : '' }}</div>
                <div v-if="!itemEditing[item.id]" @click="startEdit(item)">
                    {{ item.info }}
                </div>
                <textarea v-else type="text" v-model="item.info" @blur="stopEdit(item)"
                          @keyup.enter="stopEdit(item)" class="textareaPlace"></textarea>
                <div class="todo-meta">
                    <div class="meta-info">
                        <span> {{ item.author }}</span>
                        <span> {{ parseTime3(item.createTime) }}</span>
                    </div>
                </div>
            </div>
        </div>
        <transition name="gta-slide">
            <div class="addPage" v-if="isAdding">
                <div class="addPageLine"></div>
                <div class="addPageConfirm" @click="create()">
                    创建✔︎
                </div>
                <div class="addPageCancel" @click="cancel()">
                    取消✘
                </div>
                <div>
                    <textarea placeholder="点此输入内容" class="addPageInfo" rows="15"
                              v-model="toDoThing.info"></textarea>
                    <input placeholder="作者" class="addPageAuthor" v-model="toDoThing.author">
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import Back from "@/components/back.vue";
import {createToDoThing, getToDoList, updateToDos} from "@/api/yunoApi";
import {parseTime3} from "@/utils/parse";
import Quit from "@/components/quit.vue";

export default {
    name: "todoList",
    components: {Quit, Back},
    data() {
        return {
            toDoList: [],
            pageNum: 1,
            totalCount: 0,
            author: null,
            status: 0,
            info: null,
            isFetching: false,
            itemEditing: {},
            isAdding: false,
            toDoThing: {info: '', author: ''}
        }
    },
    methods: {
        create() {
            if (this.toDoThing.info && this.toDoThing.author) {
                createToDoThing(this.toDoThing).then(() => {
                    this.clean();
                    this.pageNum = 1;
                    this.getToDoList();
                });
            } else {
                this.isAdding = false;
            }
        },
        cancel() {
            this.isAdding = false;
            this.clean();
        },
        clean() {
            this.toDoThing = {info: '', author: this.toDoThing.author};
        },
        handleInputEnd() {
            this.getToDoList();
        },
        parseTime3,
        async getToDoList() {
            const todos = { pageNum: this.pageNum };
            const addTodoParam = (key, value) => {
                if (value) {
                    todos[key] = value;
                }
            };
            addTodoParam('author', this.author);
            addTodoParam('status', this.status);
            addTodoParam('info', this.info);
            getToDoList(todos).then(res => {
                this.toDoList = res.list;
                this.totalCount = res.totalCount;
            });
        },
        handleScroll() {
            if (this.isFetching) return;
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            const scrollHeight = document.documentElement.scrollHeight;
            const clientHeight = document.documentElement.clientHeight;
            if (scrollTop + clientHeight >= scrollHeight - 30) {
                if (this.toDoList.length < this.totalCount) {
                    this.pageNum++;
                    this.fetchLineUpList();
                }
            }
        },
        fetchLineUpList() {
            this.isFetching = true;
            const todos = { pageNum: this.pageNum };
            const addTodoParam = (key, value) => {
                if (value) {
                    todos[key] = value;
                }
            };
            addTodoParam('author', this.author);
            addTodoParam('status', this.status);
            addTodoParam('info', this.info);
            getToDoList(todos).then(data => {
                this.toDoList = [...this.toDoList, ...data.list];
                this.isFetching = false;
            });
        },
        finish(item) {
            item.status = 1;
            const todos = { id: item.id, status: 1 };
            updateToDos(todos).then(() => {
                const index = this.toDoList.findIndex(todo => todo.id === item.id);
                if (index !== -1) {
                    this.toDoList.splice(index, 1);
                }
            });
        },
        startEdit(item) {
            if (item.status === 0) {
                this.$set(this.itemEditing, item.id, true);
            } else {
                alert("已完成，不可修改");
            }
        },
        stopEdit(item) {
            this.$set(this.itemEditing, item.id, false);
            this.editToDoThing(item);
        },
        editToDoThing(item) {
            const todos = { id: item.id, info: item.info };
            updateToDos(todos).then();
        },
    },
    mounted() {
        this.getToDoList();
    },
    created() {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    }
}
</script>

<style scoped>
.todo-container {
    background-color: #f3f3f3;
    height: 101vh;
    margin: -10px -5px -5px -5px;
    padding: 0 40px; /* Added padding for left and right space */
}

.image-repeater {
    width: 100%;
    height: 60px;
    background-image: url('@/assets/img/Sheet.png');
    background-repeat: repeat-x;
    background-size: auto 100%;
    background-position: center;
}
@font-face {
    font-family: 'Festive';
    src: url('@/assets/ttf/STONB.TTF') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
.title {
    font-family: "Festive";
    font-size: 45px;
    text-align: center; /* Centered title */
}

.search {
    justify-content: space-between;
    display: flex;
    margin: 0 10px; /* Added margin for search area */
}

select {
    color: black;
    padding: 4px;
    border: 1px solid #f3f3f3;
    background-color: #f3f3f3;
    border-radius: 4px;
    font-size: 16px;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

input {
    margin-top: 3px;
    font-size: 16px;
    height: 20px;
    width: 60px;
    border: 1px solid #f3f3f3;
    background-color: #f3f3f3;
}
select::-ms-expand {
    display: none;
}
.toDoList {
    background-color: #f3f3f3;
    margin-top: 20px;
    border: 1px dashed black;
    padding: 10px; /* Added padding for to-do list */
}

.todoInfo {
    border-bottom: black 1px solid;
    margin-top: 10px;
    padding: 10px; /* Added padding for each todo item */
}

.textareaPlace {
    font-size: 16px;
}

.checkBox {
    cursor: pointer;
    font-size: 35px;
    height: 30px;
    border: 3px solid black;
    width: 30px;
    position: absolute;
}

.create-button {
    background-color: #05cb00;
    color: white;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    position: fixed;
    bottom: 50px;
    right: 20px;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
    font-size: 24px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.create-button:hover {
    background-color: #11de0d;
}

.addPage {
    width: 97%;
    margin: 0 1%;
    background-color: rgba(162, 162, 162, 0.67);
    position: fixed;
    min-height: 80vh;
    margin-top: 20vw;
    border-radius: 40px 40px 0 0;
}

.addPageLine {
    border-radius: 15px;
    margin: 10px auto;
    background-color: #f3f3f3;
    width: 30%;
    height: 8px;
}

.addPageConfirm, .addPageCancel {
    position: absolute;
    margin-top: -20px;
    font-size: 20px;
    font-weight: bolder;
}

.addPageConfirm {
    cursor: pointer;
    right: 40px;
    color: rgba(0, 129, 0);
}

.addPageCancel {
    cursor: pointer;
    left: 40px;
    color: rgba(255, 77, 77);
}

.addPageInfo {
    margin-top: 20px;
    background-color: rgba(241, 240, 240, 0.5);
    width: 90%;
    font-size: 18px;
    color: #000000;
    font-weight: bolder;
}

.gta-slide-enter-active,
.gta-slide-leave-active {
    transition: transform 0.5s ease;
}

.gta-slide-enter,
.gta-slide-leave-to {
    transform: translateY(100%);
}

.todo-meta {
    display: flex;
    justify-content: right;
    align-items: flex-end;
}

.meta-info {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 5px;
}
</style>