<template>
    <div class="ancient-scroll">
        <back></back>
        <div class="scroll-header">
            <div class="dragon-left"></div>
            <h1 class="scroll-title">王氏祖训</h1>
            <div class="dragon-right"></div>
        </div>

        <div class="scroll-content">
            <div class="scroll-decoration top"></div>
            
            <transition-group name="fade" tag="div" class="wisdom-list">
                <div v-for="motto in mottos" 
                     :key="motto.id"
                     class="wisdom-item">
                    <div class="wisdom-text">
                        <div class="ink-drops"></div>
                        <div class="calligraphy">{{ motto.info }}</div>
                        <div class="seal-mark"></div>
                    </div>
                    <div class="chinese-patterns left"></div>
                    <div class="chinese-patterns right"></div>
                </div>
            </transition-group>

            <div class="add-wisdom">
                <div class="brush-container">
                    <div class="ink-brush"></div>
                    <input 
                        type="text"
                        v-model="addMottoInfo"
                        @keyup.enter="addMotto"
                        placeholder="留下王氏箴言..."
                        class="wisdom-input"
                        :class="{'writing': isFocused}"
                        @focus="isFocused = true"
                        @blur="handleBlur"
                    />
                </div>
                <div class="ink-splash" :class="{'active': isFocused}"></div>
            </div>

            <div class="scroll-decoration bottom"></div>
        </div>

        <div v-if="isFetching" class="loading-container">
            <div class="loading-scroll">
                <div class="scroll-roller"></div>
                <div class="loading-text">加载智慧...</div>
            </div>
        </div>

        <div class="scroll-footer">
            <div class="chinese-border"></div>
            <div class="signature">
                <span class="signature-text">传承智慧 · 启迪后人</span>
                <div class="red-seal"></div>
            </div>
        </div>
    </div>
</template>

<script>
import {addMotto, getFamilyMotto} from "@/api/yunoApi";
import Back from "@/components/back.vue";

export default {
    name: "familyMotto",
    components: {Back},
    data() {
        return {
            mottos: [],
            pageNum: 1,
            totalCount: 1,
            isFetching: false,
            addMottoInfo: '',
            isFocused: false
        };
    },
    methods: {
        handleBlur() {
            this.isFocused = false;
            if(this.addMottoInfo.trim()) {
                this.addMotto();
            }
        },
        handleScroll() {
            const scrollEl = document.documentElement;
            if (this.isFetching) return;
            
            if (scrollEl.scrollTop + window.innerHeight >= scrollEl.scrollHeight - 100) {
                this.pageNum++;
                this.getMotto();
            }
        },
        getMotto() {
            if (this.isFetching || this.mottos.length >= this.totalCount) return;
            this.isFetching = true;
            getFamilyMotto(this.pageNum)
                .then(res => {
                    this.mottos = [...this.mottos, ...res.list];
                    this.totalCount = res.totalCount;
                })
                .catch(err => console.error('获取失败:', err))
                .finally(() => this.isFetching = false);
        },
        addMotto() {
            if (!this.addMottoInfo.trim()) return;

            this.isFetching = true;
            addMotto({info: this.addMottoInfo})
                .then(() => {
                    this.mottos = [];
                    this.addMottoInfo = '';
                    this.pageNum = 1;
                    return getFamilyMotto(1);
                })
                .then(res => {
                    this.mottos = res.list;
                    this.totalCount = res.totalCount;
                })
                .catch(err => console.error('添加失败:', err))
                .finally(() => this.isFetching = false);
        }
    },
    mounted() {
        this.getMotto();
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    }
}
</script>

<style scoped>
.ancient-scroll {
    min-height: 100vh;
    background: #f5e6d3;
    background-image: 
        url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100"><rect width="100" height="100" fill="none" stroke="rgba(139,69,19,0.1)" stroke-width="2"/><text x="50" y="50" font-size="40" text-anchor="middle" dy=".35em" fill="rgba(139,69,19,0.05)">王</text></svg>');
    background-size: 100px 100px;
    padding: 2rem;
    font-family: "楷体", "KaiTi", serif;
    position: relative;
}

.scroll-header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3rem;
    position: relative;
}

.dragon-left, .dragon-right {
    width: 120px;
    height: 80px;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    opacity: 0.8;
    transition: all 0.5s ease;
}

.dragon-left:hover, .dragon-right:hover {
    opacity: 1;
    transform: scale(1.2);
    filter: drop-shadow(0 0 10px rgba(139,69,19,0.3));
}

.dragon-left {
    left: 0;
    transform: scaleX(-1);
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 50"><path d="M10,25 C20,15 30,20 40,25 S60,30 70,25 S90,20 95,25" stroke="rgb(160,82,45)" fill="none" stroke-width="3"/></svg>');
}

.dragon-right {
    right: 0;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 50"><path d="M10,25 C20,15 30,20 40,25 S60,30 70,25 S90,20 95,25" stroke="rgb(160,82,45)" fill="none" stroke-width="3"/></svg>');
}

.scroll-title {
    font-size: 3.5rem;
    color: #8B4513;
    text-shadow: 3px 3px 6px rgba(0,0,0,0.2);
    margin: 0 60px;
    writing-mode: vertical-lr;
    letter-spacing: 0.8em;
    background: linear-gradient(to bottom, #8B4513, #A0522D);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: titleGlow 3s ease-in-out infinite alternate;
}

@keyframes titleGlow {
    from {
        text-shadow: 0 0 10px rgba(139,69,19,0.5);
    }
    to {
        text-shadow: 0 0 20px rgba(139,69,19,0.8), 0 0 30px rgba(139,69,19,0.4);
    }
}

.wisdom-list {
    max-width: 900px;
    margin: 0 auto;
    position: relative;
}

.wisdom-item {
    background: rgba(255,250,245,0.95);
    margin: 2.5rem 0;
    padding: 2.5rem;
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(139,69,19,0.15);
    position: relative;
    transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1);
    border: 2px solid rgba(139,69,19,0.1);
    backdrop-filter: blur(10px);
}

.wisdom-item:hover {
    transform: translateY(-12px) rotate(0.8deg);
    box-shadow: 0 20px 40px rgba(139,69,19,0.25);
    background: rgba(255,250,245,1);
}

.wisdom-text {
    position: relative;
    padding: 1.5rem;
    background: rgba(255,255,255,0.5);
    border-radius: 10px;
}

.calligraphy {
    font-size: 1.8rem;
    line-height: 2;
    color: #4a3728;
    text-align: center;
    position: relative;
    z-index: 1;
    transition: all 0.4s ease;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.1);
}

.wisdom-item:hover .calligraphy {
    transform: scale(1.05);
    color: #2c1810;
}

.ink-drops {
    position: absolute;
    top: -15px;
    right: -15px;
    width: 60px;
    height: 60px;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><circle cx="50" cy="50" r="45" fill="rgba(139,69,19,0.1)"/></svg>');
    background-size: contain;
    transform: rotate(-15deg);
    transition: transform 0.4s ease;
}

.wisdom-item:hover .ink-drops {
    transform: rotate(-30deg) scale(1.2);
}

.seal-mark {
    position: absolute;
    bottom: -25px;
    right: -25px;
    width: 70px;
    height: 70px;
    background: rgba(255,0,0,0.1);
    border-radius: 50%;
    transform: rotate(15deg);
    transition: all 0.4s ease;
}

.wisdom-item:hover .seal-mark {
    transform: rotate(30deg) scale(1.2);
    background: rgba(255,0,0,0.15);
}

.chinese-patterns {
    position: absolute;
    width: 40px;
    height: 100%;
    top: 0;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 100"><path d="M10,10 L10,90 M5,50 L15,50" stroke="rgba(139,69,19,0.2)" stroke-width="1.5" stroke-dasharray="8,8"/></svg>');
    background-repeat: repeat-y;
    opacity: 0.7;
    transition: opacity 0.4s ease;
}

.wisdom-item:hover .chinese-patterns {
    opacity: 1;
}

.chinese-patterns.left {
    left: 10px;
}

.chinese-patterns.right {
    right: 10px;
}

.add-wisdom {
    max-width: 700px;
    margin: 4rem auto;
    position: relative;
}

.brush-container {
    position: relative;
    margin-bottom: 2.5rem;
}

.ink-brush {
    position: absolute;
    top: -35px;
    left: -45px;
    width: 35px;
    height: 90px;
    background: linear-gradient(to bottom, #1a1a1a, #333333);
    transform: rotate(-45deg);
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    border-radius: 3px;
    box-shadow: 2px 2px 5px rgba(0,0,0,0.2);
}

.wisdom-input {
    width: 100%;
    padding: 1.5rem;
    background: rgba(255,255,255,0.95);
    border: none;
    border-bottom: 3px solid #8B4513;
    font-size: 1.4rem;
    font-family: inherit;
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    border-radius: 12px;
    backdrop-filter: blur(10px);
    box-shadow: 0 5px 15px rgba(139,69,19,0.1);
}

.wisdom-input:focus {
    outline: none;
    background: #fff;
    box-shadow: 0 10px 35px rgba(139,69,19,0.2);
    border-bottom-width: 4px;
}

.writing .ink-brush {
    transform: rotate(-35deg) translateY(-15px);
    background: linear-gradient(to bottom, #000000, #1a1a1a);
}

.ink-splash {
    position: absolute;
    bottom: -25px;
    right: -25px;
    width: 50px;
    height: 50px;
    background: rgba(139,69,19,0.1);
    border-radius: 50%;
    transform: scale(0);
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.ink-splash.active {
    transform: scale(1.2) rotate(-20deg);
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 4rem 0;
    min-height: 120px;
}

.loading-scroll {
    position: relative;
    width: 70px;
    height: 70px;
}

.scroll-roller {
    width: 100%;
    height: 100%;
    border: 5px solid rgba(139,69,19,0.15);
    border-top-color: #8B4513;
    border-radius: 50%;
    animation: spin 1.5s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.loading-text {
    position: absolute;
    width: 150px;
    text-align: center;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 1.5rem;
    color: #8B4513;
    font-size: 1.1rem;
    animation: pulse 2s ease-in-out infinite;
}

.scroll-footer {
    text-align: center;
    margin-top: 5rem;
    padding: 2.5rem;
    position: relative;
}

.chinese-border {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 250px;
    height: 3px;
    background: rgba(139,69,19,0.2);
}

.signature {
    position: relative;
    display: inline-block;
    padding: 1.5rem 3.5rem;
}

.signature-text {
    font-size: 1.3rem;
    color: #8B4513;
    letter-spacing: 0.3em;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.1);
}

.red-seal {
    position: absolute;
    right: -25px;
    bottom: -15px;
    width: 50px;
    height: 50px;
    background: rgba(255,0,0,0.1);
    border-radius: 8px;
    transform: rotate(15deg);
    transition: transform 0.4s ease;
}

.signature:hover .red-seal {
    transform: rotate(30deg) scale(1.2);
}

@keyframes spin {
    to { transform: rotate(360deg); }
}

@keyframes pulse {
    50% { opacity: 0.6; }
}

.fade-enter-active, .fade-leave-active {
    transition: all 1s cubic-bezier(0.4, 0, 0.2, 1);
}

.fade-enter, .fade-leave-to {
    opacity: 0;
    transform: translateY(50px) scale(0.95);
}

@media (max-width: 480px) {
    .ancient-scroll {
        padding: 1rem;
    }

    .scroll-title {
        font-size: 2.5rem;
    }

    .wisdom-item {
        padding: 1.5rem;
        margin: 2rem 0;
    }

    .calligraphy {
        font-size: 1.4rem;
    }

    .chinese-patterns {
        width: 20px;
    }

    .ink-brush {
        transform: scale(0.8);
    }
}

@media (min-width: 1200px) {
    .wisdom-item {
        transition: all 0.7s cubic-bezier(0.4, 0, 0.2, 1);
    }

    .wisdom-item:hover {
        transform: translateY(-15px) rotate(1deg);
    }
}

@media (prefers-reduced-motion: reduce) {
    .wisdom-item,
    .fade-enter-active,
    .fade-leave-active,
    .scroll-roller,
    .loading-text {
        animation: none;
        transition: opacity 0.4s ease-in-out;
    }
}
</style>