<template>
<!--    使用方式，复制下面这段 -->
<!--    <Notification-->
<!--        :message="notificationMessage"-->
<!--        :visible.sync="isNotificationVisible"-->
<!--        :type="notificationType"-->
<!--    />-->
    <div
        v-if="visible"
        :class="['notification', notificationClass, animationClass]"
        @transitionend="handleTransitionEnd"
    >
        <div class="notification-content">
            {{ message }}
        </div>
    </div>
</template>

<script>
export default {
    name: "Notification",
    props: {
        message: {
            type: String,
            required: true,
        },
        visible: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: 'info',
        },
    },
    data() {
        return {
            animationClass: '',
        };
    },
    computed: {
        notificationClass() {
            return `notification-${this.type}`;
        }
    },
    watch: {
        visible(newVal) {
            if (newVal) {
                this.animationClass = "slide-in"; // Changed to slide-in
                setTimeout(() => {
                    this.animationClass = "fade-out-up"; // Changed to fade-out-up
                }, 3000); // Increased duration for fade-in
            }
        },
    },
    methods: {
        handleTransitionEnd(event) {
            if (event.propertyName === "opacity" && this.animationClass === "fade-out-up") {
                this.$emit("update:visible", false);
            }
        },
    },
};
</script>

<style scoped>
.notification {
    position: fixed;
    top: 20px;
    right: -300px; /* Start off-screen */
    color: white;
    padding: 15px 25px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    opacity: 0;
    transition: right 0.5s ease-in-out, opacity 0.5s ease-in-out, transform 0.5s ease-in-out; /* Added transition for right and transform */
    z-index: 9999;
    font-size: 16px;
    font-family: 'Arial', sans-serif;
}

/* 动画效果 */
.notification.slide-in {
    right: 20px; /* Slide in to the visible position */
    opacity: 1;
}

.notification.fade-out-up {
    opacity: 0;
    transform: translateY(-20px); /* Move up while fading out */
}

/* 不同类型的通知样式 */
.notification-success {
    background-color: #4CAF50; /* 绿色 */
    border-left: 5px solid #388E3C; /* 深绿色边框 */
}

.notification-error {
    background-color: #ff2310; /* 红色 */
    border-left: 5px solid #D32F2F; /* 深红色边框 */
}

.notification-info {
    background-color: #2196F3; /* 蓝色 */
    border-left: 5px solid #1976D2; /* 深蓝色边框 */
}

.notification-warning {
    background-color: #FF9800; /* 橙色 */
    border-left: 5px solid #F57C00; /* 深橙色边框 */
}

/* 内容样式 */
.notification-content {
    font-size: 16px;
    line-height: 24px;
}
</style>
