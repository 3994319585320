<template>
    <div>
        <!-- 返回上一级按钮 -->
        <div v-if="currentPath !== '/project'" class="back-btn" @click="goBack">
            <i class="fa fa-arrow-left"></i>返回上一级
        </div>
        <back v-if="currentPath === '/project'"></back>
        <!-- 当前路径显示 -->
        <div class="path-container">
            <span v-for="(part, index) in pathParts" :key="index" class="path-item" @click="navigateToPath(index)">
                <i class="fa fa-folder"></i> {{ part }}
                <span v-if="index < pathParts.length - 1"> / </span> <!-- 分隔符 -->
            </span>
        </div>

        <h2>文件目录</h2>
        <ul class="folder-list">
            <li v-for="(item, index) in fileItems" :key="index" @click="handleItemClick(item)" class="folder-item">
    <i :class="getIconClass(item)"></i> {{ item.name }}
</li>
        </ul>
    </div>
</template>

<script>
import { getFileDetails } from "@/api/yunoApi";
import Back from "@/components/back.vue"; // 合并后的API调用

export default {
    components: {Back},
    data() {
        return {
            fileItems: [], // 存储文件夹和文件的列表
            currentPath: '/project', // 根目录路径
        };
    },
    computed: {
        // 计算当前路径的各个部分
        pathParts() {
            return this.currentPath.split('/').filter(part => part); // 分割路径并过滤空值
        }
    },
    mounted() {
        this.getFileDetails(); // 页面加载时获取文件夹和文件信息
    },
    methods: {
        // 获取文件夹和文件列表
        getFileDetails() {
            getFileDetails(this.currentPath).then((res) => {
                this.fileItems = [];
                res.forEach(v => {
                    if (v.type === 'file'){
                        v.downloadUrl = 'https://www.yunox.cn/'+v.path.replace('/project','base')
                    }
                    this.fileItems.push(v);
                });
            });
        },
        // 跳转到子文件夹
        goToFolder(item) {
            if (item.type === 'folder') {
                this.currentPath = item.path;
                this.getFileDetails(); // 刷新当前目录的文件夹和文件
            }
        },
        // 返回上一级目录
        goBack() {
            const parentPath = this.currentPath.substring(0, this.currentPath.lastIndexOf('/'));
            this.currentPath = parentPath || '/project'; // 如果根目录就不再跳转
            this.getFileDetails(); // 刷新上级目录的文件夹和文件
        },
        // 跳转到选定的路径
        navigateToPath(index) {
            const newPath = '/' + this.pathParts.slice(0, index + 1).join('/');
            this.currentPath = newPath;
            this.getFileDetails(); // 刷新路径下的文件夹和文件
        },
        // 处理文件夹和文件点击
        handleItemClick(item) {
            if (item.type === 'folder') {
                this.goToFolder(item); // 如果是文件夹，跳转到该文件夹
            } else {
                this.downloadFile(item); // 如果是文件，下载文件
            }
        },
        // 文件下载
        downloadFile(item) {
            console.log(item)
            // 获取文件的下载链接，这个链接可以是后端提供的接口地址
            const downloadUrl = item.downloadUrl; // 假设 item 对象中有一个 downloadUrl 字段存储文件的下载地址

            if (downloadUrl) {
                // 创建一个临时的 <a> 标签来触发下载
                const a = document.createElement('a');
                a.href = downloadUrl;
                a.download = item.name; // 设置下载的文件名
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            } else {
                alert("文件下载链接不可用");
            }
        },
        // 获取文件图标类
        getIconClass(item) {
            const extension = item.name.split('.').pop().toLowerCase();
            switch (extension) {
                case 'png':
                case 'jpg':
                case 'jpeg':
                case 'svg':
                    return 'fa fa-file-image'; // 图片图标
                case 'zip':
                    return 'fa fa-file-archive'; // 压缩包图标
                case 'pdf':
                    return 'fa fa-file-pdf'; // PDF图标
                default:
                    return item.type === 'folder' ? 'fa fa-folder' : 'fa fa-file'; // 默认文件夹或文件图标
            }
        }
    },
};
</script>

<style scoped>
/* 样式优化 */
ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

h2 {
    font-size: 26px; /* 增加标题字体大小 */
    margin-bottom: 15px; /* 增加底部间距 */
    color: #333; /* 更改标题颜色 */
}

.folder-list {
    padding-left: 25px; /* 增加左侧填充 */
}

.folder-item {
    cursor: pointer;
    padding: 10px 0; /* 增加上下填充 */
    color: #444; /* 更改文件项颜色 */
    display: flex;
    align-items: center;
    transition: background-color 0.3s, transform 0.2s; /* 添加过渡效果 */
}

.folder-item:hover {
    color: #fff; /* 悬停时更改字体颜色 */
    background-color: rgba(93, 137, 204, 0.47); /* 悬停时更改背景颜色 */
    border-radius: 4px;
    transform: scale(1.02); /* 悬停时轻微放大 */
}

/* 图标样式 */
.folder-item i {
    margin-right: 12px; /* 增加图标右侧间距 */
    font-size: 22px; /* 增加图标大小 */
}

/* 文件夹图标颜色 */
.folder-item i.fa-folder {
    color: #f39c12;
}

/* 文件图标颜色 */
.folder-item i.fa-file {
    color: #3498db;
}

/* 返回上一级按钮 */
.back-btn {
    font-size: 18px; /* 增加字体大小 */
    margin-bottom: 25px; /* 增加底部间距 */
    color: #b20015;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: color 0.3s; /* 添加过渡效果 */
}

.back-btn i {
    margin-right: 10px; /* 增加图标右侧间距 */
}

.back-btn:hover {
    text-decoration: underline;
    color: #ff001c; /* 悬停时更改颜色 */
}

/* 路径显示样式 */
.path-container {
    margin-bottom: 20px; /* 增加底部间距 */
    font-size: 18px; /* 增加字体大小 */
    color: #555;
}

.path-item {
    cursor: pointer;
    color: #130710;
    display: inline-flex;
    align-items: center;
    padding: 6px; /* 增加填充 */
    border-radius: 4px;
    transition: background-color 0.3s ease, color 0.3s; /* 添加过渡效果 */
}

.path-item:hover {
    background-color: #e9ecef; /* 悬停时更改背景颜色 */
    color: #0056b3; /* 悬停时更改字体颜色 */
}

.path-item i {
    margin-right: 6px; /* 增加图标右侧间距 */
    font-size: 18px; /* 增加图标大小 */
}

/* 文件夹路径图标 */
.path-item i.fa-folder {
    color: #f39c12;
}

/* 响应式 */
@media (max-width: 768px) {
    h2 {
        font-size: 22px; /* 调整小屏幕标题大小 */
    }

    .folder-item {
        font-size: 18px; /* 调整小屏幕文件项大小 */
    }
}
</style>