import { render, staticRenderFns } from "./simpleGame.vue?vue&type=template&id=02dc1298&scoped=true"
import script from "./simpleGame.vue?vue&type=script&lang=js"
export * from "./simpleGame.vue?vue&type=script&lang=js"
import style0 from "./simpleGame.vue?vue&type=style&index=0&id=02dc1298&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02dc1298",
  null
  
)

export default component.exports