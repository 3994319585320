<template>
    <div class="family-motto-list">
        <back></back>
        <h1 class="title-animate">记住那天关于光阴的教训   回头走天已暗</h1>
        <ul>
            <li v-for="motto in mottos"
                :key="motto.id"
                class="motto-item"
                :class="{'fade-in': true}">
                {{ motto.info }}
            </li>
        </ul>
        <input
            type="text"
            class="motto-input"
            placeholder="..."
            v-model="addMottoInfo"
            @keyup.enter="addMotto()"
            @blur="addMotto"
            :class="{'input-focus': isFocused}"
            @focus="isFocused = true"
            @blur.stop="isFocused = false">

        <div class="loading" v-if="isFetching">
            <div class="loading-spinner"></div>
        </div>

        <div class="bottomPower">powered by 颤颤巍巍</div>
    </div>
</template>

<script>
import {addMotto, getFamilyMotto} from "@/api/yunoApi";
import Back from "@/components/back.vue";

export default {
    name: "timeLessons",
    components: {Back},
    data() {
        return {
            mottos: [],
            pageNum: 1,
            totalCount: 1,
            isFetching: false,
            addMottoInfo: '',
            isFocused: false
        };
    },
    methods: {
        handleScroll() {
            if (this.isFetching) return;
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            const scrollHeight = document.documentElement.scrollHeight;
            const clientHeight = document.documentElement.clientHeight;
            if (scrollTop + clientHeight >= scrollHeight - 100) {
                this.pageNum++;
                this.getMotto();
            }
        },
        getMotto() {
            if (this.isFetching || this.mottos.length >= this.totalCount) return;
            this.isFetching = true;
            getFamilyMotto(this.pageNum).then(res => {
                this.mottos = [...this.mottos, ...res.list];
                this.totalCount = res.totalCount
                this.isFetching = false;
            }).catch(err => {
                this.isFetching = false;
                console.error('获取数据失败:', err);
            });
        },
        addMotto() {
            if (this.addMottoInfo.trim() !== '' && this.mottos != null) {
                const info = {info: this.addMottoInfo}
                this.isFetching = true;
                addMotto(info).then(() => {
                    this.mottos = []
                    this.addMottoInfo = ''
                    this.pageNum = 1;
                    return getFamilyMotto(1);
                }).then(res => {
                    this.mottos = res.list;
                    this.totalCount = res.totalCount;
                }).catch(err => {
                    console.error('添加失败:', err);
                }).finally(() => {
                    this.isFetching = false;
                });
            }
        }
    },
    mounted() {
        this.getMotto();
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@300;400;500;700&display=swap');

.family-motto-list {
    font-family: 'Noto Sans SC', sans-serif;
    padding: 20px;
    background: linear-gradient(135deg, #F9E6A0 0%, #F66B0D 100%);
    min-height: 100vh;
    color: #333;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow-x: hidden;
    box-sizing: border-box;
}

.title-animate {
    position: relative;
    text-align: center;
    color: #3B3A36;
    margin: 30px 0;
    font-weight: 700;
    font-size: clamp(1.5rem, 4vw, 2rem);
    letter-spacing: 1.5px;
    line-height: 1.3;
    animation: fadeInDown 1s ease-out;
}

ul {
    list-style-type: none;
    padding: 0;
    width: 100%;
    max-width: 650px;
    margin-bottom: 40px;
}

.motto-item {
    padding: 18px 24px;
    margin-bottom: 18px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 12px;
    font-size: 1.1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    transition: all 0.3s ease-out;
    opacity: 0;
    transform: translateY(-20px);
    animation: slideIn 0.6s forwards;
}

.motto-item:hover {
    transform: translateY(-5px) scale(1.03);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
    background-color: rgba(255, 255, 255, 1);
}

.motto-input {
    width: 100%;
    max-width: 650px;
    padding: 16px 22px;
    margin: 20px 0;
    background-color: rgba(255, 255, 255, 0.95);
    border: 2px solid transparent;
    border-radius: 10px;
    font-size: 1.1rem;
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.motto-input:focus {
    outline: none;
    border-color: #F66B0D;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.loading {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.loading-spinner {
    width: 50px;
    height: 50px;
    border: 5px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: #F66B0D;
    animation: spin 1s linear infinite;
}

.bottomPower {
    position: fixed;
    bottom: 20px;
    right: 20px;
    font-style: italic;
    color: rgba(44, 62, 80, 0.8);
    font-size: 0.9rem;
    z-index: 10;
    text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.5);
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translateY(-30px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideIn {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

/* 移动设备适配 */
@media screen and (max-width: 768px) {
    .family-motto-list {
        padding: 15px;
    }

    .motto-item {
        padding: 15px;
        font-size: 1rem;
    }

    .motto-input {
        padding: 12px 16px;
        font-size: 1rem;
    }

    .bottomPower {
        bottom: 10px;
        right: 10px;
        font-size: 0.8rem;
    }
}

/* 深色模式支持 */
@media (prefers-color-scheme: dark) {
    .family-motto-list {
        background: linear-gradient(135deg, #434343 0%, #000000 100%);
        color: #ffffff;
    }

    .motto-item {
        background-color: rgba(255, 255, 255, 0.1);
        color: #ffffff;
    }

    .motto-input {
        background-color: rgba(255, 255, 255, 0.1);
        color: #ffffff;
    }

    .motto-input::placeholder {
        color: rgba(255, 255, 255, 0.6);
    }

    .bottomPower {
        color: rgba(255, 255, 255, 0.8);
    }
}

/* 触摸设备优化 */
@media (hover: none) {
    .motto-item:hover {
        transform: none;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
}
</style>
