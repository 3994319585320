<template>
    <div class="navigationBar">
        <div class="nav-container">
            <transition name="fade">
                <div v-if="isShowNavigationBar" class="navigation-menu">
                    <div class="navigation-menu-item" 
                         :class="{ 'current': path === 'home'}"
                         @click="goto(path === 'home' ? 'homePage' : 'home')">
                        切换首页
                    </div>
                    <div class="navigation-menu-item"
                         :class="{ 'current': path === 'game'}" 
                         @click="goto('game')">
                        小游戏
                    </div>
                    <div class="navigation-menu-item"
                         :class="{ 'current': path === 'feedback'}" 
                         @click="goto('log')">
                        更新日志
                    </div>
                </div>
            </transition>
            <img src="../assets/menu.png" 
                 @click="showNavigationBar()" 
                 class="navigationBarImg"
                 alt="菜单">
        </div>
    </div>
</template>

<script>
import {goto} from "@/utils/pathUtil";

export default {
    name: "navigationBar",
    data() {
        return {
            isShowNavigationBar: false,
            path: this.$route.name
        }
    },
    methods: {
        goto,
        showNavigationBar() {
            this.isShowNavigationBar = !this.isShowNavigationBar
        }
    },
    watch: {
        '$route.name'(newPath) {
            this.path = newPath;
        }
    }
}
</script>

<style scoped>
.navigationBar {
    position: fixed;
    right: 5vw;
    top: 1px;
    z-index: 1000;
}

.nav-container {
    position: relative;
}

.navigationBarImg {
    height: 5vh;
    cursor: pointer;
    transition: transform 0.2s ease;
}

.navigationBarImg:hover {
    transform: scale(0.9);
}

.navigation-menu {
    position: absolute;
    right: 0;
    top: calc(5vh + 10px);
    border: 2px dashed #969696;
    background-color: rgba(255, 255, 255, 0.95);
    width: 24vw;
    max-width: 200px;
    padding: 8px;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.navigation-menu-item {
    color: #333;
    font-weight: normal;
    padding: 8px 12px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 0.2s ease;
}

.navigation-menu-item:last-child {
    border-bottom: none;
}

.navigation-menu-item:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.current {
    color: tomato;
    font-weight: bold;
}

.fade-enter-active, .fade-leave-active {
    transition: opacity 0.3s ease;
}

.fade-enter-from, .fade-leave-to {
    opacity: 0;
}
</style>