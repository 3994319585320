<template>
    <div class="quote-book">
      <div class="quote-page">
        <div class="quote-number">#01</div>
        <div class="quote-text">
          <p>{{ inputContent }}</p>
        </div>
        <div class="quote-author">
          ——伊壁鸠鲁（前341—前270），希腊哲学家
        </div>
        <div class="quote-hashtag">#享乐主义者</div>
      </div>
      <textarea v-model="inputContent" class="hidden" @input="updateContent"></textarea>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        inputContent: '“莫因渴望你没有的，而错过你已拥有的；要知道，你现在拥有的，也曾是你渴望的。”'
      };
    },
    methods: {
      updateContent() {
        // 当文本区域内容改变时，更新inputContent
      }
    }
  };
  </script>
  
  <style scoped>
  .quote-book {
    width: 300px; /* 根据图片宽度调整 */
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .quote-page {
    padding: 20px;
    font-family: 'Times New Roman', Times, serif;
    position: relative;
  }
  
  .quote-number {
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 24px;
    font-weight: bold;
  }
  
  .quote-text p {
    font-size: 16px;
    line-height: 1.6;
    margin: 20px 0;
  }
  
  .quote-author {
    font-size: 14px;
    margin-top: 10px;
  }
  
  .quote-hashtag {
    font-size: 14px;
    margin-top: 5px;
    color: #008000;
  }
  
  .hidden {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    padding: 20px;
    resize: none;
  }
  </style>