export function convertToChineseCurrency(amount) {
    // 定义数字和单位的映射
    const chineseNums = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
    const units = ['', '拾', '佰', '仟'];
    const bigUnits = ['', '万', '亿', '兆'];

    // 处理非法输入
    if (isNaN(amount) || amount < 0) {
        return '输入错误';
    }

    // 处理0的情况
    if (amount === 0) {
        return '零';
    }

    // 将数字转为字符串并分割整数和小数部分
    let [integerStr, decimalStr = ''] = amount.toString().split('.');

    // 限制小数位数为2位
    decimalStr = decimalStr.slice(0, 2);
    if (decimalStr.length === 1) {
        decimalStr += '0';
    }

    // 处理整数部分
    const processInteger = (numStr) => {
        // 补齐4的倍数位
        const padLength = Math.ceil(numStr.length / 4) * 4;
        numStr = numStr.padStart(padLength, '0');

        // 按4位分组处理
        const groups = [];
        for (let i = numStr.length; i > 0; i -= 4) {
            groups.unshift(numStr.slice(Math.max(0, i - 4), i));
        }

        let result = '';
        let lastWasZero = false;
        let lastGroupHadValue = false;

        groups.forEach((group, groupIndex) => {
            let groupResult = '';
            let hasValue = false;
            let hasNonZeroAfter = false;

            // 检查当前组后面是否还有非零数字
            for (let j = groupIndex + 1; j < groups.length; j++) {
                if (groups[j].split('').some(digit => digit !== '0')) {
                    hasNonZeroAfter = true;
                    break;
                }
            }

            for (let i = 0; i < 4; i++) {
                const digit = parseInt(group[i]);
                
                // 检查当前位后是否还有非零数字
                const hasNonZeroInGroup = group.slice(i + 1).split('').some(d => d !== '0');

                if (digit === 0) {
                    if (!lastWasZero && hasNonZeroInGroup) {
                        groupResult += chineseNums[0];
                    }
                    lastWasZero = true;
                } else {
                    groupResult += chineseNums[digit] + units[3 - i];
                    hasValue = true;
                    lastWasZero = false;
                }
            }

            if (hasValue) {
                // 处理组间的"零"
                if (lastGroupHadValue && !result.endsWith('零') && 
                    !result.endsWith(bigUnits[groups.length - groupIndex])) {
                    result += '零';
                }
                result += groupResult + bigUnits[groups.length - 1 - groupIndex];
                lastGroupHadValue = true;
            } else if (hasNonZeroAfter && bigUnits[groups.length - 1 - groupIndex] !== '') {
                // 对于全零但后面还有非零数的组，保留单位
                result += bigUnits[groups.length - 1 - groupIndex];
                lastGroupHadValue = false;
            }
        });

        return result || '零';
    };

    // 处理小数部分
    const processDecimal = (decStr) => {
        if (!decStr || (decStr[0] === '0' && decStr[1] === '0')) {
            return '整';
        }

        let result = '点';
        for (let i = 0; i < decStr.length; i++) {
            if (decStr[i] !== '0') {
                result += chineseNums[parseInt(decStr[i])];
            } else if (i === 0 && decStr[1] !== '0') {
                result += chineseNums[0];
            }
        }

        return result;
    };

    // 组合结果
    let result = processInteger(integerStr);
    const decimalPart = processDecimal(decimalStr);
    
    // 优化处理
    result = result
        .replace(/零+/g, '零')
        .replace(/零万/g, '万')
        .replace(/零亿/g, '亿')
        .replace(/零兆/g, '兆')
        .replace(/亿万/g, '亿')
        .replace(/兆亿/g, '兆')
        .replace(/^零+/, '')
        .replace(/零+$/, '');

    // 如果结果为空，返回零
    if (!result) {
        result = '零';
    }

    return result + decimalPart;
}
