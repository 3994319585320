<template>
        <div class="video-wrapper">
                <!-- 自定义视频播放器 -->
                <div v-if="currentVideo" class="video-container">
                        <video
                            ref="videoPlayer"
                            :src="currentVideo"
                            class="video-js"
                            controls
                            muted
                            @ended="playNextVideo"
                            @error="handleError"
                            @loadeddata="onVideoLoaded"
                        ></video>
                </div>

                <!-- 控制按钮：上一个和下一个 -->
                <div class="video-controls">
                        <button @click="playPrevVideo" :disabled="currentIndex === 0">上一个</button>
                        <button @click="playNextVideo" :disabled="currentIndex === videos.length - 1">下一个</button>
                </div>

                <!-- 加载状态 -->
                <div v-if="loading" class="loading">加载中...</div>

                <!-- 错误提示 -->
                <div v-if="error" class="error">视频加载失败，请稍后重试。</div>
        </div>
</template>

<script>
import { getDailyVideo } from "@/api/yunoApi";

export default {
        name: "watchVideo",
        data() {
                return {
                        videos: [], // 视频链接数组
                        currentIndex: 0, // 当前播放的视频索引
                        currentVideo: "", // 当前播放的视频链接
                        loading: false, // 加载状态
                        error: false, // 错误状态
                        isVideoLoaded: false, // 标记视频是否加载完成
                };
        },
        mounted() {
                this.getDailyVideo();
        },
        watch: {
                currentVideo(newVideo) {
                        if (newVideo) {
                                this.loadVideo();
                        }
                },
        },
        methods: {
                // 获取视频列表
                getDailyVideo() {
                        this.loading = true;
                        this.error = false;
                        getDailyVideo()
                            .then((res) => {
                                    this.videos = res;
                                    this.loadVideo();
                                    this.loading = false;
                            })
                            .catch(() => {
                                    this.error = true;
                                    this.loading = false;
                            });
                },

                // 加载当前视频并播放
                loadVideo() {
                        if (this.videos.length > 0) {
                                this.currentVideo = this.videos[this.currentIndex];
                                this.isVideoLoaded = false;  // 重置视频加载标志
                        }
                },

                // 播放下一个视频
                playNextVideo() {
                        if (this.currentIndex < this.videos.length - 1) {
                                this.currentIndex++; // 更新索引
                                this.loadVideo(); // 加载下一个视频
                        } else {
                                this.currentIndex = 0; // 如果播放完最后一个视频，重新从头开始播放
                                this.loadVideo(); // 加载第一个视频
                        }
                },

                // 播放上一个视频
                playPrevVideo() {
                        if (this.currentIndex > 0) {
                                this.currentIndex--; // 更新索引
                                this.loadVideo(); // 加载上一个视频
                        }
                },

                // 当视频加载完毕
                onVideoLoaded() {
                        this.isVideoLoaded = true;
                        this.playVideo();  // 在视频加载完后开始播放
                },

                // 播放视频
                playVideo() {
                        const videoElement = this.$refs.videoPlayer;
                        if (videoElement && this.isVideoLoaded) {
                                videoElement.play().catch((error) => {
                                        console.error("播放视频失败:", error);
                                        this.handleError(); // 播放失败处理
                                });
                        }
                },

                // 处理视频加载失败
                handleError() {
                        this.error = true;
                        this.loading = false;
                        this.playNextVideo();  // 尝试播放下一个视频
                },
        },

        destroyed() {
                // 清理播放器
                const videoElement = this.$refs.videoPlayer;
                if (videoElement) {
                        videoElement.pause();
                        videoElement.src = "";
                        videoElement.load();  // 释放资源
                }
        },
};
</script>

<style scoped>
.video-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 20px;
}

.video-container {
        max-width: 800px; /* 限制视频最大宽度 */
        margin: 0 auto;
        border: 5px solid #ddd; /* 边框 */
        border-radius: 8px; /* 圆角 */
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* 阴影效果 */
        overflow: hidden;
}

.video-js {
        width: 100%;
        height: auto;
}

.video-controls {
        margin-top: 20px;
        display: flex;
        justify-content: center;
}

.video-controls button {
        padding: 10px 20px;
        margin: 0 10px;
        font-size: 16px;
        background-color: #4caf50;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s ease;
}

.video-controls button:disabled {
        background-color: #ccc;
        cursor: not-allowed;
}

.video-controls button:hover:not(:disabled) {
        background-color: #45a049; /* 鼠标悬停效果 */
}

.loading {
        text-align: center;
        font-size: 18px;
        color: #888;
}

.error {
        text-align: center;
        font-size: 18px;
        color: red;
}
</style>
