<template>
    <div class="criminalLaw_container">
        <back></back>
        <div class="criminalLaw_search">
            <input v-model="keyword" placeholder="查找">
            <div class="button" @click="searchLaw()">
                <img src="@/assets/img/search.png">
            </div>
        </div>
        <div class="lawType">
            <div class="law_type" v-if="type === 1" @click="changeType()">刑法⇌</div>
            <div class="law_type" v-if="type === 2" @click="changeType()">劳动法⇌</div>
        </div>
        <div class="lawList" v-if="!isSearch">
            <div v-if="type === 1">
                <div class="lawTitle">中华人民共和国刑法</div>
                <a class="lawExplain" href="https://baike.baidu.com/item/中华人民共和国刑法/721359?fr=ge_ala"
                   target="_blank">（根据《刑法修正案(十二)》修正）</a>
            </div>
            <div v-if="type === 2">
                <div class="lawTitle">中华人民共和国劳动法</div>
                <a class="lawExplain" href="https://baike.baidu.com/item/中华人民共和国劳动法/207140?fr=aladdin"
                   target="_blank">根据2018年12月29日第十三届全国人民代表大会常务委员会第七次会议《关于修改〈中华人民共和国劳动法〉等七部法律的决定》第二次修正</a>
            </div>
            <div v-for="item in lawList" key="item.id" class="lawInfo1">{{ item.info }}</div>
            <div class="changeLaw" @click="changeLaw()">换一些</div>
        </div>
        <div class="searchLawList" v-if="isSearch">
            <div class="criminalLawCloseButton" @click="closeSearch()">✘</div>
            <div v-for="item in searchLawList" key="item.id" class="lawInfo2">{{ item.info }}</div>
        </div>
    </div>
</template>

<script>
import Back from "@/components/back.vue";
import {fuzzyGetCriminalLaw, getRandomCriminalLaws} from "@/api/yunoApi";

export default {
    name: "law",
    components: {Back},

    data() {
        return {
            lawList: [],
            searchLawList: [],
            keyword: '',
            isSearch: false,
            type: 1,
        }
    },
    methods: {
        changeType(){
          if (this.type === 1){
              this.type =2
          }else {
              this.type = 1
          }
            this.getCriminalLaws()
        },
        changeLaw() {
            this.getCriminalLaws()
        },
        getCriminalLaws() {
            getRandomCriminalLaws(this.type).then(res => {
                this.lawList = res
            })
        }, searchLaw() {
            this.isSearch = true
            const keyWord = {info: this.keyword, id: this.type}
            fuzzyGetCriminalLaw(keyWord).then(res => {
                this.searchLawList = res
            })
        }, closeSearch() {
            this.keyword = ''
            this.searchLawList = []
            this.isSearch = false
        }
    },
    mounted() {
        this.getCriminalLaws()
    }
}
</script>

<style scoped>
.criminalLaw_container {
    background: linear-gradient(135deg, #f5f7fa 0%, #e4e8eb 100%);
    min-height: 100vh;
    padding: 20px;
}

.criminalLaw_search {
    display: flex;
    position: absolute;
    margin-top: -8px;
    right: 20px;
    gap: 10px;
}

.lawType {
    display: flex;
    position: absolute;
    left: 30%;
    gap: 20px;
}

.law_type {
    background: linear-gradient(145deg, #4a90e2, #357abd);
    border: none;
    border-radius: 8px;
    margin-top: -8px;
    padding: 8px 15px;
    color: white;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.law_type:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

.button {
    cursor: pointer;
    border-radius: 10px;
    height: 40px;
    width: 40px;
    background: linear-gradient(145deg, #4a90e2, #357abd);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
}

.button:hover {
    transform: scale(1.05);
}

.button img {
    height: 24px;
    width: 24px;
}

.criminalLaw_search input {
    height: 40px;
    color: #2c3e50;
    font-weight: 500;
    border: 2px solid #e0e6ed;
    border-radius: 8px;
    padding: 0 15px;
    transition: all 0.3s ease;
}

.criminalLaw_search input:focus {
    border-color: #4a90e2;
    outline: none;
    box-shadow: 0 0 0 3px rgba(74,144,226,0.2);
}

.lawTitle {
    font-family: "Microsoft YaHei", sans-serif;
    font-weight: 700;
    color: #2c3e50;
    font-size: 32px;
    margin-bottom: 10px;
    text-align: center;
}

.lawExplain {
    display: block;
    text-align: center;
    color: #4a90e2;
    text-decoration: none;
    margin-bottom: 30px;
    transition: color 0.3s ease;
}

.lawExplain:hover {
    color: #357abd;
}

.lawList {
    margin-top: 70px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

.searchLawList {
    margin-top: 70px;
    position: relative;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

.lawInfo1, .lawInfo2 {
    background: white;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 15px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
    line-height: 1.6;
    transition: all 0.3s ease;
}

.lawInfo1:hover, .lawInfo2:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.criminalLawCloseButton {
    position: absolute;
    right: 10px;
    top: -40px;
    background: linear-gradient(145deg, #ff4757, #ff6b81);
    border-radius: 50%;
    height: 36px;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 20px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.criminalLawCloseButton:hover {
    transform: rotate(90deg);
}

.changeLaw {
    margin: 40px auto;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background: linear-gradient(145deg, #4a90e2, #357abd);
    color: white;
    font-size: 22px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    box-shadow: 0 4px 15px rgba(74,144,226,0.3);
}

.changeLaw:hover {
    transform: scale(1.05) rotate(5deg);
    box-shadow: 0 6px 20px rgba(74,144,226,0.4);
}
</style>