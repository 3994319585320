import JSEncrypt from 'jsencrypt';

/**
 * 生成 RSA 密钥对
 * @returns {Object} 包含公钥和私钥的对象
 */
export function generateRSAKeys() {
    const crypt = new JSEncrypt({ default_key_size: 2048 });
    const privateKey = crypt.getPrivateKey();
    const publicKey = crypt.getPublicKey();
    return { privateKey, publicKey };
}

/**
 * 使用 RSA 公钥加密
 * @param {string} publicKey 公钥
 * @param {string} content 要加密的内容
 * @returns {string} 加密后的内容
 */
export function rsaEncrypt(publicKey, content) {
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(publicKey);
    const encrypted = encrypt.encrypt(content);
    return encrypted ? encrypted : '加密失败';
}

/**
 * 使用 RSA 私钥解密
 * @param {string} privateKey 私钥
 * @param {string} content 要解密的内容
 * @returns {string} 解密后的内容
 */
export function rsaDecrypt(privateKey, content) {
    const decrypt = new JSEncrypt();
    decrypt.setPrivateKey(privateKey);
    const decrypted = decrypt.decrypt(content);
    return decrypted ? decrypted : '解密失败';
}
