<template>
    <div class="return-button">
        <img src="../assets/img/YunOx5.png" @click="goBack()">
    </div>
</template>

<script>
export default {
    name: "back",
    methods: {
        goBack() {
            const currentUrl = window.location.origin; // 当前网站的基础 URL
            const referrerUrl = document.referrer; // 上一页面的 URL

            // 如果上一页面的 URL 为空（比如直接打开页面）或者与当前页面的 URL 不同，返回首页
            if (!referrerUrl || !referrerUrl.startsWith(currentUrl)) {
                window.location.href = '/'; // 跳转到首页
            } else {
                window.history.back(); // 否则返回上一页面
            }
        }
    }
}
</script>

<style scoped>
.return-button {
    border-radius: 5px;
    padding: 2px;
    top: 5px;
    left: 5px;
    z-index: 999;
    cursor: pointer;
}

.return-button img {
    height: 3vh;
    position: absolute;
    left: 1vh;
    top: 5px;
    cursor: pointer;
}

.return-button img:hover {
    scale: 0.9;
}
</style>
