<template>
  <div class="personal-container">
    <!-- 顶部导航 -->
    <div class="nav-header">
      <h1>个人资料</h1>
      <div class="nav-actions">
        <el-button type="text" icon="el-icon-back" @click="goBack">返回</el-button>
      </div>
    </div>

    <!-- 主要内容区 -->
    <div class="main-content">
      <!-- 个人信息卡片 -->
      <div class="profile-card" :class="{ 'is-saving': saving }">
        <div class="profile-header">
          <div class="avatar-section">
            <div class="avatar-wrapper" @click="triggerUpload">
              <img :src="userInfo.avatar || defaultAvatar" alt="头像" class="avatar-img">
              <div class="avatar-mask">
                <i class="el-icon-camera"></i>
                <span>更换头像</span>
              </div>
              <div class="avatar-ring"></div>
            </div>
            <input 
              ref="avatarInput"
              type="file"
              accept="image/*"
              style="display: none"
              @change="handleAvatarChange"
            >
          </div>
          <div class="profile-info">
            <el-input 
              v-model="userInfo.nickname" 
              placeholder="设置昵称" 
              class="nickname-input custom-input"
              :maxlength="20"
              prefix-icon="el-icon-user"
            ></el-input>
            <el-input
              type="textarea"
              v-model="userInfo.bio"
              :rows="3"
              placeholder="介绍一下自己吧..."
              maxlength="200"
              show-word-limit
              class="bio-input custom-textarea"
              resize="none"
            ></el-input>
          </div>
        </div>

        <!-- 信息标签页 -->
        <div class="info-tabs" style="  background: linear-gradient(120deg, #fccb90 0%, #d4d2db 100%);">
          <el-tabs type="card" class="custom-tabs">
            <el-tab-pane label="基本信息" name="basic">
              <div class="tab-content animate__animated animate__fadeIn">
                <el-form :model="userInfo" label-width="100px" class="animated-form">
                  <el-form-item label="性别">
                    <el-radio-group v-model="userInfo.gender" class="gender-group">
                      <el-radio label="male">
                        <i class="el-icon-male"></i> 男
                      </el-radio>
                      <el-radio label="female">
                        <i class="el-icon-female"></i> 女
                      </el-radio>
                      <el-radio label="secret">
                        <i class="el-icon-lock"></i> 保密
                      </el-radio>
                    </el-radio-group>
                  </el-form-item>
                  
                  <el-form-item label="生日">
                    <el-date-picker
                      v-model="userInfo.birthday"
                      type="date"
                      placeholder="选择日期"
                      format="yyyy-MM-dd"
                      class="custom-date-picker custom-input"
                      :clearable="false"
                    ></el-date-picker>
                  </el-form-item>

                  <el-form-item label="所在地">
                    <el-cascader
                      v-model="userInfo.location"
                      :options="locationOptions"
                      placeholder="请选择所在地"
                      class="custom-cascader custom-input"
                      filterable
                    ></el-cascader>
                  </el-form-item>

                  <el-form-item label="职业">
                    <el-input 
                      v-model="userInfo.occupation" 
                      placeholder="你的职业"
                      prefix-icon="el-icon-office-building"
                      class="custom-input"
                    ></el-input>
                  </el-form-item>
                </el-form>
              </div>
            </el-tab-pane>

            <el-tab-pane label="联系方式" name="contact">
              <div class="tab-content animate__animated animate__fadeIn">
                <el-form :model="userInfo" label-width="100px" class="animated-form">
                  <el-form-item label="手机号码">
                    <el-input 
                      v-model="userInfo.phone" 
                      placeholder="请输入手机号"
                      prefix-icon="el-icon-mobile-phone"
                      maxlength="11"
                      class="custom-input"
                    ></el-input>
                  </el-form-item>

                  <el-form-item label="电子邮箱">
                    <el-input 
                      v-model="userInfo.email" 
                      placeholder="请输入邮箱"
                      prefix-icon="el-icon-message"
                      type="email"
                      class="custom-input"
                    ></el-input>
                  </el-form-item>

                  <el-form-item label="社交账号">
                    <div class="social-inputs">
                      <el-input 
                        v-model="userInfo.wechat" 
                        placeholder="微信号"
                        prefix-icon="el-icon-chat-dot-round"
                        class="social-input custom-input"
                      ></el-input>
                      <el-input 
                        v-model="userInfo.qq" 
                        placeholder="QQ号"
                        prefix-icon="el-icon-chat-line-round"
                        class="social-input custom-input"
                      ></el-input>
                    </div>
                  </el-form-item>
                </el-form>
              </div>
            </el-tab-pane>

            <el-tab-pane label="自定义信息" name="custom">
              <div class="tab-content animate__animated animate__fadeIn">
                <div class="custom-fields">
                  <transition-group name="custom-field-list">
                    <div 
                      v-for="(field, index) in userInfo.customFields" 
                      :key="index" 
                      class="custom-field-item"
                    >
                      <el-input 
                        v-model="field.label" 
                        placeholder="标签名称"
                        prefix-icon="el-icon-collection-tag"
                        class="field-label custom-input"
                      ></el-input>
                      <el-input 
                        v-model="field.value" 
                        placeholder="具体内容"
                        prefix-icon="el-icon-edit"
                        class="field-value custom-input"
                      ></el-input>
                      <el-button 
                        type="danger" 
                        circle 
                        @click="removeField(index)" 
                        icon="el-icon-delete"
                        class="delete-btn"
                      ></el-button>
                    </div>
                  </transition-group>
                  
                  <el-button 
                    type="primary"
                    plain
                    @click="addCustomField"
                    class="add-field-btn"
                    :disabled="userInfo.customFields.length >= 5"
                  >
                    <i class="el-icon-plus"></i> 添加自定义字段
                  </el-button>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>

        <!-- 底部按钮 -->
        <div class="action-buttons">
          <el-button 
            type="primary" 
            @click="saveProfile" 
            :loading="saving" 
            icon="el-icon-check"
            class="save-btn"
          >
            {{ saving ? '保存中...' : '保存修改' }}
          </el-button>
          <el-button 
            @click="resetForm" 
            icon="el-icon-refresh-left"
            class="reset-btn"
          >重置</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PersonalProfile',
  data() {
    return {
      defaultAvatar: require('@/assets/img/default-avatar.jpg'),
      saving: false,
      userInfo: {
        avatar: '',
        nickname: '',
        bio: '',
        gender: '',
        birthday: '',
        location: [],
        occupation: '',
        phone: '',
        email: '',
        wechat: '',
        qq: '',
        customFields: []
      },
      locationOptions: [] // 省市区数据
    }
  },
  methods: {
    goBack() {
      this.$router.back()
    },
    triggerUpload() {
      this.$refs.avatarInput.click()
    },
    handleAvatarChange(e) {
      const file = e.target.files[0]
      if (!file) return
      
      // 处理头像上传
      const reader = new FileReader()
      reader.onload = (e) => {
        this.userInfo.avatar = e.target.result
      }
      reader.readAsDataURL(file)
    },
    addCustomField() {
      if (this.userInfo.customFields.length >= 5) return
      this.userInfo.customFields.push({
        label: '',
        value: ''
      })
    },
    removeField(index) {
      this.userInfo.customFields.splice(index, 1)
    },
    async saveProfile() {
      this.saving = true
      try {
        // 保存逻辑
        await new Promise(resolve => setTimeout(resolve, 1000))
        this.$message({
          type: 'success',
          message: '保存成功',
          duration: 2000
        })
      } catch (error) {
        this.$message({
          type: 'error',
          message: '保存失败，请重试',
          duration: 2000
        })
      } finally {
        this.saving = false
      }
    },
    resetForm() {
      this.$confirm('确定要重置所有修改吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.initUserInfo()
        this.$message({
          type: 'success',
          message: '已重置'
        })
      })
    },
    initUserInfo() {
      // 初始化用户信息
    }
  },
  created() {
    this.initUserInfo()
  }
}
</script>

<style scoped>
.personal-container {
  min-height: 100vh;
  background: linear-gradient(135deg, #f6d365 0%, #fda085 100%);
  padding: 20px;
  font-family: 'PingFang SC', 'Microsoft YaHei', sans-serif;
}

.nav-header {
  background: rgba(255, 255, 255, 0.95);
  padding: 20px;
  border-radius: 15px;
  margin-bottom: 20px;
  backdrop-filter: blur(10px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-header h1 {
  margin: 0;
  font-size: 26px;
  font-weight: 700;
  background: linear-gradient(45deg, #ff6b6b, #fda085);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.main-content {
  max-width: 800px;
  margin: 0 auto;
}

.profile-card {
  background: rgba(255, 255, 255, 0.95);
  border-radius: 25px;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.profile-card.is-saving {
  transform: scale(0.98);
  opacity: 0.8;
}

.profile-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
}

.profile-header {
  padding: 40px;
  background: linear-gradient(120deg, #fccb90 0%, #d4d2db 100%);
  position: relative;
  overflow: hidden;
}

.avatar-section {
  text-align: center;
  margin-bottom: 30px;
}

.avatar-wrapper {
  position: relative;
  display: inline-block;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.avatar-wrapper:hover {
  transform: scale(1.05);
}

.avatar-img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
  border: 4px solid #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.avatar-ring {
  position: absolute;
  top: -4px;
  left: -4px;
  right: -4px;
  bottom: -4px;
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.5);
  animation: ring-rotate 8s linear infinite;
}

@keyframes ring-rotate {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.avatar-mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 16px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.avatar-wrapper:hover .avatar-mask {
  opacity: 1;
}

.profile-info {
  max-width: 500px;
  margin: 0 auto;
}


.custom-input:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.08);
}

.custom-input :deep(.el-input__inner) {
  height: 45px;
  padding: 0 20px;
  font-size: 15px;
  border: none;
  background: transparent;
}

.custom-input :deep(.el-input__prefix) {
  left: 15px;
  color: var(--el-color-primary);
}

.custom-textarea :deep(.el-textarea__inner) {
  padding: 15px 20px;
  border-radius: 12px;
  border: none;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.04);
  transition: all 0.3s ease;
}

.custom-textarea :deep(.el-textarea__inner):hover {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.08);
}

.nickname-input {
  margin-bottom: 15px;
}

.nickname-input :deep(.el-input__inner) {
  font-size: 18px;
  text-align: center;
}

.bio-input {
  margin-bottom: 0;
}

.custom-tabs {
  margin: 20px;
}

.custom-tabs :deep(.el-tabs__header) {
  margin-bottom: 30px;
}

.animated-form .el-form-item {
  transition: all 0.3s ease;
  transform-origin: left;
}

.animated-form .el-form-item:hover {
  transform: translateX(5px);
}

.gender-group {
  display: flex;
  gap: 20px;
}

.social-inputs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}

.custom-fields {
  padding: 20px;
}

.custom-field-item {
  display: grid;
  grid-template-columns: 2fr 3fr auto;
  gap: 10px;
  margin-bottom: 15px;
  align-items: center;
}

.custom-field-list-enter-active,
.custom-field-list-leave-active {
  transition: all 0.5s ease;
}

.custom-field-list-enter-from,
.custom-field-list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.delete-btn {
  transition: all 0.3s ease;
}

.delete-btn:hover {
  transform: rotate(90deg);
}

.add-field-btn {
  margin-top: 20px;
  width: 100%;
  height: 45px;
  border-radius: 22.5px;
  transition: all 0.3s ease;
}

.add-field-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.action-buttons {
  text-align: center;
  padding: 30px;
  background: linear-gradient(to top, rgba(255,255,255,0.9), transparent);
}

.action-buttons .el-button {
  width: 200px;
  height: 45px;
  margin: 0 10px;
  border-radius: 22.5px;
  transition: all 0.3s ease;
}

.save-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(24, 144, 255, 0.3);
}

.reset-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

/* 添加响应式设计 */
@media screen and (max-width: 768px) {
  .personal-container {
    padding: 10px;
  }
  
  .profile-header {
    padding: 20px;
  }
  
  .custom-field-item {
    grid-template-columns: 1fr;
    gap: 5px;
  }
  
  .action-buttons .el-button {
    width: 45%;
  }
  
  .social-inputs {
    grid-template-columns: 1fr;
  }
}

</style>