<template>
    <div>
        <div class="postal_container" :style="styleA">
            <back></back>
            <div class="left">
                <textarea 
                    class="codeA" 
                    v-model="code" 
                    :style="styleB" 
                    placeholder="设置取件码"
                    @focus="handleFocus"
                    @blur="handleBlur"
                ></textarea>
                <div class="upload" :style="styleC">
                    <el-upload
                        class="upload-demo"
                        drag
                        action=""
                        :before-upload="handleBeforeUpload"
                        multiple="false"
                        limit="1">
                        <div class="confirm">
                            <span class="upload-text">拖拽上传</span>
                            <div class="upload-icon">
                                <i class="el-icon-upload"></i>
                            </div>
                        </div>
                    </el-upload>
                </div>
            </div>
            <div class="right">
                <textarea 
                    class="codeB" 
                    v-model="code2" 
                    :style="styleB" 
                    placeholder="输入取件码"
                    @focus="handleFocus"
                    @blur="handleBlur"
                ></textarea>
                <div class="download" :style="styleC">
                    <div class="confirm2" @click="getFile">
                        <span class="download-text">点击下载</span>
                        <div class="download-icon">
                            <i class="el-icon-download"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Back from "@/components/back.vue";
import {getFile, uploadFile} from "@/api/yunoApi";

export default {
    name: "postal",
    components: {Back},
    data() {
        return {
            code: null,
            code2: null,
            screenHeight: 0,
            screenWidth: 0,
            isDownLoad: false,
            styleA: {
                width: '0px',
                height: '0px'
            },
            styleB: {
                width: '0px',
                height: '0px',
            },
            styleC: {
                width: '0px',
                height: '0px',
            },
            selectedFile: null,
            isUploading: false,
            isDownloading: false
        };
    },
    methods: {
        handleFocus(e) {
            e.target.classList.add('focused');
        },
        handleBlur(e) {
            e.target.classList.remove('focused');
        },
        async getFile() {
            if (!this.code2) {
                this.$message.warning('请输入取件码');
                return;
            }
            
            this.isDownloading = true;
            try {
                const res = await getFile(this.code2);
                const link = document.createElement('a');
                link.href = res;
                link.setAttribute('download', res);
                link.click();
                this.$message.success('下载成功');
            } catch (err) {
                this.$message.error('下载失败，请重试');
            } finally {
                this.isDownloading = false;
            }
        },
        handleBeforeUpload(file) {
            this.selectedFile = file;
            this.uploadFile();
            return false;
        },
        async uploadFile() {
            if (!this.code) {
                this.$message.warning('请先设置取件码');
                return;
            }
            
            if (!this.selectedFile) {
                this.$message.warning('请选择要上传的文件');
                return;
            }

            this.isUploading = true;
            try {
                await uploadFile(this.selectedFile, this.code);
                this.$message.success('文件上传成功');
            } catch (error) {
                this.$message.error('上传失败，请重试');
            } finally {
                this.isUploading = false;
            }
        },
        getScreenHeight() {
            this.screenHeight = window.innerHeight;
            this.screenWidth = window.innerWidth;
            this.styleA.width = `${this.screenWidth}px`;
            this.styleA.height = `${this.screenHeight - 60}px`;
            this.styleB.height = `${this.screenHeight * 0.2}px`;
            this.styleB.width = `${this.screenWidth / 2.25}px`;
            this.styleC.height = `${this.screenHeight * 0.8 - 65}px`;
            this.styleC.width = `${this.screenWidth / 2.25}px`;
        }
    },
    mounted() {
        this.getScreenHeight();
        window.addEventListener('resize', this.getScreenHeight);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.getScreenHeight);
    }
}
</script>

<style>
.postal_container {
    margin-top: 50px;
    display: flex;
    height: 95vh;
    width: 100%;
    animation: fadeIn 0.5s ease;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.left, .right {
    flex: 1;
    transition: all 0.3s ease;
}

.left {
    background-color: #42b983;
    transform-origin: left;
}

.right {
    background-color: #007BFF;
    transform-origin: right;
}

.codeA, .codeB {
    margin-top: 10px;
    font-size: 24px;
    width: 100%;
    height: 20%;
    transition: all 0.3s ease;
    border: 2px solid transparent;
    border-radius: 8px;
    padding: 12px;
    resize: none;
}

.codeA {
    background-color: #b8f1c3;
}

.codeB {
    background-color: #a7bcff;
}

.codeA.focused, .codeB.focused {
    border-color: #ffffff;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
    transform: translateY(-2px);
}

.confirm, .confirm2 {
    color: white;
    cursor: pointer;
    font-size: 100px;
    height: 35vw;
    width: 110%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    border-radius: 12px;
}

.confirm {
    background-color: #42b983;

}

.confirm2 {
    background-color: #007BFF;
}

.confirm:hover, .confirm2:hover {
    transform: scale(0.98);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.upload-text, .download-text {
    font-size: 32px;
    margin-bottom: 20px;
}

.upload-icon, .download-icon {
    font-size: 48px;
    opacity: 0.8;
}

.el-upload-dragger {
    border: 1px dashed rgba(255, 255, 255, 0.3);
    background-color: rgba(255, 255, 255, 0.1);
    width: 50vw;
    height: 120%;
    transition: all 0.3s ease;
}

.el-upload-dragger:hover {
    border-color: #ffffff;
    background-color: rgba(255, 255, 255, 0.2);
}

@keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.05); }
    100% { transform: scale(1); }
}

.isUploading .confirm,
.isDownloading .confirm2 {
    animation: pulse 1s infinite;
}
</style>