<template>
    <div class="feedback-container">
        <back></back>
        <h2>用户建议与反馈</h2>

        <div v-if="suggestions.length > 0" class="feedback-list">
            <div
                    v-for="suggestion in suggestions"
                    :key="suggestion.id"
                    :style="{ borderLeftColor: getStatusLeftClass(suggestion.status) }"
                    class="feedback-item"
                    @click="openFeedbackModal(suggestion)"
            >
                <div class="feedback-header">
                    <strong>{{ suggestion.name || '匿名' }}</strong>
                    <span class="feedback-date">{{ parseTime3(suggestion.createTime) }}</span>
                </div>
                <div class="feedback-body">
                    <p class="feedback-text">{{ suggestion.suggestion.substring(0, 50) }}...</p>
                </div>
                <div class="feedback-footer">
                    <span class="status-circle" :class="getStatusClass(suggestion.status)"></span>
                    <span>状态: {{ getStatusText(suggestion.status) }}</span>
                </div>
            </div>
        </div>

        <!-- 弹窗 -->
        <div v-if="isModalVisible" class="modal-overlay" @click="closeModal">
            <div class="modal-content" @click.stop>
                <button class="modal-close-btn" @click="closeModal">×</button>
                <div class="modal-body">
                    <h3 class="modal-title">建议详情</h3>
                    <div class="modal-details">
                        <p><strong>姓名：</strong>{{ modalData.name || '匿名' }}</p>
                        <p><strong>建议：</strong>{{ modalData.suggestion }}</p>
                        <div v-if="modalData.imageUrl">
                            <strong>图片：</strong>
                            <img :src="modalData.imageUrl" alt="建议图片" class="modal-image"/>
                        </div>
                    </div>
                    <div v-if="modalData.status !== 0" class="feedback-reply">
                        <strong>作者回复:</strong>
                        <p>{{ getReplyText(modalData.status, modalData.reply) }}</p>
                    </div>
                    <div v-if="modalData.status === 0">
                        <strong>已提交，作者未读...</strong>
                    </div>
                </div>
            </div>
        </div>

        <!-- 添加反馈按钮 -->
        <button class="floating-btn" @click="openFeedbackForm" title="添加反馈/建议">+</button>

        <!-- 反馈表单弹窗 -->
        <div v-if="isFeedbackFormVisible" class="modal-overlay" @click="closeFeedbackForm">
            <div class="modal-content" @click.stop>
                <button class="modal-close-btn" @click="closeFeedbackForm">×</button>
                <div class="modal-body">
                    <h3 class="modal-title">提交反馈</h3>
                    <form @submit.prevent="submitFeedback">
                        <!-- 姓名和联系方式在同一行 -->
                        <div class="form-group">
                            <div class="form-row">
                                <div class="form-field">
                                    <label for="name" class="form-label">昵称:</label>
                                    <input type="text" v-model="feedbackData.name" id="name" required class="form-input"/>
                                </div>
                                <div class="form-field">
                                    <label for="contact" class="form-label">联系方式:</label>
                                    <input type="text" v-model="feedbackData.contact" id="contact" required class="form-input"/>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="suggestion" class="form-label">意见:</label>
                            <textarea v-model="feedbackData.suggestion" id="suggestion" required class="form-textarea"></textarea>
                        </div>
                        <!-- 上传按钮 -->
                        <div class="form-group upload-section">
                            <label for="image" class="form-label">上传图片:</label>
                            <button type="button" class="upload-btn" @click="$refs.imageInput.click()">选择图片</button>
                            <input type="file" @change="onFileChange" id="image" ref="imageInput" class="form-file" style="display: none;"/>
                        </div>
                        <button type="submit" class="submit-btn">提交</button>
                    </form>
                </div>
            </div>
        </div>


        <!-- 加载更多按钮 -->
        <div v-if="suggestions.length < totalCount" class="load-more">
            <button @click="loadMoreSuggestions" class="btn-load-more">加载更多</button>
        </div>
    </div>
</template>

<script>
import {getSuggestionList} from "@/api/yunoApi";
import {parseTime3} from "@/utils/parse";
import Back from "@/components/back.vue";

export default {
    name: "suggestion",
    components: {Back},
    data() {
        return {
            suggestions: [],
            pageNum: 1,
            totalCount: 0,
            isModalVisible: false,
            isFeedbackFormVisible: false,
            modalData: {},
            feedbackData: {
                name: '',
                contact: '',
                suggestion: '',
                imageUrl: null,
            },
        };
    },
    methods: {
        parseTime3,
        getSuggestionList() {
            getSuggestionList(this.pageNum).then((res) => {
                res.list.forEach((suggest) => {
                    this.suggestions.push({
                        ...suggest,
                    });
                });
                this.totalCount = res.totalCount;
            });
        },
        getStatusText(status) {
            switch (status) {
                case 0:
                    return "未处理";
                case 1:
                    return "已读";
                case 2:
                    return "已处理";
                default:
                    return "未知状态";
            }
        },
        getReplyText(status, reply) {
            if (status === 2 && reply) {
                return reply;
            }
            switch (status) {
                case 0:
                    return "等待作者阅读...";
                case 1:
                    return "作者已读，等待回复...";
                default:
                    return "暂无回复";
            }
        },
        getStatusClass(status) {
            switch (status) {
                case 0:
                    return "status-gray";
                case 1:
                    return "status-blue";
                case 2:
                    return "status-green";
                default:
                    return "status-unknown";
            }
        },
        getStatusLeftClass(status) {
            switch (status) {
                case 0:
                    return "#6c757d";
                case 1:
                    return "#007bff";
                case 2:
                    return "#28a745";
                default:
                    return "#ffc107";
            }
        },
        openFeedbackModal(suggestion) {
            this.modalData = suggestion;
            this.isModalVisible = true;
        },
        closeModal() {
            this.isModalVisible = false;
        },
        openFeedbackForm() {
            this.isFeedbackFormVisible = true;
        },
        closeFeedbackForm() {
            this.isFeedbackFormVisible = false;
            this.feedbackData = {
                name: '',
                contact: '',
                suggestion: '',
                imageUrl: null,
            };
        },
        onFileChange(event) {
            const file = event.target.files[0];
            if (file) {
                this.feedbackData.imageUrl = URL.createObjectURL(file);
            }
        },
        submitFeedback() {
            console.log("反馈数据:", this.feedbackData);
            this.closeFeedbackForm();
        },
        loadMoreSuggestions() {
            if (this.suggestions.length < this.totalCount) {
                this.pageNum++;
                this.getSuggestionList();
            }
        },
    },
    mounted() {
        this.getSuggestionList();
    },
};
</script>

<style scoped>
.feedback-container {
    padding: 20px;
    background: linear-gradient(135deg, #f5f7fa, #e0eaf2);
    border-radius: 8px;
}

h2 {
    font-size: 28px;
    margin-bottom: 20px;
    color: #2a2a2a;
    text-align: center;
    font-family: 'Arial', sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.feedback-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
}

.feedback-item {
    background: #ffffff;
    padding: 16px;
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out, box-shadow 0.3s ease;
    cursor: pointer;
    border-left: 5px solid #00aaff;
}

.feedback-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.feedback-header {
    font-size: 16px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
}

.feedback-date {
    font-size: 14px;
    color: #888;
}

.feedback-body {
    margin-bottom: 15px;
}

.feedback-text {
    color: #555;
    font-size: 14px;
    line-height: 1.4;
}

.feedback-footer {
    font-size: 14px;
    color: #888;
    display: flex;
    align-items: center;
}

.status-circle {
    width: 10px;
    height: 10px;
    margin-right: 5px;
    border-radius: 50%;
    background-color: #6c757d;
}

.status-gray {
    background-color: #6c757d;
}

.status-blue {
    background-color: #007bff;
}

.status-green {
    background-color: #28a745;
}

.status-unknown {
    background-color: #ffc107;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.modal-content {
    background: #fff;
    border-radius: 12px;
    padding: 30px;
    width: 90%;
    max-width: 600px;
    position: relative;
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
}
.modal-content:hover {
    box-shadow: 0 16px 50px rgba(0, 0, 0, 0.3);
}
.modal-close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    background: none;
    border: none;
    color: #999;
    cursor: pointer;
}
.modal-close-btn:hover {
    color: #ff4081;
}
.modal-title {
    font-size: 24px;
    font-weight: 600;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
    letter-spacing: 1px;
}

.modal-body {
    font-size: 16px;
    color: #555;
}

.form-group {
    margin-bottom: 20px;
}

/* 输入框与上传按钮调整 */
.form-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}
.form-field {
    width: 48%;
}

.form-label {
    font-size: 16px;
    color: #444;
    width: 30%;
    text-align: right;
}

.form-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 14px;
    transition: border-color 0.3s ease;
}

.form-input:focus {
    border-color: #ff4081;
    outline: none;
}

.form-textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 14px;
    resize: vertical;
    min-height: 120px;
    transition: border-color 0.3s ease;
}

.form-textarea:focus {
    border-color: #ff4081;
    outline: none;
}

.upload-section {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
}

.upload-btn {
    background: #007bff;
    color: white;
    padding: 8px 14px;
    border: none;
    border-radius: 5px;
    font-size: 14px;  /* 调整按钮字体 */
    cursor: pointer;
}

.upload-btn:hover {
    background: #0056b3;
}

.upload-btn:focus {
    outline: none;
}


.submit-btn {
    width: 100%;
    padding: 12px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.submit-btn:hover {
    background-color: #218838;
}

.floating-btn {
    position: fixed;
    bottom: 30px;
    right: 30px;
    width: 60px;
    height: 60px;
    background-color: #ff4081;
    color: white;
    border-radius: 50%;
    font-size: 30px;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
    border: none;
    cursor: pointer;
    z-index: 10000;
    transition: background-color 0.3s ease;
}

.floating-btn:hover {
    background-color: #ff2a68;
}

.load-more {
    text-align: center;
    margin-top: 20px;
}

.btn-load-more {
    padding: 10px 20px;
    background-color: #ff5722;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
}

.btn-load-more:hover {
    background-color: #e64a19;
}
</style>
