export function isIDCard(id) {
    const regex = /^(?:\d{15}|\d{18}|\d{17}(\d|X))$/;
    return regex.test(id);
}

export function isPhoneNumber(phone) {
    const regex = /^1[3-9]\d{9}$/;
    return regex.test(phone);
}
export function isName(name) {
    const regex = /^[\u4e00-\u9fa5]{2,5}$/;
    return regex.test(name);
}

