<template>
    <div class="lunar-container">
        <div v-if="lunarInfo" class="lunar-content">
            <!-- Lunar Header -->
            <div class="lunar-header">
                <div class="lunar-date">
                    <p><strong>公历：</strong>{{ lunarInfo.gregoriandate }}</p>
                    <p><strong>农历：</strong>{{ lunarInfo.lunardate }}</p>
                </div>
                <div class="lunar-sign">
                    <p><strong>生肖：</strong>{{ lunarInfo.shengxiao }}</p>
                    <p><strong>节气：</strong>{{ lunarInfo.jieqi || '无' }}</p>
                </div>
            </div>

            <!-- Lunar Festival -->
            <div class="lunar-festival">
                <h3>节日与禁忌</h3>
                <p><strong>节日：</strong>{{ lunarInfo.lunarFestival || '无' }}</p>
                <p><strong>宜：</strong>{{ lunarInfo.fitness }}</p>
                <p><strong>忌：</strong>{{ lunarInfo.taboo }}</p>
            </div>

            <!-- Lunar Details -->
            <div class="lunar-details">
                <h3>详细信息</h3>
                <div class="detail-grid">
                    <div v-for="(item, index) in lunarDetails" :key="index" class="detail-item">
                        <p><strong>{{ item.label }}：</strong>{{ lunarInfo[item.key] || '无' }}</p>
                    </div>
                </div>
            </div>

            <!-- Lunar Image -->
            <div v-if="lunarInfo.imgUrl" class="lunar-image">
                <img :src="lunarInfo.imgUrl" alt="Lunar Image" class="image" @click="openFullScreen"/>
            </div>
        </div>

        <!-- Loading or Error -->
        <div v-else class="loading-container">
            <p class="loading-text">正在加载...</p>
        </div>
    </div>
</template>

<script>
import { getLunarInfo } from "@/api/yunoApi";
import { decrypt } from "@/utils/AES";

export default {
    name: "Lunar",
    data() {
        return {
            lunarInfo: null,
            lunarDetails: [
                { label: '喜神', key: 'shenwei' },
                { label: '太神', key: 'taishen' },
                { label: '冲煞', key: 'chongsha' },
                { label: '岁煞', key: 'suisha' },
                { label: '百吉', key: 'baiji' },
                { label: '五行', key: 'wuxing' },
                { label: '五行甲子', key: 'wuxingjiazi' },
                { label: '五行纳月', key: 'wuxingnamonth' },
                { label: '星宿', key: 'xingsu' },
                { label: '彭祖', key: 'pengzu' },
                { label: '建神', key: 'jianshen' },
                { label: '天干地支(年)', key: 'tiangandizhiyear' },
                { label: '天干地支(月)', key: 'tiangandizhimonth' },
                { label: '天干地支(日)', key: 'tiangandizhiday' },
                { label: '农历月份', key: 'lubarmonth' },
                { label: '农历日期', key: 'lunarday' },
                { label: '生肖', key: 'shengxiao' },
                { label: '节气', key: 'jieqi' },
                { label: '农历名称', key: 'lmonthname' },
                { label: '农历节日', key: 'lunarFestival' },
                { label: '普通节日', key: 'festival' }
            ]
        };
    },
    methods: {
        getLunar() {
            getLunarInfo().then(res => {
                res.imgUrl = decrypt(res.imgUrl);
                this.lunarInfo = res;
            }).catch(err => {
                console.error("获取农历信息失败:", err);
            });
        },
        openFullScreen() {
            const img = document.createElement('img');
            img.src = this.lunarInfo.imgUrl;
            img.style.maxWidth = '90%';
            img.style.maxHeight = '90%';
            img.style.margin = 'auto';
            img.style.display = 'block';

            const fullScreenDiv = document.createElement('div');
            fullScreenDiv.style.position = 'fixed';
            fullScreenDiv.style.top = '0';
            fullScreenDiv.style.left = '0';
            fullScreenDiv.style.width = '100%';
            fullScreenDiv.style.height = '100%';
            fullScreenDiv.style.backgroundColor = 'rgba(0, 0, 0, 0.8)';
            fullScreenDiv.style.display = 'flex';
            fullScreenDiv.style.alignItems = 'center';
            fullScreenDiv.style.justifyContent = 'center';
            fullScreenDiv.style.overflow = 'auto';

            document.body.style.overflow = 'hidden';

            fullScreenDiv.appendChild(img);

            fullScreenDiv.onclick = () => {
                document.body.removeChild(fullScreenDiv);
                document.body.style.overflow = '';
            };

            document.body.appendChild(fullScreenDiv);
        }

    },
    mounted() {
        this.getLunar();
    }
};
</script>

<style scoped>
.lunar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    color: #333;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.lunar-header, .lunar-festival, .lunar-details {
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 8px;
    background-color: #f9e7e7; /* Light red background */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s, color 0.3s;
}

.lunar-header:hover, .lunar-festival:hover, .lunar-details:hover {
    background-color: #f0d0d0; /* Slightly darker red on hover */
}

.lunar-date, .lunar-sign {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.lunar-festival h3, .lunar-details h3 {
    color: #8b0000; /* Dark red for headings */
    font-weight: bold;
    margin-bottom: 15px;
}

.detail-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
}

.detail-item p {
    margin: 5px 0;
    font-size: 1em;
    color: #555;
}

.lunar-image {
    margin-top: 25px;
    text-align: center;
}

.lunar-image img {
    max-width: 100%;
    height: auto;
    border-radius: 15px;
    border: 4px solid #d50000; /* Bright red border */
    cursor: pointer;
    transition: transform 0.3s, box-shadow 0.3s;
}

.lunar-image img:hover {
    transform: scale(1.05);
    box-shadow: 0 0 20px rgba(213, 0, 0, 0.5); /* Red shadow on hover */
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    background-color: #e0e0e0;
    border-radius: 10px;
    animation: fadeIn 2s ease-in-out;
}

.loading-text {
    text-align: center;
    font-size: 1.2em;
    color: #616161;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}
</style>



