<template>
    <div>
        <back></back>
    <div class="word-container">
        <h1 class="title">每天一百个单词</h1>

        <!-- 搜索栏 -->
        <div class="search-bar">
            <input 
                v-model="keyword" 
                placeholder="搜索单词..."
                @keyup.enter="getWordByKeyword()"
                class="search-input"
            >
            <button class="search-btn" @click="getWordByKeyword()">
                <img src="@/assets/img/search.png" alt="搜索">
            </button>
        </div>

        <!-- 单词列表 -->
        <div class="word-list" :class="{'search-active': isSearch}">
            <!-- 搜索结果关闭按钮 -->
            <button v-if="isSearch" 
                    class="close-btn"
                    @click="closeSearch"
                    title="关闭搜索">
                ×
            </button>

            <!-- 单词卡片 -->
            <div v-for="(item, index) in displayWords" 
                 :key="index" 
                 class="word-card"
                 @click="copyText(item.word)">
                <div class="word-text">{{ item.word }}</div>
                <div class="word-meaning">{{ item.interpretation }}</div>
            </div>
        </div>

        <!-- 刷新按钮 -->
        <button class="refresh-btn" 
                @click="flushWords()"
                title="换一批单词">
            <img src="@/assets/img/undo.png" alt="刷新">
            <span>换一批</span>
        </button>
    </div>
    </div>
</template>

<script>
import {getWordByKeyword, getWords} from "@/api/yunoApi";
import Back from "@/components/back.vue";
import {checkIsToday} from "@/utils/parse";

export default {
    name: "WordList",
    components: {Back},
    
    data() {
        return {
            keyword: '',
            isSearch: false,
            searchResult: [],
            words: [{word: 'apple', interpretation: '苹果'}],
        }
    },

    computed: {
        displayWords() {
            return this.isSearch ? this.searchResult : this.words;
        }
    },

    methods: {
        async flushWords() {
            localStorage.removeItem('words');
            localStorage.removeItem('wordDate');
            await this.getWords();
        },

        copyText(text) {
            navigator.clipboard.writeText(text)
                .then(() => {
                    // 可以添加复制成功的提示
                })
                .catch(err => {
                    console.error('复制失败:', err);
                });
        },

        async getWords() {
            const words = localStorage.getItem('words');
            const time = localStorage.getItem('wordDate');
            
            if (words && time && checkIsToday(time)) {
                this.words = JSON.parse(words);
            } else {
                try {
                    const res = await getWords();
                    this.words = res.sort((a, b) => a.word.localeCompare(b.word));
                    localStorage.setItem('words', JSON.stringify(this.words));
                    localStorage.setItem('wordDate', new Date().toString());
                } catch (error) {
                    console.error('获取单词列表失败:', error);
                }
            }
        },

        async getWordByKeyword() {
            if (!this.keyword.trim()) return;
            
            try {
                this.isSearch = true;
                const word = {word: this.keyword};
                this.searchResult = await getWordByKeyword(word);
                this.keyword = '';
            } catch (error) {
                console.error('搜索单词失败:', error);
            }
        },

        closeSearch() {
            this.isSearch = false;
            this.searchResult = [];
            this.keyword = '';
        }
    },

    mounted() {
        this.getWords();
    }
}
</script>

<style scoped>
.word-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    position: relative;
}

.title {
    color: #ff7891;
    font-size: 24px;
    font-weight: bold;
    margin: 20px 0;
    text-align: center;
}

.search-bar {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-bottom: 20px;
}

.search-input {
    padding: 8px 12px;
    border: 2px solid #e2e8f0;
    border-radius: 8px;
    width: 200px;
    transition: all 0.3s ease;
}

.search-input:focus {
    border-color: #ff7891;
    outline: none;
}

.search-btn {
    background: #e2ff95;
    border: none;
    border-radius: 8px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    transition: transform 0.2s ease;
}

.search-btn:hover {
    transform: scale(1.05);
}

.search-btn img {
    width: 24px;
    height: 24px;
}

.word-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px;
    background: rgba(255, 221, 221, 0.23);
    border-radius: 12px;
    position: relative;
}

.word-card {
    background: white;
    border-radius: 12px;
    padding: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 0.3s ease;
}

.word-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.word-text {
    font-size: 20px;
    font-weight: bold;
    color: #2d3748;
    margin-bottom: 8px;
}

.word-meaning {
    padding: 8px;
    background: rgba(66, 185, 131, 0.45);
    border-radius: 6px;
    color: #2d3748;
}

.close-btn {
    position: absolute;
    top: -15px;
    right: -15px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #ff5252;
    color: white;
    border: none;
    font-size: 20px;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
}

.close-btn:hover {
    transform: scale(1.1);
    background: #ff1744;
}

.refresh-btn {
    position: fixed;
    bottom: 40px;
    right: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    color: #ff1919;
    font-size: 14px;
    gap: 5px;
}

.refresh-btn img {
    width: 40px;
    height: 40px;
    animation: spin 6s linear infinite;
}

@keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(-360deg); }
}

@media (max-width: 768px) {
    .word-list {
        grid-template-columns: 1fr;
    }
    
    .search-input {
        width: 150px;
    }
}
</style>