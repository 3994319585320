<template>
    <div class="cert-container" lang="zh">
        <back></back>

        <div class="sections-container">
            <!-- 第一部分 -->
            <div class="cert-section cert-section-60">
                <h3 class="section-title">60的证书制作</h3>
                <div class="input-group">
                    <label for="name1">证书名: </label>
                    <input v-model="name1" type="text" id="name1" placeholder="请输入证书名" class="input short-input"
                           aria-label="证书名输入框"/>
                </div>

                <div class="input-group">
                    <label for="number1">偏移量:</label>
                    <input v-model="number1" type="number" id="number1" placeholder="输入间距，3个字为810"
                           class="input short-input" aria-label="偏移量输入框"/>
                </div>
                <button @click="submitSixtyCert" class="submit-btn submit-btn-60" aria-label="创建60的证书按钮">
                    创建60的证书
                </button>

                <!-- 第一部分显示图片 -->
                <div v-if="file1Img" class="image-container">
                    <img :src="file1Img" alt="60证书图片" class="image" @click="openFullScreen(file1Img)"
                         aria-label="查看60证书图片"/>
                </div>
            </div>

            <!-- 第二部分 -->
            <div class="cert-section cert-section-100">
                <h3 class="section-title">100的证书制作</h3>

                <div class="input-group">
                    <label for="name2">证书名: </label>
                    <input v-model="name2" type="text" id="name2" placeholder="请输入证书名" class="input short-input"
                           aria-label="证书名输入框"/>
                </div>

                <div class="input-group">
                    <label for="number2">偏移量:</label>
                    <input v-model="number2" type="number" id="number2" placeholder="输入间距，3个字为560"
                           class="input short-input" aria-label="偏移量输入框"/>
                </div>

                <button @click="submitHundredCert" class="submit-btn submit-btn-100" aria-label="创建100的证书按钮">
                    创建100的证书
                </button>

                <!-- 第二部分显示图片 -->
                <div v-if="file2Img" class="image-container">
                    <img :src="file2Img" alt="100证书图片" class="image" @click="openFullScreen(file2Img)"
                         aria-label="查看100证书图片"/>
                </div>
            </div>
            <!-- 第6部分 -->
            <div class="cert-section cert-section-r150">
                <h3 class="section-title">150的证书制作</h3>

                <div class="input-group">
                    <label for="name2">证书名: </label>
                    <input v-model="name6" type="text" id="name2" placeholder="请输入证书名" class="input short-input"
                           aria-label="证书名输入框"/>
                </div>

                <div class="input-group">
                    <label for="number2">偏移量:</label>
                    <input v-model="number6" type="number" id="number2" placeholder="输入间距，3个字为560"
                           class="input short-input" aria-label="偏移量输入框"/>
                </div>

                <button @click="submitHundredFiftyCert" class="submit-btn submit-btn-r150"
                        aria-label="创建100的证书按钮">
                    创建150的证书
                </button>

                <!-- 第二部分显示图片 -->
                <div v-if="file6Img" class="image-container">
                    <img :src="file6Img" alt="100证书图片" class="image" @click="openFullScreen(file6Img)"
                         aria-label="查看100证书图片"/>
                </div>
            </div>
        </div>
        <div class="sections-container">
            <!-- 第三部分 -->
            <div class="cert-section cert-section-info">
                <div style="position: absolute">
                    <label class="switch">
                        <input class="cb" type="checkbox" style="opacity: 0.01" v-model="isShowPostion"/>
                        <span class="toggle">
                        <span class="left">❌</span><span class="right">✅</span>
                        </span>
                    </label>
                </div>
                <h3 class="section-title" style="flex: 3">60电子版生成</h3>

                <!-- 上传照片 -->
                <div class="input-group">
                    <label for="photo" class="upload-label">上传照片:</label>
                    <div class="file-input-container">
                        <input type="file" id="photo" @change="handleFileChange" class="file-input" data-source="E"
                               aria-label="上传照片"/>
                        <label for="photo" class="file-input-label" aria-label="选择照片按钮">选择照片</label>
                    </div>
                    <div v-if="imagePreview" class="image-container">
                        <img :src="imagePreview" class="image" alt="Image Preview" aria-label="图片预览"
                             style="max-width: 200px"/>
                    </div>
                </div>

                <!-- 用户名 -->
                <div class="input-group">
                    <label for="userName">姓名:</label>
                    <input v-model="userName" type="text" id="userName" placeholder="输入姓名"
                           class="input short2-input" aria-label="姓名输入框"/>
                    <input v-model="userNameXLocation" type="number" class="input short3-input"
                           aria-label="左右位置调整" v-if="isShowPostion"/>
                    <!--                    <input v-model="userNameYLocation" type="number" class="input short3-input"-->
                    <!--                           aria-label="上下位置调整" v-if="isShowPostion"/>-->
                </div>

                <!-- 性别 -->
                <div class="input-group">
                    <label for="gender">性别:</label>
                    <div
                            class="gender-option"
                            :class="{'selected': gender === '男'}"
                            @click="gender = '男'">
                        男
                    </div>
                    <div
                            class="gender-option"
                            :class="{'selected': gender === '女'}"
                            @click="gender = '女'">
                        女
                    </div>
                    <input v-model="genderXLocation" type="number" class="input short3-input"
                           aria-label="左右位置调整" v-if="isShowPostion"/>
                    <!--                    <input v-model="genderYLocation" type="number" class="input short3-input"-->
                    <!--                           aria-label="上下位置调整" v-if="isShowPostion"/>-->
                </div>

                <!-- 出生日期 -->
                <div class="input-group">
                    <label for="birthDate">出生日期:</label>
                    <input v-model="birthDate" type="text" id="birthDate" placeholder="2025.01.01"
                           class="input short2-input" aria-label="出生日期输入框"/>
                    <input v-model="birthDateXLocation" type="number" class="input short3-input"
                           aria-label="左右位置调整" v-if="isShowPostion"/>
                    <!--                    <input v-model="birthDateYLocation" type="number" class="input short3-input"-->
                    <!--                           aria-label="上下位置调整" v-if="isShowPostion"/>-->
                </div>

                <!-- 身份证号 -->
                <div class="input-group">
                    <label for="cardId">身份证号:</label>
                    <input v-model="cardId" type="text" id="cardId" placeholder="身份证号" class="input short2-input"
                           aria-label="身份证号输入框"/>
                    <input v-model="cardIdXLocation" type="number" class="input short3-input"
                           aria-label="左右位置调整" v-if="isShowPostion"/>
                    <!--                    <input v-model="cardIdYLocation" type="number" class="input short3-input"-->
                    <!--                           aria-label="上下位置调整" v-if="isShowPostion"/>-->
                </div>

                <!-- 证书名称 -->
                <div class="input-group">
                    <label for="certName">证书名称:</label>
                    <input v-model="certName" type="text" id="certName" placeholder="焊工、电工..."
                           class="input short2-input" aria-label="证书名称输入框"/>
                    <input v-model="certNameXLocation" type="number" class="input short3-input"
                           aria-label="左右位置调整" v-if="isShowPostion"/>
                    <!--                    <input v-model="certNameYLocation" type="number" class="input short3-input"-->
                    <!--                           aria-label="上下位置调整" v-if="isShowPostion"/>-->
                </div>

                <!-- 级别 -->
                <div class="input-group">
                    <label for="level">级别:</label>
                    <input v-model="level" type="text" id="level" placeholder="高级、中级..." class="input short2-input"
                           aria-label="级别输入框"/>
                    <input v-model="levelXLocation" type="number" class="input short3-input" aria-label="左右位置调整"
                           v-if="isShowPostion"/>
                    <!--                    <input v-model="levelYLocation" type="number" class="input short3-input" aria-label="上下位置调整"-->
                    <!--                           v-if="isShowPostion"/>-->
                </div>

                <!-- 证书号 -->
                <div class="input-group">
                    <label for="certNo">证书号:</label>
                    <input v-model="certNo" type="text" id="certNo" :placeholder="getPatternTime('yyyyMMdd')"
                           class="input short2-input"
                           aria-label="证书号输入框"/>
                    <input v-model="certNoXLocation" type="number" class="input short3-input"
                           aria-label="左右位置调整" v-if="isShowPostion"/>
                    <!--                    <input v-model="certNoYLocation" type="number" class="input short3-input"-->
                    <!--                           aria-label="上下位置调整" v-if="isShowPostion"/>-->
                </div>

                <!-- 年份 -->
                <div class="input-group">
                    <label for="createTime">发证时间:</label>
                    <input v-model="year" type="text" id="year" class="input short3-input" aria-label="发证年份输入框"/>年
                    <input v-model="month" type="text" id="month" class="input short3-input"
                           aria-label="发证月份输入框"/>月
                    <input v-model="day" type="text" id="day" class="input short3-input" aria-label="发证日期输入框"/>日
                </div>

                <!-- 提交按钮 -->
                <button @click="submitESixtyCert" class="submit-btn submit-btn-e60" aria-label="创建电子版按钮">
                    创建60的电子版
                </button>

                <!-- 显示图片 -->
                <div v-if="file3Img" class="image-container">
                    <img :src="file3Img" alt="个人信息证书图片" class="image" @click="openFullScreen(file3Img)"
                         aria-label="查看个人信息证书图片"/>
                </div>
            </div>
            <div class="cert-section cert-section-info">
                <div style="position: absolute">
                    <label class="switch">
                        <input class="cb" type="checkbox" style="opacity: 0.01" v-model="isShowE100Postion"/>
                        <span class="toggle">
                        <span class="left">❌</span><span class="right">✅</span>
                        </span>
                    </label>
                </div>
                <h3 class="section-title" style="flex: 3">100电子版生成</h3>

                <!-- 上传照片 -->
                <div class="input-group">
                    <label for="photo" class="upload-label">上传照片:</label>
                    <div class="file-input-container">
                        <input type="file" id="photoe100" @change="handleFileChange" class="file-input"
                               data-source="e100"
                               aria-label="上传照片"/>
                        <label for="photoe100" class="file-input-label" aria-label="选择照片按钮">选择照片</label>
                    </div>
                    <div v-if="imageE100Preview" class="image-container">
                        <img :src="imageE100Preview" class="image" alt="Image Preview" aria-label="图片预览"
                             style="max-width: 200px"/>
                    </div>
                </div>

                <!-- 用户名 -->
                <div class="input-group">
                    <label for="userName">姓名:</label>
                    <input v-model="e100userName" type="text" id="userName" placeholder="输入姓名"
                           class="input short2-input" aria-label="姓名输入框"/>
                    <input v-model="e100userNameXLocation" type="number" class="input short3-input"
                           aria-label="左右位置调整" v-if="isShowE100Postion"/>
                    <!--                    <input v-model="userNameYLocation" type="number" class="input short3-input"-->
                    <!--                           aria-label="上下位置调整" v-if="isShowPostion"/>-->
                </div>

                <!-- 性别 -->
                <div class="input-group">
                    <label for="gender">性别:</label>
                    <div
                            class="gender-option"
                            :class="{'selected': e100gender === '男'}"
                            @click="e100gender = '男'">
                        男
                    </div>
                    <div
                            class="gender-option"
                            :class="{'selected': e100gender === '女'}"
                            @click="e100gender = '女'">
                        女
                    </div>
                    <input v-model="e100genderXLocation" type="number" class="input short3-input"
                           aria-label="左右位置调整" v-if="isShowE100Postion"/>
                    <!--                    <input v-model="genderYLocation" type="number" class="input short3-input"-->
                    <!--                           aria-label="上下位置调整" v-if="isShowPostion"/>-->
                </div>
                <!-- 身份证号 -->
                <div class="input-group">
                    <label for="cardId">身份证号:</label>
                    <input v-model="e100cardId" type="text" id="cardId" placeholder="身份证号"
                           class="input short2-input"
                           aria-label="身份证号输入框"/>
                    <input v-model="e100cardIdXLocation" type="number" class="input short3-input"
                           aria-label="左右位置调整" v-if="isShowE100Postion"/>
                    <!--                    <input v-model="cardIdYLocation" type="number" class="input short3-input"-->
                    <!--                           aria-label="上下位置调整" v-if="isShowPostion"/>-->
                </div>
                <!-- 出生日期 -->
                <div class="input-group">
                    <label for="birthDate">出生日期:</label>
                    <input v-model="e100birthDate" type="text" id="birthDate"
                           :placeholder="getPatternTime('yyyy-MM-dd')"
                           class="input short2-input" aria-label="出生日期输入框"/>
                    <input v-model="e100birthDateXLocation" type="number" class="input short3-input"
                           aria-label="左右位置调整" v-if="isShowE100Postion"/>
                    <!--                    <input v-model="birthDateYLocation" type="number" class="input short3-input"-->
                    <!--                           aria-label="上下位置调整" v-if="isShowPostion"/>-->
                </div>
                <!-- 证书编号 -->
                <div class="input-group">
                    <label for="certNo">证书编号:</label>
                    <input v-model="e100certNo" type="text" id="certNo"
                           :placeholder="'JSAC'+getPatternTime('yyyyMMddHHmmss')"
                           class="input short2-input"
                           aria-label="证书号输入框"/>
                    <input v-model="e100certNoXLocation" type="number" class="input short3-input"
                           aria-label="左右位置调整" v-if="isShowE100Postion"/>
                    <!--                    <input v-model="certNoYLocation" type="number" class="input short3-input"-->
                    <!--                           aria-label="上下位置调整" v-if="isShowPostion"/>-->
                </div>
                <!-- 出生日期 -->
                <div class="input-group">
                    <label for="birthDate">颁证日期:</label>
                    <input v-model="e100year" type="text" id="birthDate" :placeholder="getPatternTime('yyyy-MM-dd')"
                           class="input short2-input" aria-label="颁证日期输入框"/>
                    <input v-model="e100yearXLocation" type="number" class="input short3-input"
                           aria-label="左右位置调整" v-if="isShowE100Postion"/>
                    <!--                    <input v-model="e100yearYLocation" type="number" class="input short3-input"-->
                    <!--                           aria-label="上下位置调整" v-if="isShowPostion"/>-->
                </div>
                <!-- 职业名称 -->
                <div class="input-group">
                    <label for="certName">证书名称:</label>
                    <input v-model="e100certName" type="text" id="certName" placeholder="焊工、电工..."
                           class="input short2-input" aria-label="证书名称输入框"/>
                    <input v-model="e100certNameXLocation" type="number" class="input short3-input"
                           aria-label="左右位置调整" v-if="isShowE100Postion"/>
                    <!--                    <input v-model="e100certNameYLocation" type="number" class="input short3-input"-->
                    <!--                           aria-label="上下位置调整" v-if="isShowPostion"/>-->
                </div>

                <!-- 级别 -->
                <div class="input-group">
                    <label for="level">职业等级:</label>
                    <input v-model="e100level" type="text" id="level" placeholder="一级、二级..."
                           class="input short2-input"
                           aria-label="级别输入框"/>
                    <input v-model="e100levelXLocation" type="number" class="input short3-input"
                           aria-label="左右位置调整"
                           v-if="isShowE100Postion"/>
                    <!--                    <input v-model="e100levelYLocation" type="number" class="input short3-input" aria-label="上下位置调整"-->
                    <!--                           v-if="isShowPostion"/>-->
                </div>

                <!-- 年份 -->
                <div class="input-group">
                    <label for="Score">理论成绩:</label>
                    <input v-model="e100theoryScore" type="text" id="e100theoryScore" class="input short3-input"
                           placeholder="99" aria-label="理论成绩输入框"/> 技能成绩:
                    <input v-model="e100assessScore" type="text" id="e100assessScore" class="input short3-input"
                           placeholder="98"
                           aria-label="技能成绩输入框"/>
                </div>
                <!-- 工作单位 -->
                <div class="input-group">
                    <label for="level">工作单位:</label>
                    <input v-model="e100workAddress" type="text" id="workAddress"
                           placeholder="安徽省合肥市xx区xx路xx小区xx栋xx室" class="input short2-input"
                           aria-label="级别输入框"/>
                    <input v-model="e100workAddressXLocation" type="number" class="input short3-input"
                           aria-label="左右位置调整"
                           v-if="isShowE100Postion"/>
                    <!--                    <input v-model="e100levelYLocation" type="number" class="input short3-input" aria-label="上下位置调整"-->
                    <!--                           v-if="isShowPostion"/>-->
                </div>


                <!-- 提交按钮 -->
                <button @click="submitEHunderCert" class="submit-btn submit-btn-e100" aria-label="创建电子版按钮">
                    创建100的电子版
                </button>

                <!-- 显示图片 -->
                <div v-if="e100fileImg" class="image-container">
                    <img :src="e100fileImg" alt="个人信息证书图片" class="image" @click="openFullScreen(e100fileImg)"
                         aria-label="查看个人信息证书图片"/>
                </div>
                <!-- From Uiverse.io by Nawsome -->
                <!-- From Uiverse.io by kerolos23 -->
                <div v-if="isLoading" class="loader"></div>
            </div>

            <!-- 第四部分 -->
            <div class="cert-section cert-section-info">
                <div style="position: absolute">
                    <label class="switch">
                        <input class="cb" type="checkbox" style="opacity: 0.01" v-model="isShow150Postion"/>
                        <span class="toggle">
                        <span class="left">❌</span><span class="right">✅</span>
                        </span>
                    </label>
                </div>
                <h3 class="section-title" style="flex: 3">150电子版制作</h3>

                <!-- 上传照片 -->
                <div class="input-group">
                    <label for="photo2" class="upload-label">上传照片:</label>
                    <div class="file-input-container">
                        <input type="file" id="photo2" @change="handleFileChange" class="file-input" data-source="150"
                               aria-label="上传照片2"/>
                        <label for="photo2" class="file-input-label" aria-label="选择照片按钮2">选择照片</label>
                    </div>
                    <div v-if="image150Preview" class="image-container">
                        <img :src="image150Preview" class="image" alt="Image Preview" aria-label="图片预览2"
                             style="max-width: 200px"/>
                    </div>
                </div>

                <!-- 用户名 -->
                <div class="input-group">
                    <label for="userName">姓名:</label>
                    <input v-model="user150Name" type="text" id="userName" placeholder="输入姓名"
                           class="input short2-input" aria-label="姓名输入框"/>
                    <input v-model="user150NameXLocation" type="number" class="input short3-input"
                           aria-label="左右位置调整" v-if="isShow150Postion"/>
                    <!--                    <input v-model="user150NameYLocation" type="number" class="input short3-input"-->
                    <!--                           aria-label="上下位置调整" v-if="isShow150Postion"/>-->
                </div>
                <!-- 身份证号 -->
                <div class="input-group">
                    <label for="cardId">身份证号:</label>
                    <input v-model="cardId150" type="text" id="cardId" placeholder="身份证号" class="input short2-input"
                           aria-label="身份证号输入框"/>
                    <input v-model="cardId150XLocation" type="number" class="input short3-input"
                           aria-label="左右位置调整" v-if="isShow150Postion"/>
                    <!--                    <input v-model="cardId150YLocation" type="number" class="input short3-input"-->
                    <!--                           aria-label="上下位置调整" v-if="isShow150Postion"/>-->
                </div>

                <!-- 证书名称 -->
                <div class="input-group">
                    <label for="certName">证书名称:</label>
                    <input v-model="cert150Name" type="text" id="certName" placeholder="焊工、电工..."
                           class="input short2-input" aria-label="证书名称输入框"/>
                    <input v-model="cert150NameXLocation" type="number" class="input short3-input"
                           aria-label="左右位置调整" v-if="isShow150Postion"/>
                    <!--                    <input v-model="cert150NameYLocation" type="number" class="input short3-input"-->
                    <!--                           aria-label="上下位置调整" v-if="isShow150Postion"/>-->
                </div>

                <!-- 级别 -->
                <div class="input-group">
                    <label for="level">级别:</label>
                    <input v-model="level150" type="text" id="level" placeholder="高级、中级..."
                           class="input short2-input"
                           aria-label="级别输入框"/>
                    <input v-model="level150XLocation" type="number" class="input short3-input"
                           aria-label="左右位置调整" v-if="isShow150Postion"/>
                    <!--                    <input v-model="level150YLocation" type="number" class="input short3-input"-->
                    <!--                           aria-label="上下位置调整" v-if="isShow150Postion"/>-->
                </div>

                <!-- 证书号 -->
                <div class="input-group">
                    <label for="certNo">证书号:</label>
                    <input v-model="cert150No" type="text" id="certNo"
                           :placeholder="'ABCD'+getPatternTime('yyyyMMdd')+'EFG'"
                           class="input short2-input"
                           aria-label="证书号输入框"/>
                    <input v-model="cert150NoXLocation" type="number" class="input short3-input"
                           aria-label="左右位置调整" v-if="isShow150Postion"/>
                    <!--                    <input v-model="cert150NoYLocation" type="number" class="input short3-input"-->
                    <!--                           aria-label="上下位置调整" v-if="isShow150Postion"/>-->
                </div>

                <!-- 年份 -->
                <div class="input-group">
                    <label for="createTime">发证时间:</label>
                    <input v-model="year150" type="text" id="year" class="input short3-input"
                           aria-label="发证年份输入框"/>年
                    <input v-model="month150" type="text" id="month" class="input short3-input"
                           aria-label="发证月份输入框"/>月
                    <input v-model="day150" type="text" id="day" class="input short3-input"
                           aria-label="发证日期输入框"/>日
                </div>
                <!--                &lt;!&ndash; 证书号 &ndash;&gt;-->
                <!--                <div class="input-group">-->
                <!--                    <label for="certNo">二维码内容:</label>-->
                <!--                    <input v-model="qrCodeInfo" type="text" id="level" placeholder="公示中，等待通过..."-->
                <!--                           class="input short2-input"-->
                <!--                           aria-label="二维码内容输入框"/>-->
                <!--                </div>-->
                <!-- 提交按钮 -->
                <button @click="submitEHundredFiftyCert" class="submit-btn submit-btn-150" aria-label="创建电子版按钮">
                    创建150证书
                </button>

                <!-- 显示图片 -->
                <div v-if="file150Img" class="image-container">
                    <img :src="file150Img" alt="个人信息证书图片" class="image" @click="openFullScreen(file150Img)"
                         aria-label="查看个人信息证书图片"/>
                </div>
            </div>
            <!-- 第五部分 -->
            <div class="cert-section cert-section-query">
                <h3>证书查询</h3>
                <div class="input-group">
                    <label>考生姓名: </label>
                    <input v-model="name4" type="text" placeholder="请输入姓名" class="input short2-input"/>
                </div>
                <div class="input-group">
                    <label>证书编号: </label>
                    <input v-model="certNum" type="text" placeholder="请输入证书编号" class="input short2-input"/>
                </div>
                <div class="input-group">
                    <label>身份证号: </label>
                    <input v-model="idCard" type="text" placeholder="请输入身份证号" class="input short2-input"/>
                </div>
                <div class="input-group">
                    <label>验证码: </label>
                    <input v-model="code" type="text" placeholder="请输入验证码" class="input short2-input"/>
                    <img :src="codeImg" alt="验证码" @click="flushCode" style="scale: 1.5;margin-left: 20px;"/>
                </div>
                <button @click="submitPartFour" aria-label="查询" class="submit-btn submit-btn-query">查询信息</button>
            </div>
        </div>

        <Notification :message="notificationMessage"
                      :visible.sync="isNotificationVisible"
                      :type="notificationType"
                      aria-label="通知组件"/>
    </div>
</template>

<script>
import {
    edit150PicSize,
    getCaptchaImage,
    getCookie,
    getESixtyCert, getHundredFiftyCert,
    getHundredCert, getEHundredFiftyCert,
    getSixtyCert,
    queryCert,
    uploadPic, edit100PicSize, getEHundredCert
} from "@/api/wmjApi";
import Notification from "@/components/Notification.vue";
import Back from "@/components/back.vue";
import {log} from "@/utils/logHelper.js";
import {isIDCard, isName} from "@/utils/checkUtil";
import {getPatternTime, getTime} from "@/utils/parse";


export default {
    components: {Back, Notification},
    data() {
        return {
            // 第一部分数据
            name1: '',
            number1: 800,
            file1Img: '',  // 用于存储部分一图片URL

            // 第二部分数据
            name2: '',
            number2: 560,
            file2Img: '',  // 用于存储部分二图片URL

            // 第三部分数据
            userName: '',
            userNameXLocation: 350,
            userNameYLocation: 1200,
            gender: '',
            genderXLocation: 1000,
            genderYLocation: 1200,
            birthDate: '',
            birthDateXLocation: 530,
            birthDateYLocation: 1500,
            cardId: '',
            cardIdXLocation: 510,
            cardIdYLocation: 1800,
            certName: '',
            certNameXLocation: 2300,
            certNameYLocation: 375,
            level: '',
            levelXLocation: 2250,
            levelYLocation: 680,
            certNo: '',
            certNoXLocation: 2300,
            certNoYLocation: 990,
            year: '',
            yearXLocation: 2180,
            yearYLocation: 1827,
            month: '',
            monthXLocation: 2450,
            monthYLocation: 1827,
            day: '',
            dayXLocation: 2650,
            dayYLocation: 1827,
            certType: '',
            picPath: '',
            file3Img: '',

            // 第四部分数据
            user150Name: '',
            user150NameXLocation: 1185,
            user150NameYLocation: 570,
            cardId150: '',
            cardId150XLocation: 1135,
            cardId150YLocation: 652,
            cert150Name: '',
            cert150NameXLocation: 1185,
            cert150NameYLocation: 735,
            level150: '',
            level150XLocation: 1185,
            level150YLocation: 818,
            cert150No: '',
            cert150NoXLocation: 1150,
            cert150NoYLocation: 900,
            year150: '',
            year150XLocation: 370,
            year150YLocation: 973,
            month150: '',
            month150XLocation: 460,
            month150YLocation: 973,
            day150: '',
            day150XLocation: 520,
            day150YLocation: 973,
            qrCodeInfo: '',
            cert150Type: '',
            pic150Path: '',
            file150Img: '',
            //通知
            isNotificationVisible: false,  // 控制通知的显示/隐藏
            notificationMessage: '',       // 存储通知的消息内容
            notificationType: 'info',      // 存储通知的类型（info、success、error、warning）
            isLoading: false,
            // 第五部分数据
            name4: '',
            idCard: '',
            certNum: '',  // 用于存储部分二图片URL
            code: '', //验证码
            codeImg: '',

            cookie: '',
            selectedFile: null,  // 存储选择的文件
            selected150File: null,  // 存储选择的文件
            selectedE100File: null,  // 存储选择的文件
            imagePreview: null,  // 存储图片预览链接
            image150Preview: null,  // 存储图片预览链接
            imageE100Preview: null,  // 存储图片预览链接
            maxFileSize: 50 * 1024 * 1024,  // 限制图片最大为 2MB
            isShowPostion: false,
            isShow150Postion: false,
            isShowE100Postion: false,
            // 第6部分数据
            name6: '',
            number6: 958,
            file6Img: '',  // 用于存储部分二图片URL

            e100userName: '',
            e100userNameXLocation: 500,
            e100userNameYLocation: 900,
            e100gender: '',
            e100genderXLocation: 890,
            e100genderYLocation: 900,
            e100cardId: '',
            e100cardIdXLocation: 470,
            e100cardIdYLocation: 1030,
            e100certName: '',
            e100certNameXLocation: 1670,
            e100certNameYLocation: 240,
            e100level: '',
            e100levelXLocation: 1730,
            e100levelYLocation: 368,
            e100certNo: '',
            e100certNoXLocation: 470,
            e100certNoYLocation: 1294,
            e100year: '',
            e100yearXLocation: 550,
            e100yearYLocation: 1420,
            e100birthDate: '',
            e100birthDateXLocation: 550,
            e100birthDateYLocation: 1160,
            e100picPath: '',
            e100theoryScore: '',
            e100theoryScoreXLocation: 1875,
            e100theoryScoreYLocation: 505,
            e100assessScore: '',
            e100assessScoreXLocation: 1825,
            e100assessScoreYLocation: 628,
            e100workAddress: '',
            e100workAddressXLocation: 1550,
            e100workAddressYLocation: 885,
            e100qrCodeInfo: '',
            e100fileImg: ''
        };
    },
    methods: {
        getPatternTime,
        cleanData(part) {
            switch (part) {
                case 1:
                    this.resetPart1();
                    break;
                case 2:
                    this.resetPart2();
                    break;
                case 3:
                    this.resetPart3();
                    break;
                case 4:
                    this.resetPart4();
                    break;
                case 0:
                    this.resetPart1();
                    this.resetPart2();
                    this.resetPart3();
                    this.resetPart4();
                    break;
            }
        },
        validateInput(name, idCard) {
            if (!isName(name)) {
                this.setNotification('姓名格式不正确', 'error')
                return false;
            }
            if (!isIDCard(idCard)) {
                this.setNotification('身份证号格式不正确', 'error')
                return false;
            }
            return true;
        },
        submitPartFour() {
            // 使用解构获取表单数据
            const {name4, idCard} = this;
            // 调用 validateInput 校验
            if (!this.validateInput(name4, idCard)) {
                return;  // 如果验证不通过，终止后续操作
            }
            if (!this.code) {
                this.setNotification('请填写验证码', 'error');
                return;
            }
            const certQueryVO = {
                cookie: this.cookie,
                name: this.name4,
                certNum: this.certNum,
                idCard: this.idCard,
                code: this.code
            }
            queryCert(certQueryVO).then(res => {
                const certificateInfo = res;
                // 将数据存储到 localStorage 中
                localStorage.setItem("certificateInfo", JSON.stringify(certificateInfo));
                // 打开页面2
                window.open('certInquire');
                this.flushCode();
            }).catch(err => {
                this.flushCode();  // 刷新验证码
                this.handleError(err);  // 统一处理错误
            });
        },
        // 统一的错误处理方法
        handleError(err) {
            if (err.message.includes('证书不存在')) {
                this.setNotification('证书不存在', 'error');
            }
            if (err.message.includes('验证码不正确哦')) {
                this.setNotification('验证码不正确哦', 'error');
            }
        },
        setNotification(message, type) {
            this.notificationMessage = message;
            this.notificationType = type;
            this.isNotificationVisible = true;
        },
        flushCode() {
            getCaptchaImage(this.cookie).then(res => {
                this.codeImg = res
            })
        },
        getCookie() {
            getCookie().then(res => {
                this.cookie = res
                this.flushCode()
            })
        },

        // 打开全屏显示图片
        openFullScreen(imageUrl) {
            const img = document.createElement('img');
            img.src = imageUrl;
            img.style.maxWidth = '90%';
            img.style.maxHeight = '90%';
            img.style.margin = 'auto';
            img.style.display = 'block';
            img.style.transition = 'transform 0.3s ease-in-out';
            img.onmouseover = () => img.style.transform = 'scale(1.1)';
            img.onmouseout = () => img.style.transform = 'scale(1)';

            const fullScreenDiv = document.createElement('div');
            fullScreenDiv.style.position = 'fixed';
            fullScreenDiv.style.top = '0';
            fullScreenDiv.style.left = '0';
            fullScreenDiv.style.width = '100%';
            fullScreenDiv.style.height = '100%';
            fullScreenDiv.style.backgroundColor = 'rgba(0, 0, 0, 0.95)'; // Slightly darker background
            fullScreenDiv.style.display = 'flex';
            fullScreenDiv.style.alignItems = 'center';
            fullScreenDiv.style.justifyContent = 'center';
            fullScreenDiv.style.overflow = 'auto';
            fullScreenDiv.style.transition = 'opacity 0.3s ease';
            fullScreenDiv.style.opacity = '0';
            fullScreenDiv.style.zIndex = '1000';

            document.body.style.overflow = 'hidden'; // 禁止背景滚动

            fullScreenDiv.appendChild(img);
            document.body.appendChild(fullScreenDiv);

            // Fade in effect
            setTimeout(() => {
                fullScreenDiv.style.opacity = '1';
            }, 0);

            // 点击全屏区域关闭全屏
            fullScreenDiv.onclick = () => {
                fullScreenDiv.style.opacity = '0';
                setTimeout(() => {
                    document.body.removeChild(fullScreenDiv);
                    document.body.style.overflow = ''; // 恢复背景滚动
                }, 300);
            };
        },
        submitSixtyCert() {
            this.setNotification('稍等，正在生成', 'info')
            getSixtyCert(this.name1, this.number1).then(res => {
                this.file1Img = res;
            });
        },
        submitHundredCert() {
            this.setNotification('稍等，正在生成', 'info')
            getHundredCert(this.name2, this.number2).then(res => {
                this.file2Img = res;
            });
        },
        submitHundredFiftyCert() {
            this.setNotification('稍等，正在生成', 'info')
            getHundredFiftyCert(this.name6, this.number6).then(res => {
                this.file6Img = res;
            });
        },
        // 文件选择处理
        handleFileChange(event) {
            const file = event.target.files[0];
            const source = event.target.closest('[data-source]').getAttribute('data-source');
            if (file) {
                // 检查文件类型
                if (!file.type.startsWith('image/')) {
                    this.setNotification('不是图片', 'error')
                    return;
                }
                // 检查文件大小
                if (file.size > this.maxFileSize) {
                    this.setNotification('图片过大，最大支持50M的文件！', 'error')
                    return;
                }

                if (source === 'E') {
                    this.selectedFile = file;
                    this.imagePreview = URL.createObjectURL(file); // 生成图片预览
                }
                if (source === '150') {
                    this.selected150File = file;
                    this.image150Preview = URL.createObjectURL(file); // 生成图片预览
                }
                if (source === 'e100') {
                    this.selectedE100File = file;
                    this.imageE100Preview = URL.createObjectURL(file)
                }
            }
        },
        submitESixtyCert() {
            const {userName, cardId} = this;
            // 调用 validateInput 校验
            if (!this.validateInput(userName, cardId)) {
                return;  // 如果验证不通过，终止后续操作
            }
            this.setNotification('稍等，正在生成', 'info')
            uploadPic(this.selectedFile).then(res => {
                this.picPath = res
                const certVO = {
                    userName: this.userName,
                    userNameXLocation: this.userNameXLocation,
                    userNameYLocation: this.userNameYLocation,
                    gender: this.gender,
                    genderXLocation: this.genderXLocation,
                    genderYLocation: this.genderYLocation,
                    birthDate: this.birthDate,
                    birthDateXLocation: this.birthDateXLocation,
                    birthDateYLocation: this.birthDateYLocation,
                    cardId: this.cardId,
                    cardIdXLocation: this.cardIdXLocation,
                    cardIdYLocation: this.cardIdYLocation,
                    certName: this.certName,
                    certNameXLocation: this.certNameXLocation,
                    certNameYLocation: this.certNameYLocation,
                    level: this.level,
                    levelXLocation: this.levelXLocation,
                    levelYLocation: this.levelYLocation,
                    certNo: this.certNo,
                    certNoXLocation: this.certNoXLocation,
                    certNoYLocation: this.certNoYLocation,
                    year: this.year,
                    yearXLocation: this.yearXLocation,
                    yearYLocation: this.yearYLocation,
                    month: this.month,
                    monthXLocation: this.monthXLocation,
                    monthYLocation: this.monthYLocation,
                    day: this.day,
                    dayXLocation: this.dayXLocation,
                    dayYLocation: this.dayYLocation,
                    certType: this.certType,
                    picPath: this.picPath
                };
                getESixtyCert(certVO).then(res => {
                    this.file3Img = res
                })

            })
        },
        submitEHunderCert() {
            const {e100userName, e100cardId} = this;
            // 调用 validateInput 校验
            if (!this.validateInput(e100userName, e100cardId)) {
                return;  // 如果验证不通过，终止后续操作
            }
            this.setNotification('稍等，正在生成', 'info');
            this.isLoading = true;

            const file = this.selectedE100File
            if (!file.type.startsWith('image/')) {
                this.setNotification('照片呢？', 'error')
                return;
            }
            // 异常处理
            edit100PicSize(file).then(res => {
                this.e100picPath = res;
                const certVO = {
                    userName: this.e100userName,
                    userNameXLocation: this.e100userNameXLocation,
                    userNameYLocation: this.e100userNameYLocation,
                    gender: this.e100gender,
                    genderXLocation: this.e100genderXLocation,
                    genderYLocation: this.e100genderYLocation,
                    cardId: this.e100cardId,
                    cardIdXLocation: this.e100cardIdXLocation,
                    cardIdYLocation: this.e100cardIdYLocation,
                    certName: this.e100certName,
                    certNameXLocation: this.e100certNameXLocation,
                    certNameYLocation: this.e100certNameYLocation,
                    level: this.e100level,
                    levelXLocation: this.e100levelXLocation,
                    levelYLocation: this.e100levelYLocation,
                    certNo: this.e100certNo,
                    certNoXLocation: this.e100certNoXLocation,
                    certNoYLocation: this.e100certNoYLocation,
                    year: this.e100year,
                    yearXLocation: this.e100yearXLocation,
                    yearYLocation: this.e100yearYLocation,
                    birthDate: this.e100birthDate,
                    birthDateXLocation: this.e100birthDateXLocation,
                    birthDateYLocation: this.e100birthDateYLocation,
                    picPath: this.e100picPath,
                    theoryScore: this.e100theoryScore,
                    theoryScoreXLocation: this.e100theoryScoreXLocation,
                    theoryScoreYLocation: this.e100theoryScoreYLocation,
                    assessScore: this.e100assessScore,
                    assessScoreXLocation: this.e100assessScoreXLocation,
                    assessScoreYLocation: this.e100assessScoreYLocation,
                    workAddress: this.e100workAddress,
                    workAddressXLocation: this.e100workAddressXLocation,
                    workAddressYLocation: this.e100workAddressYLocation,
                    qrCodeInfo: 'http://www.hrgwpxjdzx.com/cert/a/' + this.e100certNo
                };
                getEHundredCert(certVO).then(res => {
                    this.isLoading = false
                    this.e100fileImg = res
                })
            })
        },
        submitEHundredFiftyCert() {
            const {user150Name, cardId150} = this;
            // 调用 validateInput 校验
            if (!this.validateInput(user150Name, cardId150)) {
                return;  // 如果验证不通过，终止后续操作
            }
            this.setNotification('稍等，正在生成', 'info');
            const file = this.selected150File
            if (!file.type.startsWith('image/')) {
                this.setNotification('照片呢？', 'error')
                return;
            }
            // 异常处理
            edit150PicSize(file).then(res => {

                this.pic150Path = res;
                const certVO = {
                    userName: this.user150Name,
                    userNameXLocation: this.user150NameXLocation,
                    userNameYLocation: this.user150NameYLocation,
                    cardId: this.cardId150,
                    cardIdXLocation: this.cardId150XLocation,
                    cardIdYLocation: this.cardId150YLocation,
                    certName: this.cert150Name,
                    certNameXLocation: this.cert150NameXLocation,
                    certNameYLocation: this.cert150NameYLocation,
                    level: this.level150,
                    levelXLocation: this.level150XLocation,
                    levelYLocation: this.level150YLocation,
                    certNo: this.cert150No,
                    certNoXLocation: this.cert150NoXLocation,
                    certNoYLocation: this.cert150NoYLocation,
                    year: this.year150,
                    yearXLocation: this.year150XLocation,
                    yearYLocation: this.year150YLocation,
                    month: this.month150,
                    monthXLocation: this.month150XLocation,
                    monthYLocation: this.month150YLocation,
                    day: this.day150,
                    dayXLocation: this.day150XLocation,
                    dayYLocation: this.day150YLocation,
                    certType: this.cert150Type,
                    picPath: this.pic150Path,
                    qrCodeInfo: 'http://www.zysjnjdzx.com/cert/a/' + this.cert150No
                };

                getEHundredFiftyCert(certVO).then(res => {
                    this.file150Img = res;
                }).catch(error => {
                    console.error("getHundredFiftyPic Error:", error);  // 捕获并处理错误
                    this.setNotification('生成图片失败', 'error');
                });

            }).catch(error => {
                console.error("edit150PicSize Error:", error);  // 捕获并处理错误
                this.setNotification('图片处理失败', 'error');
            });
        },
        setSeo() {
            document.title = "证书生成器 - 创建证书";
            document
                .querySelector('meta[name="description"]')
                .setAttribute("content", "使用证书生成器轻松创建60和100的证书，填写信息即可生成。");
            document
                .querySelector('meta[name="keywords"]')
                .setAttribute("content", "证书生成器, 证书, 个人信息");
        }
        ,
        resetPart1() {
            this.name1 = '';
            this.number1 = 810;
            this.file1Img = '';
        }
        ,
        resetPart2() {
            this.name2 = '';
            this.number2 = 560;
            this.file2Img = '';
        }
        ,
        resetPart3() {
            // 重置字段
            this.userName = '';
            this.gender = '';
            this.birthDate = '';
            this.cardId = '';
            this.certName = '';
            this.level = '';
            this.certNo = '';
            this.year = '';
            this.month = '';
            this.day = '';
            this.certType = '';
            this.picPath = '';
            this.file3Img = '';
            // 重置坐标位置
            this.resetCoordinates();
        }
        ,
        resetPart4() {
            this.name4 = '';
            this.idCard = '';
            this.certNum = '';  // 用于存储部分二图片URL
            this.code = '';
            this.codeImg = '';
        }
        ,
        resetCoordinates() {
            this.userNameXLocation = 350;
            this.userNameYLocation = 1200;
            this.genderXLocation = 1000;
            this.genderYLocation = 1200;
            this.birthDateXLocation = 530;
            this.birthDateYLocation = 1500;
            this.cardIdXLocation = 510;
            this.cardIdYLocation = 1800;
            this.certNameXLocation = 2300;
            this.certNameYLocation = 375;
            this.levelXLocation = 2250;
            this.levelYLocation = 680;
            this.certNoXLocation = 2300;
            this.certNoYLocation = 990;
            this.yearXLocation = 2180;
            this.yearYLocation = 1827;
            this.monthXLocation = 2450;
            this.monthYLocation = 1827;
            this.dayXLocation = 2650;
            this.dayYLocation = 1827;
        }
    },
    mounted() {
        this.getCookie()
        this.setSeo()
    }
}
;
</script>

<style scoped>
.cert-container {
    transition: background-color 0.5s ease, background-image 0.5s ease;
    background-image: linear-gradient(45deg, #ffcccb, #f0f8ff);
    padding: 25px;
    height: 100%;
    border-radius: 10px;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}

.sections-container {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.cert-section {
    flex: 1 1 30%;
    margin: 5px;
    padding: 2px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.cert-section:hover {
    transform: translateY(-3px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.cert-section-r150, .cert-section-100, .cert-section-60 {
    flex: 1;
}

.section-title {
    font-size: 1.8rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
}

.input-group {
    font-family: "Heiti TC";
    font-weight: bold;
    margin-bottom: 30px;
}

.input-group label {
    margin-right: 15px;
}

.input {
    box-sizing: border-box;
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: border-color 0.3s ease;
}

.short-input {
    width: 48%;
}

.short2-input {
    min-width: 300px;
    width: 20%;
}

.short3-input {
    width: 80px;
}

.file-input {
    padding: 8px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 8px;
}

.input:focus {
    border-color: #ff6b6b;
    outline: none;
}

.submit-btn {
    background-color: #6c5ce7;
    min-width: 200px;
    width: 20%;
    padding: 12px;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    margin-bottom: 30px;
}

.submit-btn-60 {
    background-color: #30c43c;
}

.submit-btn-60:hover {
    background-color: #228f2b;
    transform: scale(1.02);
}

.submit-btn-100 {
    background-color: #6c5ce7;
}

.submit-btn-r150 {
    background-color: #e7685c;
}


.submit-btn-100:hover {
    background-color: #4034a4;
    transform: scale(1.02);
}

.submit-btn-r150:hover {
    background-color: #ff4230;
    transform: scale(1.02);
}

.submit-btn-e60 {
    background-color: #38cdda;
}

.submit-btn-150 {
    background-color: #423b37;
}

.submit-btn-e100 {
    background-color: #b12ed5;
}

.submit-btn-e60:hover {
    background-color: #2a99a2;
    transform: scale(1.02);
}

.submit-btn-150:hover {
    background-color: #000000;
}

.submit-btn-e100:hover {
    background-color: #a705d3;
}

.submit-btn-query {
    background-color: #5e96ff;
}

.submit-btn-query:hover {
    background-color: #4485ff;
    transform: scale(1.02);
}

.image-container {
    margin-top: 15px;
}

.image {
    max-width: 100%;
    border-radius: 5px;
    transition: transform 0.3s ease;
}

.image:hover {
    transform: scale(1.05);
}

/* Responsive design */
@media (max-width: 500px) {
    .sections-container {
        flex-direction: column;
    }

    .cert-section {
        flex: 1 1 100%;
    }
}

/* 上传照片文本 */
.upload-label {
    font-size: 1rem;
    color: #333;
    margin-bottom: 10px;
    font-weight: bold;
}

.file-input-container {
    position: relative;
    display: inline-block;
}

.file-input {
    display: none;
}

.file-input-label {
    display: inline-block;
    background-color: #ff5151;
    color: white;
    font-size: 1rem;
    padding: 12px 20px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    width: auto;
    text-align: center;
}

.file-input-label:hover {
    background-color: #ff48db;
    transform: scale(1.05);
}

.gender-option {
    display: inline-block;
    padding: 10px 20px;
    margin-right: 10px;
    border: 2px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, border-color 0.3s ease;
}

.gender-option.selected {
    background-color: #3c3e3f; /* 选中的背景色 */
    border-color: #4CAF50; /* 选中的边框颜色 */
    color: white; /* 选中的文字颜色 */
}

.gender-option:hover {
    background-color: #e12b2b; /* 鼠标悬停时的背景色 */
    color: white; /* 选中的文字颜色 */

}

/* From Uiverse.io by r7chardgh */
/* The switch - the box around the slider */
.switch {
    font-size: 17px;
    position: relative;
    display: inline-block;
    width: 5em;
    height: 2.5em;
    user-select: none;
}


/* The slider */
.toggle {
    position: absolute;
    cursor: pointer;
    width: 100%;
    height: 100%;
    background-color: #373737;
    border-radius: 0.1em;
    transition: 0.4s;
    margin-left: -30px;
    text-transform: uppercase;
    font-weight: 700;
    overflow: hidden;
    box-shadow: -0.3em 0 0 0 #373737, -0.3em 0.3em 0 0 #373737,
    0.3em 0 0 0 #373737, 0.3em 0.3em 0 0 #373737, 0 0.3em 0 0 #373737;
}

.toggle > .left {
    position: absolute;
    display: flex;
    width: 50%;
    height: 88%;
    background-color: #f3f3f3;
    color: #373737;
    left: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
    transform-origin: right;
    transform: rotateX(10deg);
    transform-style: preserve-3d;
    transition: all 150ms;
}

.left::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgb(206, 206, 206);
    transform-origin: center left;
    transform: rotateY(90deg);
}

.left::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgb(112, 112, 112);
    transform-origin: center bottom;
    transform: rotateX(90deg);
}

.toggle > .right {
    position: absolute;
    display: flex;
    width: 50%;
    height: 88%;
    background-color: #f3f3f3;
    color: rgb(206, 206, 206);
    right: 1px;
    bottom: 0;
    align-items: center;
    justify-content: center;
    transform-origin: left;
    transform: rotateX(10deg) rotateY(-45deg);
    transform-style: preserve-3d;
    transition: all 150ms;
}

.right::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgb(206, 206, 206);
    transform-origin: center right;
    transform: rotateY(-90deg);
}

.right::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgb(112, 112, 112);
    transform-origin: center bottom;
    transform: rotateX(90deg);
}

.switch input:checked + .toggle > .left {
    transform: rotateX(10deg) rotateY(45deg);
    color: rgb(206, 206, 206);
}

.switch input:checked + .toggle > .right {
    transform: rotateX(10deg) rotateY(0deg);
    color: #487bdb;
}
/* From Uiverse.io by kerolos23 */
.loader {
    transform: rotateZ(45deg);
    perspective: 1000px;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    color: #91ff00;
}

.loader:before,
.loader:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: inherit;
    height: inherit;
    border-radius: 50%;
    transform: rotateX(70deg);
    animation: 1s spin linear infinite;
}

.loader:after {
    color: #FF3D00;
    transform: rotateY(70deg);
    animation-delay: .4s;
}

@keyframes rotate {
    0% {
        transform: translate(-50%, -50%) rotateZ(0deg);
    }

    100% {
        transform: translate(-50%, -50%) rotateZ(360deg);
    }
}

@keyframes rotateccw {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
        transform: translate(-50%, -50%) rotate(-360deg);
    }
}

@keyframes spin {
    0%,
    100% {
        box-shadow: .2em 0px 0 0px currentcolor;
    }

    12% {
        box-shadow: .2em .2em 0 0 currentcolor;
    }

    25% {
        box-shadow: 0 .2em 0 0px currentcolor;
    }

    37% {
        box-shadow: -.2em .2em 0 0 currentcolor;
    }

    50% {
        box-shadow: -.2em 0 0 0 currentcolor;
    }

    62% {
        box-shadow: -.2em -.2em 0 0 currentcolor;
    }

    75% {
        box-shadow: 0px -.2em 0 0 currentcolor;
    }

    87% {
        box-shadow: .2em -.2em 0 0 currentcolor;
    }
}

</style>
