<template>
    <div class="cert_container">
        <back></back>
        <!-- 页面标题 -->
        <h1 class="title">职业技能人才数据库</h1>

        <!-- 证书信息展示 -->
        <div class="certificate-info">
            <div class="image-container">
                <!-- 使用动态绑定显示图片，图片大小固定 -->
                <div class="image-container">
                    <img v-if="certificateInfo.imgUrl" :src="certificateInfo.imgUrl" alt="证书图片" class="certificate-image">
                    <img v-else src="@/assets/img/wmjCertPhoto.png" alt="默认证书图片" class="certificate-image">
                </div>
            </div>
            <table class="info-table">
                <tbody>
                <tr>
                    <td>姓名:</td>
                    <td><span class="info-content">{{ certificateInfo.name }}</span></td>
                </tr>
                <tr>
                    <td>身份证号:</td>
                    <td><span class="info-content">{{ certificateInfo.idCard }}</span></td>
                </tr>
                <tr>
                    <td>专业名称:</td>
                    <td><span class="info-content">{{ certificateInfo.certName }}</span></td>
                </tr>
                <tr>
                    <td>技能等级:</td>
                    <td><span class="info-content">{{ certificateInfo.level }}</span></td>
                </tr>
                <tr>
                    <td>证书编号:</td>
                    <td><span class="info-content">{{ certificateInfo.certNum }}</span></td>
                </tr>
                <tr>
                    <td>发证时间:</td>
                    <td><span class="info-content">{{ certificateInfo.certDate }}</span></td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import Back from "@/components/back.vue";
import {log} from "@/utils/logHelper.js";

export default {
    components: {Back},
    data() {
        return {
            // 证书信息数据
            certificateInfo: {
                name: '',
                idCard: '',
                certName: '',
                level: '',
                certNum: '',
                certDate: '',
                imgUrl: ''
            }
        };
    },
    mounted() {
        this.initData()
    },
    methods: {
        initData(){
            const storedData = localStorage.getItem("certificateInfo");
            // 如果数据存在，将其解析并赋值给 certificateInfo
            if (storedData) {
                this.certificateInfo = JSON.parse(storedData);
                localStorage.removeItem("certificateInfo")
            }
        }
    }
};
</script>

<style scoped>
/* 页面整体背景颜色 */
.cert_container {
    background-color: #f4f4f9; /* 更柔和的背景色 */
    padding: 40px;
    margin-left: 1%;
    display: flex;
    height: 90vh;
    flex-direction: column;
    align-items: center;
    border-radius: 12px;
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.15); /* 增强的阴影，突出效果 */
}

/* 页面标题样式 */
.title {
    text-align: center;
    font-size: 40px; /* 增加字体大小 */
    font-weight: 700;
    color: #333;
    margin-bottom: 50px; /* 增加下方间距 */
    font-family: 'Georgia', serif; /* 更典雅的字体 */
}

/* 证书信息容器 */
.certificate-info {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 1000px;
    margin: 0 auto;
    background: linear-gradient(to bottom right, #fff, #f0f8ff); /* 渐变背景 */
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.15); /* 更强的阴影 */
}

/* 图片容器样式 */
.image-container {
    margin-right: 30px; /* 减少与文本的间距 */
}

.certificate-image {
    height: 240px; /* 固定图片高度 */
    border-radius: 8px; /* 圆角处理 */
    object-fit: cover; /* 确保图片不会被拉伸或变形 */
}

/* 表格样式 */
.info-table {
    width: 100%;
    border-collapse: collapse;
    margin-left: 20px; /* 左侧间距 */
    font-family: 'Arial', sans-serif; /* 更现代的字体 */
}

.info-table td {
    padding: 18px; /* 增加内边距，避免内容紧贴边框 */
    font-size: 20px; /* 增加字体大小 */
    line-height: 1.8; /* 提升可读性 */
}

.info-table td:first-child {
    width: 120px; /* 固定左侧描述的宽度 */
    font-weight: 600;
    color: #555;
}

.info-content {
    font-weight: 500;
    color: #333;
}

/* 响应式设计 */
@media screen and (max-width: 768px) {
    .certificate-info {
        flex-direction: column;
        align-items: center;
        text-align: center;
        max-width: 100%; /* 限制宽度 */
        padding: 20px; /* 缩小内边距 */
    }

    .image-container {
        margin-right: 0;
        margin-bottom: 20px;
    }

    .info-table td {
        font-size: 18px; /* 调整为稍小的字体大小 */
    }

    .info-table td:first-child {
        width: auto; /* 不固定描述的宽度 */
    }
}
</style>
