<template>
    <div class="dream-container">
        <!-- 选择奖金金额部分 -->
        <section class="lottery-selection" v-show="isSelectingAmount">
            <h1 class="main-title">白日梦计划</h1>
            <p class="subtitle">选择一个中奖金额,开始想钱怎么花</p>

            <div class="lottery-amounts">
                <button
                    v-for="(option, index) in prizeOptions"
                    :key="index"
                    class="amount-btn"
                    @click="selectAmount(option.value)"
                >
                    <span class="amount-label">{{ option.label }}</span>
                    <small class="amount-detail">{{ formatCurrency(option.value) }}</small>
                </button>
            </div>
        </section>

        <!-- 梦想清单部分 -->
        <section v-show="!isSelectingAmount" class="dream-list">
            <header class="dream-header">
                <h2 class="section-title">计划清单</h2>
                <div class="total-amount">
                    总金额: <span>{{ formatCurrency(totalAmount) }}</span>
                </div>
            </header>

            <div class="dreams-container">
                <transition-group name="dream-item" tag="div">
                    <div v-for="(dream, index) in dreamList"
                         :key="index"
                         class="dream-item"
                         :class="{'shake-animation': dream.isShaking}"
                    >
                        <div class="dream-content">
                            <div class="dream-number">第{{ index + 1 }}件事</div>
                            <div class="dream-input-group">
                                <div class="dream-row">
                                    <div class="dream-description-wrapper">
                                        <textarea
                                            v-model="dream.description"
                                            class="dream-description"
                                            placeholder="干点什么..."
                                            rows="1"
                                            @input="autoGrow($event)"
                                            @focus="dream.isFocused = true"
                                            @blur="dream.isFocused = false"
                                        ></textarea>
                                    </div>
                                    <div class="dream-cost-wrapper">
                                        <div class="cost-input">
                                            <span class="currency-symbol">¥</span>
                                            <input
                                                v-model.number="dream.cost"
                                                type="number"
                                                min="0"
                                                :max="remainingBudget + dream.cost"
                                                placeholder="预计花费"
                                                @input="updateBudget(dream)"
                                                @focus="dream.isFocused = true"
                                                @blur="dream.isFocused = false"
                                            />
                                        </div>
                                        <button class="remove-btn"
                                                @click="removeDream(index)"
                                                v-if="dreamList.length > 1"
                                                title="删除">
                                            <i class="delete-icon"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition-group>
            </div>

            <footer class="dream-footer">
                <button class="add-dream-btn"
                        @click="addDream"
                        :disabled="remainingBudget <= 0">
                    <span class="plus-icon">+</span> 添加新计划
                </button>

                <div class="budget-status" :class="{'warning': remainingBudget < 0}">
                    <span>剩余预算:</span>
                    <strong>{{ formatCurrency(remainingBudget) }}</strong>
                </div>
            </footer>
        </section>
    </div>
</template>

<script>
export default {
    name: 'dayDream',
    data() {
        return {
            isSelectingAmount: true,
            totalAmount: 0,
            dreamList: [{ description: '', cost: 0, isFocused: false, isShaking: false }],
            prizeOptions: [
                { label: '50万', value: 500000 },
                { label: '100万', value: 1000000},
                { label: '500万', value: 5000000 },
                { label: '1000万', value: 10000000 },
                { label: '5000万', value: 50000000 },
                { label: '1亿', value: 100000000 },
                { label: '10亿', value: 1000000000 }
            ]
        }
    },
    computed: {
        remainingBudget() {
            const totalSpent = this.dreamList.reduce((sum, dream) => sum + (Number(dream.cost) || 0), 0)
            return this.totalAmount - totalSpent
        }
    },
    mounted() {
        this.setSeo()
    },
    methods: {
        setSeo() {
            document.title = "白日梦规划器 - 规划不同金额实现的梦想与目标";
            document
                .querySelector('meta[name="description"]')
                .setAttribute("content", "使用我们的白日梦规划器，轻松规划不同金额可以实现的梦想和目标。无论是旅行、买房、创业还是提升个人能力，我们都能帮你计算出实现目标所需的金额，让梦想变成可能。");
            document
                .querySelector('meta[name="keywords"]')
                .setAttribute("content", "白日梦规划器, 梦想规划, 目标设定, 理财规划, 梦想清单, 金额规划, 实现梦想, 财务规划");
        },
        selectAmount(amount) {
            this.totalAmount = amount
            this.isSelectingAmount = false
            this.$nextTick(() => {
                this.focusFirstDream()
            })
        },
        addDream() {
            if (this.remainingBudget > 0) {
                this.dreamList.push({ description: '', cost: 0, isFocused: false, isShaking: false })
                this.$nextTick(() => {
                    const textareas = document.querySelectorAll('.dream-description')
                    textareas[textareas.length - 1].focus()
                })
            }
        },
        removeDream(index) {
            this.dreamList.splice(index, 1)
        },
        formatCurrency(value) {
            return new Intl.NumberFormat('zh-CN', {
                style: 'currency',
                currency: 'CNY',
                minimumFractionDigits: 0
            }).format(value)
        },
        updateBudget(dream) {
            this.$nextTick(() => {
                if (this.remainingBudget < 0) {
                    dream.isShaking = true
                    setTimeout(() => {
                        dream.isShaking = false
                    }, 820)
                    this.$emit('budget-exceeded')
                }
            })
        },
        focusFirstDream() {
            const firstTextarea = document.querySelector('.dream-description')
            if (firstTextarea) firstTextarea.focus()
        },
        autoGrow(event) {
            event.target.style.height = 'auto'
            event.target.style.height = (event.target.scrollHeight) + 'px'
        }
    }
}
</script>

<style scoped>
.dream-container {
    width: 100vw;
    min-height: 100vh;
    padding: 2rem;
    background: linear-gradient(135deg, #fff8e1 0%, #ffe082 100%);
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
}

.dream-container::before {
    content: '💰';
    position: absolute;
    top: -10%;
    left: -10%;
    right: -10%;
    bottom: -10%;
    font-size: 24px;
    background:
        repeating-linear-gradient(
            45deg,
            transparent,
            transparent 100px,
            rgba(255,255,255,0.1) 100px,
            rgba(255,255,255,0.1) 200px
        );
    animation: moneyRain 20s linear infinite;
    z-index: 0;
}

@keyframes moneyRain {
    0% {
        transform: translate(0, -10%) rotate(0deg);
    }
    100% {
        transform: translate(-20%, 110%) rotate(20deg);
    }
}

.main-title {
    font-size: 4rem;
    color: #f4b800;
    text-align: center;
    margin-bottom: 1rem;
    font-weight: 800;
    text-shadow: 2px 2px 4px rgba(0,0,0,0.1);
    letter-spacing: -0.5px;
    position: relative;
    animation: titleFloat 3s ease-in-out infinite;
}

.subtitle {
    text-align: center;
    color: #d4a017;
    margin-bottom: 3rem;
    font-size: 1.5rem;
    position: relative;
    animation: subtitleFade 2s ease-in-out;
}

.lottery-amounts {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.5rem;
    padding: 2rem;
    max-width: 1600px;
    margin: 0 auto;
    position: relative;
}

.amount-btn {
    flex: 1;
    min-width: 160px;
    max-width: 240px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.8rem 1.5rem;
    border: none;
    border-radius: 16px;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0 10px 20px rgba(244, 184, 0, 0.15);
    transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    cursor: pointer;
    backdrop-filter: blur(5px);
    position: relative;
    overflow: hidden;
}

.amount-btn::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: linear-gradient(45deg, transparent, rgba(255,255,255,0.3), transparent);
    transform: rotate(45deg);
    transition: all 0.6s;
    opacity: 0;
}

.amount-btn:hover::before {
    opacity: 1;
    transform: rotate(45deg) translate(50%, 50%);
}

.amount-btn .amount-label {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 0.3rem;
    color: #f4b800;
    transition: all 0.3s;
}

.amount-btn .amount-detail {
    font-size: 1rem;
    color: #4a5568;
    transition: all 0.3s;
}

.amount-btn:hover {
    transform: translateY(-5px) scale(1.02);
    box-shadow: 0 15px 30px rgba(244, 184, 0, 0.25);
    background: rgba(255, 255, 255, 0.95);
}

.amount-btn.highlight {
    background: linear-gradient(135deg, #ffd700, #ffb700);
    color: white;
}

.amount-btn.highlight:hover {
    background: linear-gradient(135deg, #ffb700, #ffa500);
}

.dream-list {
    background: rgba(255, 255, 255, 0.9);
    border-radius: 24px;
    padding: 2.5rem;
    box-shadow: 0 15px 40px rgba(244, 184, 0, 0.2);
    max-width: 1600px;
    margin: 0 auto;
    position: relative;
    backdrop-filter: blur(10px);
    animation: listAppear 0.6s ease-out;
}

.dream-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 2px solid #e2e8f0;
}

.section-title {
    color: #f4b800;
    font-size: 2rem;
    font-weight: 700;
    position: relative;
}

.section-title::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 40%;
    height: 3px;
    background: linear-gradient(90deg, #ffd700, transparent);
}

.total-amount {
    font-size: 1.5rem;
    color: #f4b800;
    font-weight: 600;
    padding: 0.5rem 1rem;
    background: rgba(255, 215, 0, 0.15);
    border-radius: 12px;
    transition: all 0.3s;
}

.dream-content {
    background: rgba(247, 250, 252, 0.8);
    border-radius: 16px;
    padding: 1.5rem;
    transition: all 0.3s ease;
    border: 2px solid transparent;
    position: relative;
    overflow: hidden;
}

.dream-content::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 3px;
    background: linear-gradient(90deg, #ffd700, #ffb700);
    transform: scaleX(0);
    transition: transform 0.3s ease;
}

.dream-content:hover::before {
    transform: scaleX(1);
}

.dream-content:hover {
    background: rgba(237, 242, 247, 0.9);
    border-color: #ffd700;
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(244, 184, 0, 0.15);
}

.dream-number {
    font-weight: 700;
    color: #f4b800;
    margin-bottom: 1rem;
    font-size: 1.1rem;
    display: inline-block;
    padding: 0.3rem 0.8rem;
    background: rgba(255, 215, 0, 0.15);
    border-radius: 8px;
}

.dream-input-group {
    width: 100%;
}

.dream-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1.2rem;
}

.dream-description-wrapper {
    flex: 2;
    min-width: 0;
}

.dream-description {
    width: 100%;
    padding: 0.75rem 1.2rem;
    border: 2px solid #bdc3c7;
    border-radius: 12px;
    font-size: 1.1rem;
    transition: all 0.3s ease;
    resize: none;
    overflow: hidden;
    min-height: 2.8rem;
    line-height: 1.5;
    font-family: inherit;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.9);
}

.dream-description:focus {
    border-color: #ffd700;
    box-shadow: 0 0 0 3px rgba(255, 215, 0, 0.2);
    outline: none;
    background: white;
}

.dream-cost-wrapper {
    display: flex;
    align-items: center;
    gap: 1rem;
    min-width: 160px;
    max-width: 200px;
    flex: 1;
}

.cost-input {
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;
    min-width: 0;
}

.currency-symbol {
    position: absolute;
    left: 1rem;
    color: #7f8c8d;
    font-weight: 600;
}

.cost-input input {
    width: 100%;
    padding: 0.75rem 1rem 0.75rem 2.2rem;
    border: 2px solid #bdc3c7;
    border-radius: 12px;
    font-size: 1.1rem;
    transition: all 0.3s ease;
    box-sizing: border-box;
    height: 2.8rem;
    background: rgba(255, 255, 255, 0.9);
}

.cost-input input:focus {
    border-color: #ffd700;
    box-shadow: 0 0 0 3px rgba(255, 215, 0, 0.2);
    outline: none;
    background: white;
}

.remove-btn {
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 50%;
    background: #ecf0f1;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    flex-shrink: 0;
    position: relative;
    overflow: hidden;
}

.remove-btn::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle, #e74c3c, transparent);
    opacity: 0;
    transition: all 0.3s;
}

.remove-btn:hover::before {
    opacity: 1;
}

.remove-btn:hover {
    background: #e74c3c;
    transform: rotate(90deg);
}

.delete-icon {
    display: inline-block;
    width: 18px;
    height: 18px;
    background: currentColor;
    mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/%3E%3C/svg%3E") no-repeat 50% 50%;
    -webkit-mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/%3E%3C/svg%3E") no-repeat 50% 50%;
    transition: all 0.3s;
}

.remove-btn:hover .delete-icon {
    background: white;
    transform: scale(1.2);
}

.dream-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 3rem;
    padding-top: 2rem;
    border-top: 2px solid #e2e8f0;
    position: relative;
}

.dream-footer::before {
    content: '';
    position: absolute;
    top: -2px;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, #ffd700, transparent);
}

.budget-status {
    font-size: 1.4rem;
    color: #f4b800;
    font-weight: 500;
    padding: 0.5rem 1rem;
    border-radius: 12px;
    background: rgba(255, 215, 0, 0.15);
    transition: all 0.3s;
}

.budget-status.warning {
    color: #e74c3c;
    background: rgba(231, 76, 60, 0.1);
    animation: pulse 2s infinite;
}

.add-dream-btn {
    padding: 1rem 2rem;
    background: linear-gradient(135deg, #ffd700, #ffb700);
    color: white;
    border: none;
    border-radius: 12px;
    font-size: 1.2rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    gap: 0.8rem;
    position: relative;
    overflow: hidden;
}

.add-dream-btn::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: linear-gradient(45deg, transparent, rgba(255,255,255,0.3), transparent);
    transform: rotate(45deg);
    transition: all 0.6s;
    opacity: 0;
}

.add-dream-btn:hover::before {
    opacity: 1;
    transform: rotate(45deg) translate(50%, 50%);
}

.add-dream-btn:hover:not(:disabled) {
    transform: translateY(-3px);
    box-shadow: 0 8px 15px rgba(244, 184, 0, 0.3);
    background: linear-gradient(135deg, #ffb700, #ffa500);
}

.add-dream-btn:disabled {
    background: linear-gradient(135deg, #bdc3c7, #95a5a6);
    cursor: not-allowed;
    opacity: 0.7;
}

.plus-icon {
    font-size: 1.4rem;
    font-weight: bold;
    transition: all 0.3s;
}

.add-dream-btn:hover .plus-icon {
    transform: rotate(180deg);
}

/* Animations */
@keyframes titleFloat {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-10px); }
}

@keyframes subtitleFade {
    from { opacity: 0; transform: translateY(20px); }
    to { opacity: 1; transform: translateY(0); }
}

@keyframes listAppear {
    from { opacity: 0; transform: translateY(30px); }
    to { opacity: 1; transform: translateY(0); }
}

.dream-item-enter-active,
.dream-item-leave-active {
    transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1);
}

.dream-item-enter-from,
.dream-item-leave-to {
    opacity: 0;
    transform: translateY(-30px);
}

@keyframes shake {
    0%, 100% { transform: translateX(0); }
    10%, 30%, 50%, 70%, 90% { transform: translateX(-5px); }
    20%, 40%, 60%, 80% { transform: translateX(5px); }
}

.shake-animation {
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
}

@keyframes pulse {
    0% { opacity: 1; transform: scale(1); }
    50% { opacity: 0.8; transform: scale(0.95); }
    100% { opacity: 1; transform: scale(1); }
}

@media (max-width: 768px) {
    .dream-container {
        padding: 1rem;
    }

    .main-title {
        font-size: 2.5rem;
    }

    .subtitle {
        font-size: 1.2rem;
    }

    .lottery-amounts {
        flex-direction: column;
        align-items: stretch;
    }

    .amount-btn {
        max-width: none;
    }

    .dream-row {
        flex-direction: column;
        gap: 1rem;
    }

    .dream-cost-wrapper {
        width: 100%;
        min-width: unset;
        max-width: none;
    }

    .dream-description-wrapper {
        flex: 1;
    }

    .cost-input {
        flex: 1;
    }

    .dream-footer {
        flex-direction: column;
        gap: 1.5rem;
        text-align: center;
    }

    .add-dream-btn {
        width: 100%;
        justify-content: center;
    }
}
/* 清除输入框的数字按钮样式 */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
</style>