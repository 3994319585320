<template>
    <div class="calculator-container">
        <h2 class="title">经期计算器</h2>

        <div class="input-section">
            <h3>输入信息</h3>
            <div class="input-group">
                <label for="lastPeriod">上次月经开始日期：</label>
                <input type="date" v-model="lastPeriod" id="lastPeriod" class="input-field" />
            </div>

            <div class="input-group">
                <label for="cycleLength">周期天数：</label>
                <input type="number" v-model="cycleLength" id="cycleLength" min="21" max="35" class="input-field" />
                <small class="hint-text">标准周期为28天</small>
            </div>

            <div class="input-group">
                <label for="periodLength">经期天数：</label>
                <input type="number" v-model="periodLength" id="periodLength" min="3" max="7" class="input-field" />
                <small class="hint-text">经期通常为5天左右</small>
            </div>

            <div class="input-group history-input">
                <button @click="toggleHistoryInput" class="history-button" :class="{ 'active': showHistoryInput }">
                    {{ showHistoryInput ? '隐藏历史数据输入' : '添加历史数据' }}
                </button>
                <transition name="fade">
                    <div v-if="showHistoryInput" class="history-data">
                        <label for="historyPeriod">历史周期开始日期：</label>
                        <input type="date" v-model="historyPeriod" class="input-field" />
                        <label for="historyCycle">历史周期天数：</label>
                        <input type="number" v-model="historyCycle" min="21" max="35" class="input-field" />
                        <button @click="addHistoryData" class="add-history-button">添加历史数据</button>
                    </div>
                </transition>
            </div>

            <div class="button-group">
                <button @click="calculateDates" class="calculate-button">计算</button>
            </div>
        </div>

        <transition name="slide-fade">
            <div v-if="predictedDates.length" class="results-section">
                <h3>计算结果：</h3>
                <div class="results-container">
                    <div v-for="(date, index) in predictedDates" :key="index" class="result-item">
                        <div class="result-box" @mouseover="hoverEffect" @mouseleave="resetEffect">
                            <p><strong>第{{ index + 1 }}次月经开始日期：</strong>{{ date.nextPeriod }}</p>
                            <p><strong>排卵期：</strong>{{ date.ovulationPeriod.start }} - {{ date.ovulationPeriod.end }}</p>
                            <p><strong>经期：</strong>{{ date.periodStart }} - {{ date.periodEnd }}</p>
                            <p><strong>安全期：</strong>{{ date.safePeriod.start }} - {{ date.safePeriod.end }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </transition>

        <transition name="slide-fade">
            <div v-if="predictedDates.length" class="chart-section">
                <h3>周期图表：</h3>
                <div class="chart">
                    <div v-for="(date, index) in predictedDates" :key="index" class="chart-item">
                        <p>第{{ index + 1 }}次</p>
                        <div class="period-bar" :style="getStyle(date.periodStart, date.periodEnd)"></div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: "PeriodCalculator",
    data() {
        return {
            lastPeriod: "",
            cycleLength: 30,
            periodLength: 5,
            historyPeriod: "",
            historyCycle: 30,
            predictedDates: [],
            showHistoryInput: false,
        };
    },
    methods: {
        toggleHistoryInput() {
            this.showHistoryInput = !this.showHistoryInput;
        },
        addHistoryData() {
            if (this.historyPeriod && this.historyCycle) {
                this.lastPeriod = this.historyPeriod;
                this.cycleLength = this.historyCycle;
                alert("历史数据已成功添加！");
            } else {
                alert("请完整输入历史数据！");
            }
        },
        calculateDates() {
            if (!this.lastPeriod || this.cycleLength < 21 || this.cycleLength > 35 || this.periodLength < 3 || this.periodLength > 7) {
                alert("请输入有效的日期、周期天数和经期天数");
                return;
            }

            const lastPeriodDate = new Date(this.lastPeriod);
            this.predictedDates = [];

            for (let i = 0; i < 12; i++) {
                const nextPeriodDate = new Date(lastPeriodDate);
                nextPeriodDate.setDate(lastPeriodDate.getDate() + (this.cycleLength * (i + 1)));

                const periodStartDate = new Date(nextPeriodDate);
                const periodEndDate = new Date(periodStartDate);
                periodEndDate.setDate(periodStartDate.getDate() + this.periodLength - 1);

                const ovulationStartDate = new Date(nextPeriodDate);
                ovulationStartDate.setDate(nextPeriodDate.getDate() - 14);
                const ovulationEndDate = new Date(ovulationStartDate);
                ovulationEndDate.setDate(ovulationStartDate.getDate() + 2);

                const safeStartDate = new Date(periodStartDate);
                safeStartDate.setDate(periodStartDate.getDate() - 7);
                const safeEndDate = new Date(periodEndDate);
                safeEndDate.setDate(periodEndDate.getDate() + 7);

                const formatDate = (date) => {
                    const year = date.getFullYear();
                    const month = (date.getMonth() + 1).toString().padStart(2, "0");
                    const day = date.getDate().toString().padStart(2, "0");
                    return `${year}-${month}-${day}`;
                };

                this.predictedDates.push({
                    nextPeriod: formatDate(nextPeriodDate),
                    ovulationPeriod: {
                        start: formatDate(ovulationStartDate),
                        end: formatDate(ovulationEndDate),
                    },
                    periodStart: formatDate(periodStartDate),
                    periodEnd: formatDate(periodEndDate),
                    safePeriod: {
                        start: formatDate(safeStartDate),
                        end: formatDate(safeEndDate),
                    },
                });
            }
        },
        getStyle(start, end) {
            const startDate = new Date(start);
            const endDate = new Date(end);
            const daysInMonth = 30;
            const startOffset = (startDate.getDate() - 1) * 100 / daysInMonth;
            const endOffset = (endDate.getDate() - 1) * 100 / daysInMonth;
            return {
                width: `${endOffset - startOffset}%`,
                left: `${startOffset}%`,
                backgroundColor: "#67C23A",
                height: "12px",
                borderRadius: "6px",
                transition: "width 0.5s ease, left 0.5s ease",
            };
        },
        hoverEffect(event) {
            event.currentTarget.style.transform = "scale(1.05)";
            event.currentTarget.style.transition = "transform 0.3s ease";
        },
        resetEffect(event) {
            event.currentTarget.style.transform = "scale(1)";
        },
    },
};
</script>

<style scoped>
.calculator-container {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.title {
    color: #4CAF50;
    text-align: center;
    margin-bottom: 20px;
}

.input-section {
    margin: 20px 0;
}

.input-group {
    margin-bottom: 15px;
}

.input-field {
    padding: 12px;
    border: 2px solid #4CAF50;
    border-radius: 5px;
    transition: border-color 0.3s ease;
}

.input-field:focus {
    border-color: #67C23A;
}

.history-button {
    background-color: #4CAF50;
    color: white;
    padding: 12px 18px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.history-button.active {
    background-color: #67C23A;
}

.add-history-button {
    background-color: #2196F3;
    color: white;
    padding: 12px 18px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.calculate-button {
    background-color: #FF9800;
    color: white;
    padding: 12px 18px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.results-section {
    margin: 20px 0;
}

.result-item {
    margin-bottom: 10px;
}

.result-box {
    padding: 15px;
    border: 1px solid #4CAF50;
    border-radius: 5px;
    transition: box-shadow 0.3s ease;
}

.result-box:hover {
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    transform: translateY(-2px);
}

.chart-section {
    margin: 20px 0;
}

.chart-item {
    margin-bottom: 10px;
}

.period-bar {
    height: 12px;
    border-radius: 6px;
    transition: all 0.5s ease;
}

.fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
    opacity: 0;
}

.slide-fade-enter-active, .slide-fade-leave-active {
    transition: all 0.5s ease;
}
.slide-fade-enter, .slide-fade-leave-to /* .slide-fade-leave-active in <2.1.8 */ {
    transform: translateY(20px);
    opacity: 0;
}
</style>
