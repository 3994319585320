<template>
    <div :class="{'backGround1': season === 6, 'backGround2': season === 11, 'backGround3': season === 12,'backGround4': season === 13}">
        <back></back>
        <div class="versionSelector">
            <div class="version" 
                @click="changeSeason(6)" 
                :class="{ 'active': season === 6, 'disabled': isFetching }">
                双城之战
            </div>
            <!-- <div class="version" 
                @click="changeSeason(9)" 
                :class="{ 'active': season === 9, 'disabled': isFetching }">
                符文大陆传奇
            </div> -->
            <div class="version" 
                @click="changeSeason(11)" 
                :class="{ 'active': season === 11, 'disabled': isFetching }">
                画之灵
            </div>
            <div class="version" 
                @click="changeSeason(12)" 
                :class="{ 'active': season === 12, 'disabled': isFetching }">
                魔法大乱斗
            </div>
            <div class="version"
                 @click="changeSeason(13)"
                 :class="{ 'active': season === 13, 'disabled': isFetching }">
                双城之战2
            </div>
        </div>

        <div class="lineupTotal" v-if="lineUpList.length > 0">
            <div v-for="item in lineUpList" 
                :key="item.id" 
                class="lineup" 
                @click="getLineupDetail(item.id)"
                :class="{'loading': isFetching}">
                <div class="formation-name">{{ item.formationName }}</div>
                <div class="image-container">
                    <div v-for="info in item.formationInfoList" :key="info.id" class="character-info">
                        <img :src="info.characterName" alt="Character" class="character-image">
                        <div class="equipment">
                            <img v-if="info.equipmentOne" :src="info.equipmentOne" alt="Equipment One" class="equipment-image">
                            <img v-if="info.equipmentTwo" :src="info.equipmentTwo" alt="Equipment Two" class="equipment-image">
                            <img v-if="info.equipmentThree" :src="info.equipmentThree" alt="Equipment Three" class="equipment-image">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-show="lineUpList.length === 0" class="no-data">
            加载中...
        </div>

        <div v-show="lineUpList.length >= totalCount && isSearchActive === false" class="end-text">到底了</div>
        <create></create>

        <div class="search-container" :class="{'active': isSearchActive}">
            <i class="el-icon-close" v-show="isSearchActive" @click="toggleSearch(false)"></i>
            <input 
                class="search-input" 
                type="text" 
                v-show="isSearchActive" 
                placeholder="输入英雄名" 
                v-model="hero" 
                @input="debounceSearch"
            />
            <i class="el-icon-search" @click="toggleSearch(true)"></i>
        </div>
    </div>
</template>

<script>
import { getLineupByHero, getLineupList } from "@/api/tftApi";
import { goto2 } from "@/utils/pathUtil";
import Create from "@/components/create.vue";
import Back from "@/components/back.vue";

export default {
    name: "LineUpList",
    components: { Back, Create },
    data() {
        return {
            pageNum: 1,
            season: 11,
            lineUpList: [],
            totalCount: 1,
            isSearchActive: false,
            hero: null,
            isFetching: false,
            searchTimeout: null,
        };
    },
    methods: {
        async changeSeason(season) {
            if (this.isFetching || this.season === season) return;
            
            this.season = season;
            this.pageNum = 1;
            this.lineUpList = [];
            await this.getLineUpList();
        },
        handleScroll() {
            if (this.isFetching) return;
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            const scrollHeight = document.documentElement.scrollHeight;
            const clientHeight = document.documentElement.clientHeight;
            if (scrollTop + clientHeight >= scrollHeight - 200) {
                this.pageNum++;
                this.getLineUpList();
            }
        },
        toggleSearch(value) {
            if (value === false) {
                this.getLineUpList();
                this.hero = null;
            }
            this.isSearchActive = value;
            if (this.isSearchActive === true && value === true) {
                this.searchLineup();
            }
        },
        debounceSearch() {
            if (this.searchTimeout) {
                clearTimeout(this.searchTimeout);
            }
            this.searchTimeout = setTimeout(() => {
                this.searchLineup();
            }, 300);
        },
        async searchLineup() {
            const hero = this.hero ?? '';
            if (hero) {
                try {
                    this.isFetching = true;
                    const res = await getLineupByHero(this.season, this.hero);
                    this.lineUpList = res;
                } finally {
                    this.isFetching = false;
                }
            } else {
                await this.getLineUpList();
            }
        },
        getLineupDetail(id) {
            goto2(this.$router, 'tft', { id: id });
        },
        async getLineUpList() {
            if (this.isFetching || this.lineUpList.length >= this.totalCount) return;
            
            try {
                this.isFetching = true;
                const res = await getLineupList(this.pageNum, this.season);
                this.lineUpList = [...this.lineUpList, ...res.list];
                this.totalCount = res.totalCount;
            } catch (error) {
                console.error('Failed to fetch lineup list:', error);
            } finally {
                this.isFetching = false;
            }
        },
        setSeo() {
            let keywordsContent = '金铲铲之战, 云顶之弈, 阵容分享, 游戏策略, 画之灵, 双城传说, 赛季最强';
            let keywordsMeta = document.querySelector('meta[name="keywords"]');
            if (!keywordsMeta) {
                keywordsMeta = document.createElement('meta');
                keywordsMeta.setAttribute('name', 'keywords');
                document.head.appendChild(keywordsMeta);
            }
            keywordsMeta.setAttribute('content', keywordsContent);

            let descriptionContent = '发现并掌握金铲铲之战/云顶之弈中最强大的阵容。我们的专家玩家团队精心挑选并分享多种阵容，帮助你在每个赛季中占据优势。';
            let descriptionMeta = document.querySelector('meta[name="description"]');
            if (!descriptionMeta) {
                descriptionMeta = document.createElement('meta');
                descriptionMeta.setAttribute('name', 'description');
                document.head.appendChild(descriptionMeta);
            }
            descriptionMeta.setAttribute('content', descriptionContent);

            document.title = '顶尖阵容分享 - 金铲铲之战/云顶之弈最强策略';
        }
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
        this.getLineUpList();
        this.setSeo();
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
        if (this.searchTimeout) {
            clearTimeout(this.searchTimeout);
        }
    },
    watch: {
        season: {
            immediate: true,
            handler() {
                this.pageNum = 1;
                this.lineUpList = [];
                this.getLineUpList();
            }
        }
    }
}
</script>

<style scoped>
.lineupTotal {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    padding: 25px;
    justify-content: center;
    min-height: 200px;
}

.lineup {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 0.95);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
    transition: all 0.3s ease;
    cursor: pointer;
}

.lineup:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.lineup.loading {
    opacity: 0.7;
    pointer-events: none;
}

.formation-name {
    font-weight: bold;
    color: #333;
    font-size: 1.3em;
    margin-bottom: 15px;
    text-align: center;
}

.image-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
}

.character-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

.character-image {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    transition: transform 0.3s ease;
    border: 2px solid #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.character-image:hover {
    transform: scale(1.15);
}

.equipment {
    display: flex;
    gap: 1px;
}

.equipment img {
    width: 20px;
    height: 20px;
    transition: transform 0.2s ease;
}

.equipment img:hover {
    transform: scale(1.2);
}

.search-container {
    position: fixed;
    top: 20px;
    right: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #66c3ff;
    border-radius: 25px;
    padding: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
}

.search-container.active {
    background-color: #fff;
}

.search-input {
    padding: 8px 15px;
    border-radius: 20px;
    border: 1px solid #ddd;
    width: 180px;
    transition: all 0.3s ease;
    font-size: 14px;
}

.search-input:focus {
    width: 280px;
    outline: none;
    border-color: #66c3ff;
    box-shadow: 0 0 8px rgba(102, 195, 255, 0.5);
}

.el-icon-close,
.el-icon-search {
    color: #fff;
    cursor: pointer;
    font-size: 20px;
    padding: 0 8px;
    transition: transform 0.2s ease;
}

.search-container.active .el-icon-close,
.search-container.active .el-icon-search {
    color: #66c3ff;
}

.el-icon-close:hover,
.el-icon-search:hover {
    transform: scale(1.2);
}

.versionSelector {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    gap: 20px;
    flex-wrap: wrap;
    padding: 0 20px;
}

.version {
    padding: 12px 25px;
    background-color: rgba(255, 255, 255, 0.9);
    color: #333;
    cursor: pointer;
    font-weight: bold;
    border-radius: 12px;
    transition: all 0.3s ease;
    user-select: none;
}

.version:hover:not(.disabled) {
    background-color: #66c3ff;
    color: white;
    transform: translateY(-3px);
    box-shadow: 0 5px 15px rgba(102, 195, 255, 0.4);
}

.version.active {
    background-color: #ff8585;
    color: white;
    box-shadow: 0 5px 15px rgba(255, 133, 133, 0.4);
}

.version.disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.backGround1,
.backGround2,
.backGround3,
.backGround4{
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    min-height: 100vh;
    padding-bottom: 50px;
}

.backGround1 {
    background-image: url("https://www.yunox.cn/image/s6_bg.jpg");
}

.backGround2 {
    background-image: url("https://www.yunox.cn/image/s11_bg.png");
}

.backGround3 {
    background-image: url("https://www.yunox.cn/image/s12_bg.jpg");
}
.backGround4 {
    background-image: url("https://www.yunox.cn/image/s13_bg.png");
}

.no-data {
    text-align: center;
    padding: 50px;
    color: #666;
    font-size: 1.2em;
}

.end-text {
    text-align: center;
    padding: 20px;
    color: #666;
    font-style: italic;
}

@media (max-width: 768px) {
    .version {
        padding: 10px 20px;
        font-size: 14px;
    }
    
    .search-input:focus {
        width: 200px;
    }
    
    .lineup {
        padding: 15px;
    }
    
    .character-image {
        width: 45px;
        height: 45px;
    }
}
</style>
